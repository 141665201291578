import React from 'react';
import { Checkbox as ChakraCheckbox, FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';

const Checkbox = ({ field, value, onChange, error, ...rest }) => {
  return (
    <FormControl isInvalid={error}>
      <FormLabel htmlFor={field.name}>{field.label}</FormLabel>
      <ChakraCheckbox
        id={field.name}
        isChecked={value === field.checkedValue}
        onChange={(e) => onChange(e.target.checked ? field.checkedValue : field.uncheckedValue)}
        {...rest}
      >
        {field.label}
      </ChakraCheckbox>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default Checkbox;
