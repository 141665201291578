import React from 'react';
import { useRoutingNavigation } from '../routing/useNavigation'; // Import useHistory hook from React Router
import '../styleSheets/edtech.css'; // Adjust the path according to your project structure

function LearningSystem() {
    const { handleRouting } = useRoutingNavigation();
 
  return (
    <div className="container">
      <h1>Learning System</h1>
      <button className="button" onClick={handleRouting("/learning")}>Learning</button>
      <button className="button" onClick={handleRouting("/learner")}>Learner </button>
      {/* Placeholder for navigation functionality */}
    </div>
  );
}
export default LearningSystem;