import { useToast } from '@chakra-ui/react';

const useFormSubmitSR = (apiUrl, reset, navigate) => {
  const toast = useToast();

  const onSubmit = async (data) => {
    if (!data.date_start) data.date_start = null;
    if (!data.date_end) data.date_end = null;

    try {
      const response = await fetch(apiUrl, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      if (!response.ok) throw new Error(`HTTP status ${response.status} (${response.statusText})`);

      const result = await response.json();
//      console.log('Submission Success:', result);
//      console.log('Submission Success:';
      toast({
        title: "Success",
        description: "Entry updated successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      reset();
      navigate('Assessment'); // Navigate to the previous view
    } catch (error) {
      console.error('Failed to submit form:', error);
      toast({
        title: "Error",
        description: "Failed to update entry",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return { onSubmit };
};

export default useFormSubmitSR;
