import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles

const TaskCRQ_Q = () => {
  const [text, setText] = useState('');

  const handleChange = (value) => {
    setText(value);
  };

  return (
    <div>
      <ReactQuill value={text} onChange={handleChange} />
    </div>
  );
};

export default TaskCRQ_Q;