import React, { useState } from 'react';
import { useRoutingNavigation} from '../../routing/useNavigation'; // Import useHistory hook from React Router

import InstructionalComponents from './InstructionalComponents'; // Make sure to import the Curriculum component
import InstructionalPlans from './InstructionalPlans'; // Make sure to import the Curriculum component
import I_Resources from './I_Resources'; // Display of first resource
import I_Vocab from './I_Vocab'; // Display of first resource
import TaskCRQ_G from '../shared/TaskCRQ_G'; // Google Docs Text Editor
import TaskCRQ_Q from '../shared/TaskCRQ_Q'; // Quill Rich Text Editor
import TaskCRQ_D from '../shared/TaskCRQ_D'; // Draft-js Rich Text Editor
import I_Design from './I_Design1'; // Draft-js Rich Text Editor
import S_JsonSchemaForm from '../shared/JsonSchemaForm'; // JSON Schema Form
import AudioRecorder from '../shared/AudioRecorder';
import IPlanResources from './IPlanResources';
import IPlanResourcesReview from '../review/IPlanResourcesReview';

import A_TakeAssessments from '../assessment/A_TakeAssessments'; // Display of first resource
// import A_TakeAssessments2 from '../assessment/A_TakeAssessments2'; // Display of first resource


function Instruction() {

  const { handleRouting } = useRoutingNavigation();

    const [currentView, setCurrentView] = useState('Instruction');
    const handleView = (viewName) => {
      setCurrentView(viewName);
    };
    
    return (
      <div className="container">
        <h1>Instruction Management</h1>
        {currentView === 'Instruction' && (
        <>
        <button className="button" onClick={() => handleView('IPlanResources')}>Instructional Plan Resources</button>
        <button className="button" onClick={() => handleView('IPlanResourcesReview')}>Instructional Plan Resources Review</button>        
        <button className="button" onClick={() => handleView('InstructionalComponents')}>Instructional Components</button>
        <button className="button" onClick={() => handleView('InstructionalPlans')}>Instructional Plans</button>
        <button className="button" onClick={() => handleView('TaskCRQ_G')}>Text Input Google</button>        
        <button className="button" onClick={() => handleView('TaskCRQ_Q')}>Text Input Quill</button>        
        <button className="button" onClick={() => handleView('TaskCRQ_D')}>Text Input DraftJS</button>        
        <button className="button" onClick={() => handleView('I_Design')}>Instructional Design</button>
        <button className="button" onClick={() => handleView('S_JsonSchemaForm')}>JSON Schema Form</button>
        <button className="button" onClick={() => handleView('AudioRecorder')}>Audio Recorder</button>

        {/* Buttons for Strategies and Venues */}
        <button className="button" onClick={handleRouting('/learning')}>Return to Learning Process</button>
        </>
        )}

        {currentView === 'IPlanResources' && <IPlanResources handleView={handleView} />}
        {currentView === 'IPlanResourcesReview' && <IPlanResourcesReview handleView={handleView} />}        
        {currentView === 'InstructionalComponents' && <InstructionalComponents handleView={handleView} />}
        {currentView === 'InstructionalPlans' && <InstructionalPlans handleView={handleView} />}

        {currentView === 'TaskCRQ_G' && <TaskCRQ_G handleView={handleView} />}        
        {currentView === 'TaskCRQ_Q' && <TaskCRQ_Q handleView={handleView} />}        
        {currentView === 'TaskCRQ_D' && <TaskCRQ_D handleView={handleView} />}                        
        {currentView === 'I_Design' && <I_Design handleView={handleView} />}
        {currentView === 'S_JsonSchemaForm' && <S_JsonSchemaForm handleView={handleView} />}

        {currentView === 'Resources' && <I_Resources handleView={handleView} />}
        {currentView === 'Vocab' && <I_Vocab handleView={handleView} />}
        {/* {currentView === 'Resource1' && <Resource1 handleView={handleView} />} */}
        {currentView === 'A_TakeAssessments' && <A_TakeAssessments handleView={handleView} />}
        {currentView === 'AudioRecorder' && <AudioRecorder handleView={handleView} />}

      </div>
    );
  }

  export default Instruction;