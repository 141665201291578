import React, { useState } from 'react';
import { useRoutingNavigation} from '../../routing/useNavigation'; // Import useHistory hook from React Router
import { Button, Heading } from "@chakra-ui/react";

import A_Participations_Auto from '../assessment/A_Participations_Auto.js'; // Display of Assessment Resources
import L_Learners from '../learner/L_Learners.js'; // Display of Larner entry form
import L_Roles from '../access/L_Roles.js'; // Display of Assessment Resources
import L_Access from '../access/L_Access.js'; // Display of Assessment Resources
import ProcessEvaluations from '../shared/ProcessEvaluations.js'; 

function Admin() {

  const { handleRouting } = useRoutingNavigation();

    const [currentView, setCurrentView] = useState('Admin');
    const handleView = (viewName) => {
      setCurrentView(viewName);
    };
    
    return (
      <div className="container">
        <Heading as="h1" color="green.500" size="sm">Administration</Heading>
        <Button mt={2} mb={2} ml={0} colorScheme="green" width="max(50vw, 400px)" onClick={() => handleView('Admin')}>Return to Admin Home</Button>
        
        {currentView === 'Admin' && (
        <>
        <button className="button" onClick={() => handleView('A_Participations_Auto')}>Participations Auto</button>
        <button className="button" onClick={() => handleView('L_Learners')}>Learners</button>        
        <button className="button" onClick={() => handleView('L_Roles')}>Roles</button>        
        <button className="button" onClick={() => handleView('L_Access')}>Access Control</button>        
        <button className="button" onClick={() => handleView('ProcessEvaluations')}>Process Evaluations</button>                

        {/* Buttons for Strategies and Venues */}
        <button className="button" onClick={handleRouting('/learning')}>Return to Learning Process</button>
        </>
        )}

        {currentView === 'A_Participations_Auto' && <A_Participations_Auto handleView={handleView} />}
        {currentView === 'L_Learners' && <L_Learners handleView={handleView} />}        
        {currentView === 'L_Roles' && <L_Roles handleView={handleView} />}        
        {currentView === 'L_Access' && <L_Access handleView={handleView} />}        
        {currentView === 'ProcessEvaluations' && <ProcessEvaluations handleView={handleView} />}        

        {/* {currentView === 'Resource1' && <Resource1 handleView={handleView} />} */}
      </div>
    );
  }

  export default Admin;