import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import fieldSchema_json from '../../config/fieldSchema_ins.json'; // Updated to fieldSchema_ins.json
import apiUrlConfig_json from '../../config/apiUrl.json';

import useFetchRecords_2 from '../../techComponents/useFetchRecords_2';
import NavigationControls from '../../techComponents/NavigationControls';
import FieldsRendering from '../../techComponents/FieldsRendering';
import useFormSubmit from '../../techComponents/useFormSubmit';
import SearchRecords from '../../techComponents/SearchRecords';

const I_Vocab = () => {
  const fieldSchema = fieldSchema_json.I_Vocab;
  const searchFieldSchema = fieldSchema_json.I_Vocab_Search;
  const apiUrl = apiUrlConfig_json.I_Vocab;

  // Generate initial form values
  const initialFormValues = fieldSchema.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {});
  const initialSearchParams = searchFieldSchema.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {});

  // UseForm for main form fields
  const mainFormMethods = useForm({ defaultValues: initialFormValues });
  const { control: mainControl, handleSubmit, setValue, formState: { errors } } = mainFormMethods;

  // UseForm for search fields
  const searchFormMethods = useForm({ defaultValues: initialSearchParams });
  const { control: searchControl, getValues: getSearchValues } = searchFormMethods;

  const { records, fetchRecords, searchParam, setSearchParam, specificSearchParams, setSpecificSearchParams, handleSearch, currentIndex, setCurrentIndex, error } = useFetchRecords_2(apiUrl);

  const { onSubmit } = useFormSubmit(apiUrl, fetchRecords, mainFormMethods.reset);

  useEffect(() => {
    if (currentIndex !== -1 && currentIndex < records.length) {
      fieldSchema.forEach(field => setValue(field.name, records[currentIndex][field.name]));
    }
  }, [records, currentIndex, setValue, fieldSchema]);

  const handleSearchWrapper = () => {
    const specificParams = getSearchValues(); // Get only search field values
    setSpecificSearchParams(specificParams); // Set specific search parameters
    handleSearch(); // Perform the search
  };

  return (
    <div className="container">
      <h2>Manage Vocabulary</h2>
      <FieldsRendering control={searchControl} errors={{}} fieldSchema={searchFieldSchema} /> {/* Render search fields */}
      <SearchRecords searchParam={searchParam} setSearchParam={setSearchParam} handleSearch={handleSearchWrapper} />
      {error && <div style={{ color: 'red' }}>{error}</div>}

      <form onSubmit={handleSubmit(data => onSubmit(data, currentIndex, records))}>
        <FieldsRendering control={mainControl} errors={errors} fieldSchema={fieldSchema} />
        <NavigationControls
          currentIndex={currentIndex}
          recordsLength={records.length}
          setCurrentIndex={setCurrentIndex}
          reset={mainFormMethods.reset}
          initialFormValues={initialFormValues}
        />
      </form>
    </div>
  );
};

export default I_Vocab;
