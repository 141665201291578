import React, { useState, useEffect } from 'react';
import '../../styleSheets/quizStyles.css';
import { Box, Button, Heading, Text } from "@chakra-ui/react";
import 'katex/dist/katex.min.css';
import Latex from 'react-latex';
import TaskLines from '../../components/shared/TaskLines'; // Importing the secondary component

const L_Task = ({ taskData, onTaskSubmit, showSubmitButton }) => {

    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);    
    const [finalAnswers, setFinalAnswers] = useState({});
    const [incorrectAnswers, setIncorrectAnswers] = useState({});
    const [finalMarks, setFinalMarks] = useState({});
    const [correctAnswers, setCorrectAnswers] = useState({});
    const [evaluations, setEvaluations] = useState([]);

    const isRTL = text => {
        const rtlChars = /[\u0600-\u06FF\u0750-\u077F]/;
        return rtlChars.test(text);
    };

    const renderText = (text) => {
        if (isRTL(text)) {
            return <Text dir="rtl" textAlign="right" fontFamily="'Jameel Noori Nastaleeq', serif" fontSize="25px"><Latex>{text}</Latex></Text>;
        } else {
            return <Text><Latex>{text}</Latex></Text>;
        }
    };

    useEffect(() => {
        if (taskData) {
            const { file_contents, content_type } = taskData;
            let parsedContents = file_contents;
            if (typeof file_contents === 'string') {
                parsedContents = JSON.parse(file_contents);
            }
            setQuestions(parsedContents);
            setLoading(false);
        }

    }, [taskData]);


    const handleSubmit = async (answers, marks, incorrectAnswers, correctAnswers) => {
        setIsSubmitting(true);
        
        setFinalAnswers(answers);
        setFinalMarks(marks);
        setIncorrectAnswers(incorrectAnswers);
        setCorrectAnswers(correctAnswers);
        try {
            const evaluations = questions.map((question, index) => {
                return {
                    // part_number: question['Q-n'],
                    // q_type: question['Q-type'],
                    // question: question['Q-type'] === 'textbox' ? question.Q : null,
                    // obt_marks: marks[index],
                    // answer: JSON.stringify(answers[index]),
                    question: question.Q,
                    answer: JSON.stringify(question[finalAnswers[index]]),
                    answer1: JSON.stringify(question[answers[index]])
                };
            });

            setEvaluations(evaluations);
            setIsSubmitted(true);
        } catch (error) {
            console.error('Error updating database:', error);
        } finally {
            setIsSubmitting(false);
            // onTaskSubmit(); // Notify parent component about task submission
        }
    }

    const handleClose = async () => {
                await onTaskSubmit(evaluations); // Notify parent component about task submission    
    }
    
    if (loading) {
        return <div>Loading...</div>;
    }

    if (isSubmitted) {
        return (
            <>
                <Heading as="h2" size="lg" mb={4}>
                    Review Your Answers
                </Heading>

                {questions.map((question, index) => (
                    <Box key={index} mb={4} p={4} borderWidth="1px" borderRadius="md">
                        <Text mb={2}>
                            <strong>Question {question['Q-n']}:</strong> {renderText(`${question.Q} (${question.max_marks} marks)`) }
                        </Text>
                        <Text mb={2}>
                            <strong>Your answer:</strong> {renderText(question['Q-type'] === 'checkbox' ? Object.keys(finalAnswers[index] || {}).filter(opt => finalAnswers[index][opt]).map(opt => question[opt]).join(' *** ') : (question['Q-type'] === 'radio' ? question[finalAnswers[index]] :((question['Q-type'] === 'textbox' ? finalAnswers[index] : 'No answer selected'))))} 
                        </Text>
                        <Text mb={2}>
                            <strong>Your marks:</strong> {finalMarks[index]} 
                        </Text>
                        {(incorrectAnswers[index] === "true") && (
                            // <Text fontWeight="bold" color="red.500">
                            <Text fontWeight="normal" color="red.500">
                                <strong>Correct answer:</strong> {renderText(question['Q-type'] === 'checkbox' ? question.A.map(opt => question[opt]).join(' *** ') : (question['Q-type'] === 'radio' ? question[question.A] : (question['Q-type'] === 'textbox' ? correctAnswers[index] : 'No answer available')) )}
                            </Text>
                        )}
                    </Box>
                ))}

                {/* {showSubmitButton && ( <Button onClick={handleClose} colorScheme="teal" mt={4}> Close </Button> )} */}
                <Button onClick={handleClose} colorScheme="teal" mt={4}> Close </Button>
            </>
        );
    }

    return (
        <div className="quiz-container">
            <h3 style={{ color: "blue", fontSize: "20px" }}>Task (MCQ)</h3>
            <TaskLines
                questions={questions}
                onSubmit={handleSubmit}
                renderText={renderText}
                isRTL={isRTL}
            />
        </div>
    );
};

export default L_Task;
