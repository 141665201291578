import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import FieldsRendering from '../../techComponents/FieldsRendering';
import JSON2Table from '../../techComponents/JSON2Table';
import JSON2CSV from '../../techComponents/JSON2CSV';
import JSON2PDF from '../../techComponents/JSON2PDF';
import {Button} from '@chakra-ui/react';

import A_TakeAssessments_Preview from './A_TakeAssessments_Preview';

import fieldSchema_json from '../../config/fieldSchema.json';
import apiUrlConfig_json from '../../config/apiUrl.json';

const Participations_Auto = () => {
  const fieldSchema = fieldSchema_json.A_Participations_Auto;
  const apiUrl = apiUrlConfig_json.A_Participations_Auto;

  const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm();

  const [message, setMessage] = useState('');
  const [summary, setSummary] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isPreviewed, setIsPreviewed] = useState(false);
  const [assessmentId, setAssessmentId] = useState(null);

  const handlePublish = async (data) => {
    try {
      const response = await fetch(`${apiUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ assessment_id: data.assessmentId }),
      });
      
      if (response.ok) {
        const data = await response.json();
        setMessage(data.message);
        setSummary(data.summary);
        setIsSubmitted(true);
      } else {
        setMessage('Error publishing participations');
      }
    } catch (error) {
      setMessage('Error publishing participations');
    }
  };

  const handlePreview = (data) => {
    setAssessmentId(data.assessmentId);
    setIsPreviewed(true);
  };

  return (
    <div className="container">
      <h1>Generate Participations</h1>
      <form>            
        <FieldsRendering control={control} errors={errors} fieldSchema={fieldSchema} />

        <Button mt={2} ml={2} colorScheme="blue" onClick={handleSubmit(handlePreview)}> Preview </Button>
          {isPreviewed && <A_TakeAssessments_Preview assessmentId={assessmentId} showSubmitButton={false}/> }

        {!isSubmitted && (
          <Button mt={2} ml={2} colorScheme="teal" size="xlg" sx={{ fontSize: '20px', padding: '16px 32px' }}
            onClick={handleSubmit(handlePublish)}>
              Publish
          </Button>
        )}
      </form>                

      {message && <p>{message}</p>}
      {summary.length > 0 && <JSON2Table data={summary} />}
      {summary.length > 0 && <JSON2CSV data={summary} />}
      {summary.length > 0 && <JSON2PDF data={summary} title={'Participations'} downloadPDF={true} showPDF={false} />}
    </div>
  );
};

export default Participations_Auto;
