import React, { useState } from 'react';
import { VStack, Box, Button } from '@chakra-ui/react';
import { useRoutingNavigation} from '../routing/useNavigation'; // Import useHistory hook from React Router

import CreateAssistant from './Assistant/CreateAssistant.jsx';
import ListAssistants from './Assistant/ListAssistants.jsx';
import ModifyAssistant from './Assistant/ModifyAssistant.jsx';

import ThreadManager from './Chat/ThreadManager.jsx';
import ThreadSelector from './Chat/ThreadSelector.jsx';
import ThreadChat from './Chat/ThreadChat.jsx';

import CreateVectorStore from './Vectors/CreateVectorStore.jsx';
import ListVectorStore from './Vectors/ListVectorStore.jsx';
import DeleteVectorStore from './Vectors/DeleteVectorStore.jsx';
import UploadFile from './Vectors/UploadFile.jsx';

function AssistantApi() {

  const { handleRouting } = useRoutingNavigation();

    const [currentView, setCurrentView] = useState('AssistantApi');
    const handleView = (viewName) => {
      setCurrentView(viewName);
    };
    
    return (
      <div className="container">
        <h1>AI Tutor</h1>
        {currentView === 'AssistantApi' && (
        <>
        <VStack spacing={2} align="stretch">          
          <Button mt={2} ml={0} colorScheme="teal" width="25%" onClick={() => handleView('CreateAssistant')}>Create Assistant</Button>
          <Button mt={2} ml={0} colorScheme="teal" width="25%" onClick={() => handleView('ListAssistants')}>List Assistants</Button>
          <Button mt={2} ml={0} colorScheme="teal" width="25%" onClick={() => handleView('ModifyAssistant')}>Modify Assistant</Button>

          <Button mt={2} ml={0} colorScheme="yellow" width="25%" onClick={() => handleView('ThreadManager')}>Thread Manager</Button>
          {/* <Button mt={2} ml={0} colorScheme="teal" onClick={() => handleView('ThreadSelector')}>Thread Selector</Button>
          <Button mt={2} ml={0} colorScheme="teal" onClick={() => handleView('ThreadChat')}>Thread Chat</Button> */}

          <Button mt={2} ml={0} colorScheme="purple" width="25%" onClick={() => handleView('CreateVectorStore')}>Create Vector Store</Button>
          <Button mt={2} ml={0} colorScheme="purple" width="25%" onClick={() => handleView('ListVectorStore')}>List Vector Store</Button>
          <Button mt={2} ml={0} colorScheme="purple" width="25%" onClick={() => handleView('DeleteVectorStore')}>Delete Vector Store</Button>
          <Button mt={2} ml={0} colorScheme="green" width="25%" onClick={() => handleView('UploadFile')}>Upload File</Button>
        </VStack>        
        </>
        )}

        {currentView === 'CreateAssistant' && <CreateAssistant handleView={handleView} />}
        {currentView === 'ListAssistants' && <ListAssistants handleView={handleView} />}
        {currentView === 'ModifyAssistant' && <ModifyAssistant handleView={handleView} />}

        {currentView === 'ThreadManager' && <ThreadManager handleView={handleView} />}
        {/* {currentView === 'ThreadSelector' && <ThreadSelector handleView={handleView} />}
        {currentView === 'ThreadChat' && <ThreadChat handleView={handleView} />} */}

        {currentView === 'CreateVectorStore' && <CreateVectorStore handleView={handleView} />}
        {currentView === 'ListVectorStore' && <ListVectorStore handleView={handleView} />}
        {currentView === 'DeleteVectorStore' && <DeleteVectorStore handleView={handleView} />}
        {currentView === 'UploadFile' && <UploadFile handleView={handleView} />}

      </div>
    );
  }

  export default AssistantApi;