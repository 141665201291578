import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState(() => {
        const savedAuthState = sessionStorage.getItem('authState');
        return savedAuthState ? JSON.parse(savedAuthState) : { user_id: null, role_id: null, cont_id: null, attr_id: null };
    });

    const setAuthInfo = ({ user_id, cont_id, attr_id, role_id }) => {
        const newAuthState = { user_id, cont_id, attr_id, role_id };
        setAuthState(newAuthState);
        sessionStorage.setItem('authState', JSON.stringify(newAuthState));
    };

    useEffect(() => {
        const savedAuthState = sessionStorage.getItem('authState');
        if (savedAuthState) {
            setAuthState(JSON.parse(savedAuthState));
        }
    }, []);

    return (
        <AuthContext.Provider value={{ ...authState, setAuthInfo }}>
            {children}
        </AuthContext.Provider>
    );
};

export default useAuth;
