import React, { useState } from 'react';

const TaskCRQ_G = ({ prompt1 }) => {
  const [responseLink, setResponseLink] = useState('');

  const handleOpenEditor = () => {
    window.open('https://docs.google.com', '_blank');
  };

  const handleLinkSubmit = () => {
    const link = prompt('Please paste your Google Docs link here:');
    setResponseLink(link);
  };

  return (
    <div>
      <h3>{prompt1}</h3>
      <button onClick={handleOpenEditor}>Open Google Docs Editor</button>
      <button onClick={handleLinkSubmit}>Submit Response Link</button>
      {responseLink && (
        <div>
          <p>Submitted Link: <a href={responseLink} target="_blank" rel="noopener noreferrer">{responseLink}</a></p>
        </div>
      )}
    </div>
  );
};

export default TaskCRQ_G;


