import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import axios from 'axios';
import { Button, HStack } from '@chakra-ui/react';

import FieldsRendering from '../../techComponents/FieldsRendering';
import JSON2Table from '../../techComponents/JSON2Table';
import JSON2CSV from '../../techComponents/JSON2CSV';
import JSON2PDF from '../../techComponents/JSON2PDF';
import ChartOptions from '../../techComponents/ChartOptions'; 

import fieldSchema_json from '../../config/fieldSchema_lnr.json';
import apiUrlConfig_json from '../../config/apiUrl.json';

const LNRTaskPerformance = () => {
  const fieldSchema = fieldSchema_json.LNR_TaskPerformance;
  const apiUrl = apiUrlConfig_json.LNR_TaskPerformance;

  const { control, setValue, handleSubmit: handleSubmitForm } = useForm({
    defaultValues: {
      dimensions: fieldSchema.map(field => ({ name: field.name }))
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dimensions'
  });

  const [message, setMessage] = useState('');
  const [summary, setSummary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("Initial dimensions:", fields);
    console.log("API URL:", apiUrl);
  }, [fields]);

  const handleAddField = () => {
    const nextField = fieldSchema.find(field => !fields.some(f => f.name === field.name));
    if (nextField) {
      append({ name: nextField.name });
      console.log("Field added:", nextField.name);
    }
  };

  const handleRemoveField = (index) => {
    const removedField = fields[index].name;
    console.log("Field removed:", removedField);
  // Remove the dimension from fields
  remove(index);

  // Remove the corresponding filter value
  setValue(removedField, ""); // Assuming you use setValue from useForm to manage field values
  };

  const onSubmit = async (data) => {
    console.log("Data submitted:", data);

    // const filters = data.dimensions.reduce((acc, dimension) => {
    //   acc[dimension.name] = ''; // Placeholder for the filter value
    //   return acc;
    // }, {});

    const filters = data.dimensions.reduce((acc, dimension) => {
      const dimensionValue = data[dimension.name];
      if (dimensionValue) {
        acc[dimension.name] = dimensionValue;
      }
      return acc;
    }, {});


    const columns = data.dimensions.map(dimension => dimension.name);

    console.log("Filters for API call:", filters);
    console.log("Columns for API call:", columns);

    setLoading(true);
    setError(null);

    // try {
    //   const response = await fetch(apiUrl, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({ filters })
    //   });

    //   if (!response.ok) {
    //     throw new Error(`HTTP error! Status: ${response.status}`);
    //   }

    //   const data = await response.json();
    //   console.log('Data fetched successfully', data);
    //   setMessage(data.message);
    //   setSummary(data);
    //   console.log("message ", message);
    //   console.log("summary ", summary);
    // } catch (error) {
    //   console.error('Error fetching data', error);
    //   setError('Failed to fetch data');
    // } finally {
    //   setLoading(false);
    // }

    try {
      const response = await axios.post(apiUrl, { filters, columns }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Data fetched successfully', response.data);
      // Handle the fetched data as needed
       setMessage(response.data.message);      
       setSummary(response.data.summary || response.data);
       console.log("message ", message);
       console.log("summary ", summary);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
    setLoading(false);
    }

  };

  return (
    <div className="container">
      <h2>Task Performance</h2>
      <form onSubmit={handleSubmitForm(onSubmit)}>
        {fields.map((field, index) => {
          const fieldDetails = fieldSchema.find(f => f.name === field.name);
          if (!fieldDetails) return null;
          return (
            <div key={field.id}>
{/*              <label>{fieldDetails.label}</label>    */}
            <HStack key={field.id} spacing={4} align="center">
              <FieldsRendering
                control={control}
                errors={{}}
                fieldSchema={[fieldDetails]}
                records={[]}
                onChange={() => {}} // No need to handle change for now
              />
              <Button type="button" size="sm" minWidth="40px" mt={6} ml={2} colorScheme="red" onClick={() => handleRemoveField(index)}>X</Button>
              </HStack>
            </div>
          );
        })}
        <Button type="button" mt={2} ml={0} colorScheme="blue" onClick={handleAddField}>Add Dimension</Button>
        <Button type="submit" mt={2} ml={0} colorScheme="green">Run Report</Button>
      </form>
      {loading && <p>Loading...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {message && <p>{message}</p>}
      {summary.length > 0 && <JSON2Table data={summary} />}
      {summary.length > 0 && <JSON2CSV data={summary} />}
      {summary.length > 0 && <JSON2PDF data={summary} title={'Task Performance'} downloadPDF={true} showPDF={false} />}
      {summary.length > 0 && <ChartOptions data={summary} />}
    </div>
  );
};

export default LNRTaskPerformance;
