import React, { useState } from 'react';
import { useRoutingNavigation} from '../../routing/useNavigation'; // Import useHistory hook from React Router
import { Button, Heading } from "@chakra-ui/react";

import A_Resources from '../assessment/A_Resources.js'; // Display of Assessment Resources
import A_Assessments from '../assessment/A_Assessments.js'; // Display of Assessment Resources
import FileUploadComponent from '../assessment/FileUploadComponent.js'; // Display of Assessment Resources
import Preview from '../shared/Preview.js'; // Display of Assessment Resources
import LNRTaskPerformance from '../learner/LNRTaskPerformance.js';
import TextEditor from '../../techComponents/TextEditor.js';
// import IPlanResources from '../instruction/IPlanResources.js';
// import IPlanResourcesReview from '../review/IPlanResourcesReview.js';
import IPlanResourcesReviewTeacher from '../review/IPlanResourcesReviewTeacher.js';


function Teacher() {

  const { handleRouting } = useRoutingNavigation();

    const [currentView, setCurrentView] = useState('Teacher');
    const handleView = (viewName) => {
      setCurrentView(viewName);
    };
    
    return (
      <div className="container">
        <Heading as="h1" color="green.500" size="sm">Teacher Tasks</Heading>
        <Button mt={2} mb={2} ml={0} colorScheme="green" width="max(50vw, 400px)" onClick={() => handleView('Teacher')}>Return to Teacher Tasks Home</Button>

        {currentView === 'Teacher' && (
        <>
        <button className="button" onClick={() => handleView('FileUploadComponent')}>Resource Upload</button>        
        <button className="button" onClick={() => handleView('A_Resources')}>Assessment Resources</button>
        <button className="button" onClick={() => handleView('A_Assessments')}>Assessments</button>
        <button className="button" onClick={() => handleView('Preview')}>Preview</button>
        <button className="button" onClick={() => handleView('LNRTaskPerformance')}>Learner Task Performance</button>
        <button className="button" onClick={() => handleView('TextEditor')}>Text Editor</button>
        <button className="button" onClick={() => handleView('IPlanResourcesReviewTeacher')}>Review Learner Performance</button>

        {/* <button className="button" onClick={() => handleView('IPlanResources')}>Perform Assessment Task</button>
        <button className="button" onClick={() => handleView('IPlanResourcesReview')}>Review Assessment Task Performance</button>         */}


        {/* Buttons for Strategies and Venues */}
        <button className="button" onClick={handleRouting('/learning')}>Return to Learning Process</button>
        </>
        )}

        {currentView === 'FileUploadComponent' && <FileUploadComponent handleView={handleView} />}
        {currentView === 'A_Resources' && <A_Resources handleView={handleView} />}
        {currentView === 'A_Assessments' && <A_Assessments handleView={handleView} />}
        {currentView === 'Preview' && <Preview handleView={handleView} />}
        {currentView === 'LNRTaskPerformance' && <LNRTaskPerformance handleView={handleView} />}
        {currentView === 'TextEditor' && <TextEditor handleView={handleView} />}
        {currentView === 'IPlanResourcesReviewTeacher' && <IPlanResourcesReviewTeacher handleView={handleView} />}

        {/* {currentView === 'IPlanResources' && <IPlanResources handleView={handleView} />}
        {currentView === 'IPlanResourcesReview' && <IPlanResourcesReview handleView={handleView} />}         */}


        {/* {currentView === 'Resource1' && <Resource1 handleView={handleView} />} */}
      </div>
    );
  }

  export default Teacher;