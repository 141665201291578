// 240613, This is based on react-pdf-viewer
// It optionally excludes some buttons, such as download and print.

import * as React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import type { ToolbarProps, ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import workerSrc from 'pdfjs-dist/build/pdf.worker.min.js';

import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

interface PDFViewerProps {
    fileUrl: string;
    allowDownload: boolean;
    allowPrint: boolean;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ fileUrl, allowDownload, allowPrint }) => {
    const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
        ...slot,
        Download: allowDownload ? slot.Download : () => <></>,
        DownloadMenuItem: allowDownload ? slot.DownloadMenuItem : () => <></>,
        Print: allowPrint ? slot.Print : () => <></>,
        PrintMenuItem: allowPrint ? slot.PrintMenuItem : () => <></>,
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
    });

    const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin({

// 240702, Following the instruction in https://react-pdf-viewer.dev/examples/remove-a-tab-from-the-sidebar/
// to remove the side bar buttons
        sidebarTabs: (defaultTabs) => [
            // Remove the attachments tab (\`defaultTabs[2]\`)
            // defaultTabs[0], // Bookmarks tab
            // defaultTabs[1], // Thumbnails tab
        ],        

        renderToolbar,
    });
    const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

    return (
        <Worker workerUrl={workerSrc}>
            <div style={{ height: '750px' }} className="rpv-toolbar">
                <Viewer fileUrl={fileUrl} plugins={[defaultLayoutPluginInstance]} />
            </div>
        </Worker>
    );
};

export default PDFViewer;
