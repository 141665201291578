import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Importing Axios
import { Box, Textarea, Text, Button, Checkbox, FormLabel, Input } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
// import TaskGet from './TaskGet';
import { useAuth } from '../../context/AuthContext'; // Adjust the path as necessary
import A_TaskMCQ_Preview from '../assessment/A_TaskMCQ_Preview';
import List from '../../techComponents/List';
import apiUrlConfig_json from '../../config/apiUrl.json';
import AudioRecorder from './AudioRecorder';
import TextGet from './TextGet';
import MediaRecorder from './MediaRecorder';
import FileUploadComponent from '../assessment/FileUploadComponent';

const TaskMedia = ({ question, index, onSubmit, mediaType = "video", lang = "en", mediaFileName = `media_${new Date().toISOString()}` })  => {
    
    const apiUrl = apiUrlConfig_json.CMS;
    const { user_id } = useAuth(); // Get the role_id of the logged on user from auth context  
  
    const { handleSubmit, control, setValue, getValues } = useForm();

    const [mediaFile, setMediaFile] = useState(null); // State to store the file
    const [displayTextFile, setDisplayTextFile] = useState(false);

    const timeLimitTeacher = 10;
    const [videoResolution, setVideoResolution] = useState({ "width": 854, "height": 480 });
    const [videoFPS, setVideoFPS] = useState( 10 );
    const [cameraFacingMode, setCameraFacingMode] = useState( 'environment' );
    const [audioConfig, setAudioConfig] = useState( { "sampleRate": 44100, "channelCount": 1 } );
    const [answer, setAnswer] = useState(null);

    const uploadTextFile = async () => {
        const formData = new FormData();
        if (mediaFile) formData.append('file', mediaFile);
        formData.append('resource_class', 'TEXT');
        formData.append('user_id', user_id);
        try {
            const response = await axios.post(`${apiUrl}gcs_learner_upload/`, formData);
            alert('File uploaded successfully');
            setMediaFile(null)
            setAnswer(response.data.secure_name);            
        } catch (error) {
            if (error.response) {
                console.error('Error:', error.response.status);                
                // console.error('Error:', error.response.data);
                // alert(`Error: ${error.response.statusText}: ${error.response.data.error}`);
            } else {
                console.error('Error:', error.message);  // No response or other network error
            }            
        }
    };


    useEffect(() => {
        const obtainedMarks = null;
        const isCorrect = "yes";
        onSubmit(index, answer, obtainedMarks, isCorrect);
    }, [answer]);

    if (!answer) {
    return (
        <>
        {/* <Box display="flex" alignItems="left" mb={4}>
            <FormLabel htmlFor="grade" width="150px" style={{ marginRight: '10px' }}>Select Grade</FormLabel>
            <Box width="50vw" maxWidth="300px">
                <List
                    id="grade"
                    field={{ label: "Select Grade" }} // Provide the field prop with a label
                    lov="GradeLevels"
                    placeholder="Select Grade"
                    value={grade}
                    onChange={(value) => setGrade(value)}
                    style={{ width: '100%' }}
                />
            </Box>
        </Box>                
        <Box display="flex" alignItems="left" mb={4}>
            <FormLabel htmlFor="subject" width="150px" style={{ marginRight: '10px' }}>Select Subject</FormLabel>
            <Box width="50vw" maxWidth="300px">
                <List
                    id="subject"
                    field={{ label: "Select Subject" }} // Provide the field prop with a label
                    lov="Subjects"
                    placeholder="Select Subject"
                    value={subject}
                    onChange={(value) => setSubject(value)}
                    style={{ width: '100%' }}
                />
            </Box>
        </Box>                
        <Box display="flex" alignItems="left" mb={4}>
            <FormLabel htmlFor="language" width="150px" style={{ marginRight: '10px' }}>Select Language</FormLabel>
            <Box width="50vw" maxWidth="300px">
                <List
                    id="language"
                    field={{ label: "Select Language" }} // Provide the field prop with a label
                    lov="Language"
                    placeholder="Select Language"
                    value={language}
                    onChange={(value) => setLanguage(value || '')}
                    style={{ width: '100%' }}
                />
            </Box>
        </Box>                
        <Box display="flex" alignItems="left" mb={4}>
            <FormLabel htmlFor="mediaType" width="150px" style={{ marginRight: '10px' }}>Select media type</FormLabel>
            <Box width="50vw" maxWidth="300px">
                <List
                    id="mediaType"
                    field={{ label: "Select media type" }} // Provide the field prop with a label
                    lov="MediaType"
                    placeholder="Select media type"
                    value={mediaType}
                    onChange={(value) => setMediaType(value)}
                    style={{ width: '100%' }}
                />
            </Box>
        </Box>      
        <Box display="flex" alignItems="left" mb={4}>
            <FormLabel htmlFor="videoResolution" width="150px" style={{ marginRight: '10px' }}>Select Video Resolution</FormLabel>
            <Box width="50vw" maxWidth="300px">
                <List
                    id="videoResolution"
                    field={{ label: "Select Video Resolution" }} // Provide the field prop with a label
                    lov="VideoResolution"
                    placeholder="Select Video Resolution"
                    value={videoResolution}
                    onChange={(value) => setVideoResolution(value)}
                    style={{ width: '100%' }}
                />
            </Box>
        </Box>                
        <Box display="flex" alignItems="left" mb={4}>
            <FormLabel htmlFor="videoFPS" width="150px" style={{ marginRight: '10px' }}>Select Video FPS</FormLabel>
            <Box width="50vw" maxWidth="300px">
                <List
                    id="videoFPS"
                    field={{ label: "Select Video FPS" }} // Provide the field prop with a label
                    lov="VideoFPS"
                    placeholder="Select Video FPS"
                    value={videoFPS}
                    onChange={(value) => setVideoFPS(value)}
                    style={{ width: '100%' }}
                />
            </Box>
        </Box>                 
        <Box display="flex" alignItems="left" mb={4}>
            <FormLabel htmlFor="audioConfig" width="150px" style={{ marginRight: '10px' }}>Select Audio Config</FormLabel>
            <Box width="50vw" maxWidth="300px">
                <List
                    id="audioConfig"
                    field={{ label: "Select Audio Config" }} // Provide the field prop with a label
                    lov="AudioConfig"
                    placeholder="Select Audio Config"
                    value={audioConfig}
                    onChange={(value) => setAudioConfig(value)}
                    style={{ width: '100%' }}
                />
            </Box>
        </Box>          

        <Box display="flex" alignItems="center" mb={2}>
            <FormLabel htmlFor="mediaFileName" width="150px" style={{ marginRight: '10px' }}>Media file name</FormLabel>
            <Input
                id="mediaFileName"
                placeholder="Enter media file name"
                value={mediaFileName}
                onChange={(e) => setMediaFileName(e.target.value)}
                style={{ width: '50vw' }}
            /> 
        </Box>                    

            */}
        <Box display="flex" alignItems="left" mb={4}>
            <FormLabel htmlFor="cameraFacingMode" width="150px" style={{ marginRight: '10px' }}>Camera Mode</FormLabel>
            <Box width="50vw" maxWidth="300px">
                <List
                    id="cameraFacingMode"
                    field={{ label: "Select Camera Facing Mode" }} // Provide the field prop with a label
                    lov="CameraFacingMode"
                    placeholder="Select Camera Facing Mode"
                    value={cameraFacingMode}
                    onChange={(value) => setCameraFacingMode(value)}
                    style={{ width: '100%' }}
                />
            </Box>
        </Box>                

        {/* <Box display="flex" alignItems="left" mb={4}> */}
        <Box display="flex" flexDirection="column" alignItems="flex-start" mb={4}>            
            <MediaRecorder 
                mediaType={mediaType} 
                timeLimit={timeLimitTeacher} 
                setMediaFile={setMediaFile} 
                fileName={mediaFileName} 
                videoResolution={videoResolution}
                videoFPS={videoFPS}
                cameraFacingMode={cameraFacingMode}
                audioConfig={audioConfig}
            />

            {mediaFile &&
                <Button mt={2} ml={0} colorScheme="blue" type="button" onClick={uploadTextFile}>
                    Save Media File
                </Button>
            }
        </Box>
        </>
    );
} else if (answer) {
    return (
        <>
    <Button mt={2} ml={0} colorScheme="blue" type="button" onClick={() => setDisplayTextFile(!displayTextFile)}>
        {displayTextFile ? 'Cancel' : 'Show Media File'}
        {/* {displayTextFile ? `Cancel ${answer}` : `Show Media File 1 ${answer}`} */}
    </Button>
    {displayTextFile && <TextGet currentTextFile={answer} cmsType={'learner'}/> }
    </>
    )
}
};

export default TaskMedia;
