import React from 'react';
import { usePapaParse } from 'react-papaparse';
import { Button } from "@chakra-ui/react";

const JSON2CSV = ({ data }) => {
  const { jsonToCSV } = usePapaParse();

  const downloadCSV = () => {
    const csvData = jsonToCSV({ data });
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button mt={2} ml={2} colorScheme="green" onClick={downloadCSV}>Download CSV</Button>
  );
};

export default JSON2CSV;
