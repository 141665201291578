import React from 'react';
import { Button, Box } from '@chakra-ui/react';
import { useAuth } from '../context/AuthContext'; // Adjust the path as necessary

const NavigationControls = ({ currentIndex, recordsLength, setCurrentIndex, reset, initialFormValues, showSubmit = true}) => {
  const handleNext = () => {
    if (currentIndex < recordsLength - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNewRecord = () => {
    reset(initialFormValues);
    setCurrentIndex(-1);
  };

  return (
    <Box display="flex" mt={4}>
      {showSubmit && <Button colorScheme="blue" type="submit" className='button_small'>Submit</Button>}
      <Button ml={4} onClick={() => reset()} variant="outline">Reset</Button>
      <Button ml={4} onClick={handlePrevious} variant="ghost">Previous</Button>
      <Button ml={4} onClick={handleNext} variant="ghost">Next</Button>
      <Button ml={4} onClick={handleNewRecord} colorScheme="teal">New Record</Button>
    </Box>
  );
};

export default NavigationControls;
