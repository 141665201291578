import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiUrlConfig_json from '../../config/apiUrl.json';
import "./ListAssistants.css"

const ListAssistants = () => {
  
  const apiUrl = apiUrlConfig_json.AAPI;

  const [assistants, setAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState('');

  useEffect(() => {
    const fetchAssistants = async () => {
      try {
        const response = await axios.get(`${apiUrl}assistants/`);
        setAssistants(response.data.available_assistants);
      } catch (error) {
        console.error('Error fetching assistants:', error);
      }
    };

    fetchAssistants();
  }, []);

  const handleAssistantChange = (event) => {
    setSelectedAssistant(event.target.value);
  };

  return (
    <div className="assistant-list">
      <h2>Select A Assistant</h2>
      <select value={selectedAssistant} onChange={handleAssistantChange}>
        <option value="">Select a assistant</option>
        {
            assistants.map((assistant)=>(
                <option value={assistant.fields.assistant_id} key={assistant.fields.assistant_id}>{assistant.fields.name}</option>
            ))
        }
      </select>
        {selectedAssistant && (
            <>
            <h1>Selected assistant's ID : </h1>
            <p>{selectedAssistant}</p>
            </>
        )}
        
    </div>
  );
};

export default ListAssistants;
