import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { useToast, Input, Button, FormControl, FormLabel, FormErrorMessage, Box } from '@chakra-ui/react';
import List from '../../techComponents/List'; // Import List.js component
import fieldSchema_json from '../../config/fieldSchema_lnr.json';
import apiUrlConfig_json from '../../config/apiUrl.json';
import { useAuth } from '../../context/AuthContext'; // Adjust the path as necessary

const L_Learners = () => {
  const fieldSchema = fieldSchema_json.L_Learners;
  const apiUrl = apiUrlConfig_json.L_Learners;
  const { user_id } = useAuth();
  const toast = useToast();

  const generateInitialValues = (fields) => {
    const initialValues = {};
    fields.forEach(field => {
      initialValues[field.name] = '';  // Set to empty string or a sensible default
    });
    return initialValues;
  };
  const initialFormValues = generateInitialValues(fieldSchema);

  const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: initialFormValues
  });

  const [records, setRecords] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchParam, setSearchParam] = useState('');

  useEffect(() => {
    if (currentIndex !== -1 && currentIndex < records.length) {
      fieldSchema.forEach(field => {
        setValue(field.name, records[currentIndex][field.name]);
      });
    }
  }, [records, currentIndex, setValue, fieldSchema]);

  const fetchRecords = async () => {
    try {
      const response = await axios.get(`${apiUrl}?search=${searchParam}`, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
  
      // If the status code is in the 2xx range, the response is successful
      if (response.status === 200) {
        setRecords(response.data); // response.data contains the parsed JSON
      } else {
        console.error(`Failed to fetch records: HTTP status ${response.status}`);
      }
    } catch (error) {
      // Handle the error appropriately
      console.error('Error fetching records:', error.message);
    }
  };

  const handleSearch = () => {
    fetchRecords();
  };

  const onSubmit = async (data) => {
    const isNewRecord = currentIndex === -1;
    const method = isNewRecord ? 'POST' : 'PUT';
    const recordId = !isNewRecord ? records[currentIndex].id + "/" : '';

    if (!data.dob) data.dob = null;
    if (!data.date_start) data.date_start = null;
    if (!data.date_end) data.date_end = null;
    if (method === 'POST') {
      data.created_by = user_id;
      data.created_on = new Date().toISOString(); // Captures client-side date in ISO format
    } else if (method === 'PUT') {
      data.updated_by = user_id;
      data.updated_on = new Date().toISOString(); // Captures client-side date in ISO format
    }


    try {
      const response = await axios({
        method: method.toLowerCase(),  // 'post' or 'put'
        url: `${apiUrl}${recordId}`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data
      });
  
      // No need to use response.ok, axios throws an error automatically if status is not 2xx
      // console.log('Submission Success:', response.data);  // response.data is the parsed JSON response
      toast({
        title: "Success",
        description: "Entries created successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      reset();  // Reset the form after successful submission
      fetchRecords();  // Fetch the updated list of records
  
    } catch (error) {
      // Handle error response
      // console.error('Failed to submit form:', error);
      // console.error("Form data:", data);  // Log form data for debugging
      toast({
        title: "Error",
        description: "Failed to create entries",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleNext = () => {
    if (currentIndex < records.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNewRecord = () => {
    reset(initialFormValues);
    setCurrentIndex(-1);
  };

  return (
    <div className="container">
      <h2>Manage Learners</h2>

      <div>
        <Input
          type="text"
          placeholder="Search"
          value={searchParam}
          onChange={(e) => setSearchParam(e.target.value)}
          width="max(50vw, 300px)"
        />
        <Button onClick={handleSearch} colorScheme="green">Search</Button>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        {fieldSchema.map((field) => {
          if (field.type === 'list') {
            return (
              <FormControl key={field.name} isInvalid={errors[field.name]}>
                <FormLabel htmlFor={field.name}>{field.label}</FormLabel>
                <Controller
                  name={field.name}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <List
                      field={field}
                      lov={field.lov} // Assuming lov refers to the list of values
                      value={value}
                      onChange={onChange}
                      width="max(50vw, 300px)"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors[field.name] && `${field.label} is required`}
                </FormErrorMessage>
              </FormControl>
            );
          } else {
            return (
              <FormControl key={field.name} isInvalid={errors[field.name]}>
                <FormLabel htmlFor={field.name}>{field.label}</FormLabel>
                <Controller
                  name={field.name}
                  control={control}
                  rules={{ required: field.required }}
                  render={({ field: { ref, value, ...rest } }) => (
                    <Input 
                      {...rest} 
                      id={field.name} 
                      type={field.type || 'text'} 
                      readOnly={field.readOnly} 
                      value={field.type === 'date' && value === null ? "" : value}
                      width="max(50vw, 300px)"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors[field.name] && `${field.label} is required`}
                </FormErrorMessage>
              </FormControl>
            );
          }
        })}
        <Box display="flex" mt={4}>
          <Button colorScheme="blue" type="submit" className="button_small">Submit</Button>
          <Button ml={4} onClick={() => reset()} variant="outline">Reset</Button>
          <Button ml={4} onClick={handlePrevious} variant="ghost">Previous</Button>
          <Button ml={4} onClick={handleNext} variant="ghost">Next</Button>
          <Button ml={4} onClick={handleNewRecord} colorScheme="teal">New Record</Button>
        </Box>
      </form>
    </div>
  );
};

export default L_Learners;
