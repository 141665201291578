import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../context/AuthContext';
import { Box, Heading, Input, Button, Select } from '@chakra-ui/react';

// import fieldSchema_json from '../../config/fieldSchema.json';
import apiUrlConfig_json from '../../config/apiUrl.json';

function AccessManager() {
  const apiUrl = apiUrlConfig_json.L_Learners;
  const apiUrl2 = apiUrlConfig_json.L_AccessRoles;
  
  const [loginUsername, setLoginUsername] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [selectedRole, setSelectedRole] = useState(undefined);
  const [user, setUser] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const navigate = useNavigate();
  const { setAuthInfo } = useAuth();

  const handleLogin = async () => {
    try {

      const response = await fetch(`${apiUrl}?user_name=${loginUsername}&user_pwd=${loginPassword}`);
      if (!response.ok) {
        throw new Error(`HTTP status ${response.status}`);
      }
      const users = await response.json();

      // const response = await axios.post(`${apiUrl}authenticate/`, {
      //   user_name: loginUsername,
      //   user_pwd: loginPassword
      // });
  
      // if (response.status !== 200) {
      //   throw new Error(`HTTP status ${response.status}`);
      // }
      // const users = response.data;

      if (users.length > 0) {
        const foundUser = users[0];
        setUser(foundUser);

        // Fetch user roles
        const rolesResponse = await fetch(`${apiUrl2}?user_id=${foundUser.id}`);
        if (!rolesResponse.ok) {
          throw new Error(`HTTP status ${rolesResponse.status}`);
        }
        const roles = await rolesResponse.json();

        setUserRoles(roles.map(role => ({
          role_id: role.role_id,
          role_name: role.role_name
        })));

//        alert('Login successful');
//      if (userRoles.length == 1) {handleProceed();}
      } else {
        alert('Login failed');
      }
    } catch (error) {
      console.error('Error during login:', error);
      alert(`Login failed: ${error.message}`);
    }
  };

  const handleProceed = () => {
    let role = selectedRole;

    if (!role && userRoles.length > 0) {
      role = userRoles[0].role_id;
      console.log("role 2", role);
      setSelectedRole(role);
      console.log("selectedRole 2", selectedRole);
    }
    if (user && role) {
      setAuthInfo({
        user_id: user.id,
        role_id: Number(role)
      });

      if (role === 1) { navigate('/learning/Assessment'); }
      if (role > 1) { navigate('/learning'); }
    }
  };

  return (
    <Box maxW="md" mx="auto" mt={10} p={5} borderWidth={1} borderRadius="lg">
      <Heading as="h1" size="xl" textAlign="center" mb={5}>
        Access Manager
      </Heading>
      <Input
        placeholder="Enter Username"
        value={loginUsername}
        onChange={(e) => setLoginUsername(e.target.value)}
        mb={3}
        fontSize="xl" // or use "xl" for even larger font size
        fontWeight="bold"
      />
      <Input
        placeholder="Enter Password"
        type="password"
        value={loginPassword}
        onChange={(e) => setLoginPassword(e.target.value)}
        mb={3}
        fontSize="xl" // or use "xl" for even larger font size
        fontWeight="bold"
      />
      <Button colorScheme="blue" onClick={handleLogin} width="full" mb={3}>
        Login
      </Button>
      {userRoles.length > 0 && (
        <>
          <Heading as="h3" size="md" mb={3}>
            Select Role
          </Heading>
          <Select
//            placeholder="Select your role"
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
            mb={3}
          >
            {userRoles.map((role) => (
              <option key={role.role_id} value={role.role_id}>
                {role.role_name}
              </option>
            ))}
          </Select>
{/*          <Button colorScheme="green" onClick={handleProceed} width="full" isDisabled={!selectedRole}> */}
          <Button colorScheme="green" onClick={handleProceed} width="full" > 
            Proceed
          </Button>
        </>
      )}
    </Box>
  );

}

export default AccessManager;
