import React, { useState, useEffect } from 'react';
import '../../styleSheets/quizStyles.css';
import { Box, Button, Heading, Text, Radio, RadioGroup, Stack, Checkbox, CheckboxGroup } from "@chakra-ui/react";
import apiUrlConfig_json from '../../config/apiUrl.json';
import Latex from 'react-latex';

const A_TaskMCQ_Preview = ({ currentTaskFile, showSubmitButton, onAssessmentComplete, taskData }) => {

    const apiUrl = apiUrlConfig_json.CMS;

    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState({});
    const [incorrectAnswers, setIncorrectAnswers] = useState([]);
    const [showCorrectAnswers, setShowCorrectAnswers] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [date_stop, setDate_stop] = useState(null);

    const isRTL = text => {
        const rtlChars = /[\u0600-\u06FF\u0750-\u077F]/;
        return rtlChars.test(text);
    };

    const renderText = (text) => {
        if (isRTL(text)) {
            return <Text dir="rtl" textAlign="right" fontFamily="'Jameel Noori Nastaleeq', serif" fontSize="25px"><Latex>{text}</Latex></Text>;
        } else {
            return <Text><Latex>{text}</Latex></Text>;
        }
    };

    useEffect(() => {
        if (currentTaskFile) {
            const fetchTaskData = async () => {
                try {
                    const taskResponse = await fetch(`${apiUrl}gcs_download/${currentTaskFile}/`);
                    const taskData = await taskResponse.json();

                    const { file_contents, content_type } = taskData;
                    let parsedContents = file_contents;
                    if (typeof file_contents === 'string') {
                        parsedContents = JSON.parse(file_contents);
                    }
                    setQuestions(parsedContents);
                    setLoading(false);
                        
                } catch (error) {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                }
            };
            fetchTaskData();
        }
        if (taskData) {
            const { file_contents, content_type } = taskData;
            let parsedContents = file_contents;
            if (typeof file_contents === 'string') {
                parsedContents = JSON.parse(file_contents);
            }
            setQuestions(parsedContents);
            setLoading(false);
        }
    }, [currentTaskFile, taskData]);

    const handleOptionChange = (questionIndex, selectedOption, isCheckbox = false) => {
        if (isCheckbox) {
            setAnswers(prevAnswers => ({
                ...prevAnswers,
                [questionIndex]: {
                    ...prevAnswers[questionIndex],
                    [selectedOption]: !prevAnswers[questionIndex]?.[selectedOption]
                }
            }));
        } else {
            setAnswers({
                ...answers,
                [questionIndex]: selectedOption,
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const incorrect = questions.filter((question, index) => {
            if (question['Q-type'] === 'checkbox') {
                const correctAnswers = question.A.sort().join(',');
                const userAnswers = Object.keys(answers[index] || {}).filter(key => answers[index][key]).sort().join(',');
                return correctAnswers !== userAnswers;
            }
            return question.A !== answers[index];
        });

        setIncorrectAnswers(incorrect);
        setIsSubmitted(true);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (isSubmitted) {
        return (
            <>
                <Heading as="h2" size="lg" mb={4}>
                    Review Your Answers
                </Heading>
                {questions.map((question, index) => (
                    <Box key={index} mb={4} p={4} borderWidth="1px" borderRadius="md">
                        <Text mb={2}>
                            <strong>Question {question['Q-n']}:</strong> {renderText(question.Q)}
                        </Text>
                        <Text mb={2}>
                            <strong>Your answer:</strong> {renderText(question['Q-type'] === 'checkbox' ? Object.keys(answers[index] || {}).
                                filter(opt => answers[index][opt]).map(opt => question[opt]).join(' *** ') : (answers[index] ? 
                                question[answers[index]] : 'No answer selected'))}
                        </Text>
                        {incorrectAnswers.find(inc => inc['Q-n'] === question['Q-n']) && (
                            <Text fontWeight="bold" color="red.500">
                                Correct answer: {renderText(Array.isArray(question.A) ? question.A.map(opt => question[opt]).
                                    join(' *** ') : question[question.A])}
                            </Text>
                        )}
                    </Box>
                ))}
                <Button onClick={onAssessmentComplete} colorScheme="teal" mt={4}>
                    Close
                </Button>
            </>
        );
    }

    return (
        <>
            <div className="quiz-container">
                <h3 style={{ color: "blue", fontSize: "20px" }}>Task (MCQ)</h3>
                <form onSubmit={handleSubmit}>
                    {questions.map((question, index) => (
                        <div key={index} className="question" >
                            <p>{renderText(`${question['Q-n']}: ${question.Q}`)}</p>
                            {!question['Q-type'] || question['Q-type'] === 'radio' ? (
                                <RadioGroup onChange={(value) => handleOptionChange(index, value)} value={answers[index]} mb={4}>
                                    <Stack direction="column">
                                        {['a', 'b', 'c', 'd'].map((opt) => (
                                            <Box className="option" key={opt} dir={isRTL(question.Q) ? "rtl":"ltr"} style={isRTL(question.Q) ? { textAlign: 'right' } : {}}>
                                                <Radio value={`opt-${opt}`}>
                                                    {renderText(`${question[`opt-${opt}`]}`)}
                                                </Radio>
                                            </Box>
                                        ))}
                                    </Stack>
                                </RadioGroup>
                            ) : (
                                <CheckboxGroup value={Object.keys(answers[index] || {}).filter(key => answers[index]?.[key])} onChange={(values) => values.forEach(val => handleOptionChange(index, val, true))} mb={4}>
                                    <Stack direction="column">
                                        {['a', 'b', 'c', 'd'].map((opt) => (
                                            <Box className="option" key={opt} dir={isRTL(question.Q) ? "rtl":"ltr"} style={isRTL(question.Q) ? { textAlign: 'right' } : {}}>
                                                <Checkbox isChecked={answers[index]?.[`opt-${opt}`]} onChange={() => handleOptionChange(index, `opt-${opt}`, true)}>
                                                    {renderText(`${question[`opt-${opt}`]}`)}
                                                </Checkbox>
                                            </Box>
                                        ))}
                                    </Stack>
                                </CheckboxGroup>
                            )}
                        </div>
                    ))}
                    {showSubmitButton && <button type="submit" className="submit-button" disabled={isSubmitted}>SUBMIT</button> }
                </form>
            </div>
        </>
    );
};

export default A_TaskMCQ_Preview;
