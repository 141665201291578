import React, { useState, useEffect } from 'react';
import '../../styleSheets/quizStyles.css';
import { Box, Button, Heading, Text } from "@chakra-ui/react";
import apiUrlConfig_json from '../../config/apiUrl.json';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex';
import TaskLines from './TaskLines'; // Importing the secondary component
import axios from 'axios'; // Importing Axios

const Task = ({ participationId, date_start, apiUrl4, apiUrl5, user_id, currentTaskFile, 
        res_line_number, res_line_id, onTaskSubmit }) => {

    const apiUrl = apiUrlConfig_json.CMS;

    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);    
    const [date_stop, setDate_stop] = useState(null);
    const [finalAnswers, setFinalAnswers] = useState({});
    const [incorrectAnswers, setIncorrectAnswers] = useState({});
    const [finalMarks, setFinalMarks] = useState({});
    const [correctAnswers, setCorrectAnswers] = useState({});

    const isRTL = text => {
        const rtlChars = /[\u0600-\u06FF\u0750-\u077F]/;
        return rtlChars.test(text);
    };

    const renderText = (text) => {
        if (isRTL(text)) {
            return <Text dir="rtl" textAlign="right" fontFamily="'Jameel Noori Nastaleeq', serif" fontSize="25px"><Latex>{text}</Latex></Text>;
        } else {
            return <Text><Latex>{text}</Latex></Text>;
        }
    };

    useEffect(() => {
        const fetchTaskData = async () => {
            try {
                const taskResponse = await axios.get(`${apiUrl}gcs_download/${currentTaskFile}/`);
                // const taskResponse = await axios.post(`${apiUrl}gcs_download/`, { secure_name: currentTaskFile });

                const taskData = taskResponse.data;
                const { file_contents, content_type } = taskData;

                let parsedContents = file_contents;
                if (typeof file_contents === 'string') {
                    parsedContents = JSON.parse(file_contents);
                }

                setQuestions(parsedContents);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        if (currentTaskFile) {
            fetchTaskData();
        }            

    }, [currentTaskFile]);

    const handleSubmit = async (answers, marks, incorrectAnswers, correctAnswers) => {
        setIsSubmitting(true);
        
        setFinalAnswers(answers);
        setFinalMarks(marks);
        setIncorrectAnswers(incorrectAnswers);
        setCorrectAnswers(correctAnswers);

        const date_end = new Date().toISOString();
        setDate_stop(date_end);
        const time_taken = Math.round((new Date(date_end) - new Date(date_start)) / 60000);

        try {
            const response = await axios.post(`${apiUrl5}`, {
                participation: participationId,    
                res_line: res_line_id,
                status: 'S',
                date_submitted: date_end,
            });
            const participation_line_id = response.data.id;

            const evaluations = questions.map((question, index) => {
                return {
                    participation_id: participationId,
                    participation_line: participation_line_id,
                    res_line: res_line_id,
                    question_number: res_line_number,
                    // question: question['Q-type'] === 'textbox' ? question.Q : null,
                    question: ['textbox', 'media'].includes(question['Q-type']) ? question.Q : null,
                    part_number: question['Q-n'],
                    q_type: question['Q-type'],
                    obt_marks: marks[index],
                    answer: JSON.stringify(answers[index]),
                    ai_run_status: question['Q-type'] === 'textbox' ? 'A': null,
                };
            });
    
            await axios.post(`${apiUrl4}bulk_insert/`, evaluations);


            setIsSubmitted(true);
        } catch (error) {
            console.error('Error updating database:', error);
        } finally {
            setIsSubmitting(false);
            onTaskSubmit(); // Notify parent component about task submission
        }
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (isSubmitted) {
        return (
            <>
                <Heading as="h2" size="lg" mb={4}>
                    Review Your Answers
                </Heading>

                {questions.map((question, index) => (
                    <Box key={index} mb={4} p={4} borderWidth="1px" borderRadius="md">
                        <Text mb={2}>
                            <strong>Question {question['Q-n']}:</strong> {renderText(`${question.Q} (${question.max_marks} marks)`) }
                        </Text>
                        <Text mb={2}>
                            <strong>Your answer:</strong> {renderText(question['Q-type'] === 'checkbox' ? Object.keys(finalAnswers[index] || {}).filter(opt => finalAnswers[index][opt]).map(opt => question[opt]).join(' *** ') : (question['Q-type'] === 'radio' ? question[finalAnswers[index]] :((question['Q-type'] === 'textbox' ? finalAnswers[index] : 'No answer selected'))))} 
                        </Text>
                        <Text mb={2}>
                            <strong>Your marks:</strong> {finalMarks[index]} 
                        </Text>
                        {(incorrectAnswers[index] === "true") && (
                            // <Text fontWeight="bold" color="red.500">
                            <Text fontWeight="normal" color="red.500">
                                <strong>Correct answer:</strong> {renderText(question['Q-type'] === 'checkbox' ? question.A.map(opt => question[opt]).join(' *** ') : (question['Q-type'] === 'radio' ? question[question.A] : (question['Q-type'] === 'textbox' ? correctAnswers[index] : 'No answer available')) )}
                            </Text>
                        )}
                    </Box>
                ))}
            </>
        );
    }

    return (
        <div className="quiz-container">
            <h3 style={{ color: "blue", fontSize: "20px" }}>Task (MCQ)</h3>
            <TaskLines
                questions={questions}
                onSubmit={handleSubmit}
                renderText={renderText}
                isRTL={isRTL}
            />
        </div>
    );
};

export default Task;
