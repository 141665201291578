import React, { useState } from 'react';
import axios from 'axios';
import apiUrlConfig_json from '../../config/apiUrl.json';

const CreateVectorStore = () => {
  const apiUrl = apiUrlConfig_json.AAPI;
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post(`${apiUrl}create-vector-store/`, { name })
      .then(response => {
        if (response.data.success) {
          setMessage(`Vector store created with ID: ${response.data.vector_store_id}`);
        } else {
          setMessage(`Error: ${response.data.error}`);
        }
      })
      .catch(error => {
        setMessage(`Error: ${error.message}`);
      });
  };

  return (
    <div>
      <h2>Create Vector Store</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" value={name} onChange={e => setName(e.target.value)} required />
        </label>
        <button type="submit">Create</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default CreateVectorStore;
