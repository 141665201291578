import React, { useState, useEffect } from 'react';
import '../../styleSheets/quizStyles.css'; // Adjust the path according to your project structure
import apiUrlConfig_json from '../../config/apiUrl.json';
import TextGet from '../shared/TextGet'; // Adjust the path as necessary
import A_TaskMCQ_Preview from './A_TaskMCQ_Preview'; // Adjust the path as necessary

function A_TakeAssessments_Preview({ assessmentId, showSubmitButton }) {
    const apiUrl2 = apiUrlConfig_json.A_Assessments;
    const apiUrl3 = apiUrlConfig_json.A_Resource_Lines;

    const [loading, setLoading] = useState(true);
    const [resourceLines, setResourceLines] = useState([]);

    const fetchResourceData = async (assessmentId) => {
        try {
            const assessmentResponse = await fetch(`${apiUrl2}${assessmentId}/`);
            const assessmentData = await assessmentResponse.json();
            const resourceId = assessmentData.resource_id;

            const resourceResponse = await fetch(`${apiUrl3}?res=${resourceId}`);
            const resourceData = await resourceResponse.json();
            const resource = resourceData;
            console.log("resources ",resource);

            setResourceLines(resource);
            console.log("res lines ", resourceLines);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchResourceData(assessmentId);
    }, [assessmentId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
        {resourceLines.map((resourceLine, index) => (
            <div key={index}>
                {resourceLine.res_class === "TEXT" ? (
                    <div className="text-container">
                        <TextGet currentTextFile={resourceLine.cms_res_id} />
                    </div>
                ) : 
                (
                    <A_TaskMCQ_Preview currentTaskFile={resourceLine.cms_res_id} showSubmitButton={showSubmitButton}/>
                )}
            </div>
        ))}
        </>
    );
}

export default A_TakeAssessments_Preview;
