// 240722, can take care of parent-child form

import React from 'react';
import { Input, Button } from '@chakra-ui/react';

const SearchRecords = ({ searchParam, setSearchParam, handleSearch, searchChildRecords }) => {
  return (
    <div>
      <Input
        type="text"
        placeholder="Search"
        value={searchParam}
        onChange={(e) => setSearchParam(e.target.value)}
        style={{ width: "50vw" }}
      />
      <Button onClick={() => handleSearch(searchParam)} colorScheme="green">Search</Button>
      {searchChildRecords && (
        <Button onClick={() => searchChildRecords(searchParam)} colorScheme="blue" ml={2}>Search Child Records</Button>
      )}
    </div>
  );
};

export default SearchRecords;
