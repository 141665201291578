import React from 'react';
import { Button } from "@chakra-ui/react";

const TextListen = ({ textData }) => {
  const startSpeech = () => {
    if ('speechSynthesis' in window) {
      const synth = window.speechSynthesis;
      const utterance = new SpeechSynthesisUtterance(textData);
      utterance.rate = 1;
      utterance.pitch = 1;
      utterance.volume = 1;
      synth.speak(utterance);
    } else {
      alert("Sorry, your browser doesn't support text to speech!");
    }
  };

const stopSpeech = () => {
    if ('speechSynthesis' in window) {
      window.speechSynthesis.cancel();
    }
};

  return (
    <div className="level-controls">
      <Button mt={2} ml={0} colorScheme="teal" onClick={startSpeech}>Listen</Button>
      <Button mt={2} ml={0} colorScheme="purple" onClick={stopSpeech}>Stop</Button>
      {/* <button type="button" onClick={handleSpeech} className="submit-button">Listen</button>
      <button type="button" onClick={stopSpeech} className="submit-button">Stop</button>       */}
    </div>
  );
};

export default TextListen;
