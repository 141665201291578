import React from 'react';
import C_Network from './C_Network'; // Corrected import path

const curriculumData = {
    nodes: [
        { data: { id: 'S', label: 'Subject' } },
        { data: { id: '1', parent: 'S', label: 'Calculus' } },
        { data: { id: '2', parent: 'S', label: 'Algebra' } },
        { data: { id: '3', parent: '1', label: 'Vector Calculus' } },
        { data: { id: '4', parent: '1', label: 'Linear Algebra' } }
    ],
    edges: [
//        { data: { source: 'S', target: '1' } },
//        { data: { source: 'S', target: '2' } },
//        { data: { source: '1', target: '3' } },
        { data: { source: '1', target: '4' } }
    ]
};


function C_Data( {handleView} ) {
    return <C_Network elements={curriculumData} />;
}

export default C_Data;