import React, { useState } from 'react';

function CurriculumMatrix() {
  const [levels, setLevels] = useState([]);
  const [levelName, setLevelName] = useState('');
  const [containerName, setContainerName] = useState('');

  const addLevel = () => {
    const newLevel = { id: levels.length + 1, name: levelName, containers: [] };
    setLevels([...levels, newLevel]);
    setLevelName(''); // Reset for next input
  };

  const addContainerToLevel = (levelId) => {
    const updatedLevels = levels.map(level => {
      if (level.id === levelId) {
        return { ...level, containers: [...level.containers, containerName] };
      }
      return level;
    });
    setLevels(updatedLevels);
    setContainerName(''); // Reset for next input
  };

  // Placeholder form for adding levels and containers
  // In a real application, you'd likely have more sophisticated forms and validation

  return (
    <div>
      <h2>Curriculum Matrix</h2>
      {/* Form to add levels */}
      <input value={levelName} onChange={e => setLevelName(e.target.value)} placeholder="Level Name" />
      <button onClick={addLevel}>Add Level</button>
      {/* Form to add containers - simplified for example */}
      {/* In practice, you'd select a level then add a container to it */}
      <input value={containerName} onChange={e => setContainerName(e.target.value)} placeholder="Container Name" />
      <button onClick={() => addContainerToLevel(1)}>Add Container to Level 1</button>
      
      {/* Display the current structure */}
      {levels.map(level => (
        <div key={level.id}>
          <h3>{level.name}</h3>
          {level.containers.map((container, index) => <p key={index}>{container}</p>)}
        </div>
      ))}
    </div>
  );
}

export default CurriculumMatrix;
