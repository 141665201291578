import React, { useState, useEffect } from 'react';
import { Box, Textarea, Text, Button } from "@chakra-ui/react";
import TextListen from './TextListen';
// import SpeechToText from './SpeechToText';
import AudioRecorder from './AudioRecorder';
import MediaRecorder from './MediaRecorder';

const TaskTextbox = ({ question, index, onSubmit, renderText, isRTL, allowSpeechToText = "true", 
                        wordLimit = 100, timeLimit = 1, maxMarks = 10, lang = 'en' }) => {
    const [localAnswer, setLocalAnswer] = useState('');
    const [localAnswerAppend, setLocalAnswerAppend] = useState('');
    const [answer, setAnswer] = useState('');
    const [wordCount, setWordCount] = useState(0);


    useEffect(() => {
        const obtainedMarks = null;
        const isCorrect = "yes";
        // const correctAnswerContent = 'Correct Answer';
        // onSubmit("textbox", index, answer, obtainedMarks, isCorrect, correctAnswerContent);
        onSubmit(index, answer, obtainedMarks, isCorrect);

    }, [answer]);

    const handleBlur = (event) => {
        const value = event.target.value;
        setAnswer(value);
    };

    const handleChange = (event) => {
        if (wordLimit) {
            const words = event.target.value.split(/\s+/);
            setWordCount (words.length);
            if (words.length <= wordLimit) {            
                setLocalAnswer(event.target.value)
            }
        } else {
            setLocalAnswer(event.target.value)
        }
    };

    const handleTranscribedText = (transcription) => {
        if (transcription) {
            setLocalAnswer(prev => {
                const newAnswer = prev + ' ' + transcription;
                const words = newAnswer.split(/\s+/);
                if (wordLimit && words.length > wordLimit) {
                    return words.slice(0, wordLimit).join(' ');
                }
                return newAnswer;
            });
        }
    };

    
    return (
        <Box key={index} mb={2} p={1} borderWidth="1px" borderRadius="md">
            {/* <Text mb={2}>
                {renderText(`${question['Q-n']}: ${question.Q}`)}
            </Text> */}

            { (allowSpeechToText === "true") && <AudioRecorder lang={lang} timeLimit={timeLimit} handleTranscribedText={handleTranscribedText}/>}
            {/* <MediaRecorder mediaType={'video'} timeLimit={timeLimit}/> */}


            <Textarea
                placeholder="Type your answer here..."
                value={localAnswer}
                onBlur={handleBlur}
                onChange={handleChange}
                dir={isRTL(question.Q) ? "rtl" : "ltr"}
                lang={lang}
                size="sm"
                style={{ width: '80vw', height: '250px', fontSize: '20px', backgroundColor: "black", color: 'white', fontWeight: 'normal', fontFamily: (lang === 'ur') ? "'Jameel Noori Nastaleeq', serif" : (lang === 'en' ? "Arial, sans-serif": "Arial, sans-serif") }}
            />

            {/* {lang==="en" && <SpeechToText setTextData={setLocalAnswerAppend} /> } */}

            {(wordLimit) && 
                // <Text>Word limit: {wordLimit} , Words entered: {wordCount}</Text> 
                <Text>
                    Word limit: <Box as="span" fontSize="lg" fontWeight="bold" color="blue.500">{wordLimit}</Box>, 
                    <Box as="span" mx={6}> {/* mx adds horizontal margin */}
                    Words entered: <Box as="span" fontSize="lg" fontWeight="bold" color="red.500">{wordCount}</Box>
                    </Box>
                </Text>                
            }

            {!isRTL(question.Q) && <TextListen textData={localAnswer} />}

         </Box> 
    );
};

export default TaskTextbox;
