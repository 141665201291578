import React, { useState, useEffect } from 'react';
import '../../styleSheets/quizStyles.css';
import { Button, Box, Radio, RadioGroup, Checkbox, CheckboxGroup, Stack, Heading, Text } from "@chakra-ui/react";
import apiUrlConfig_json from '../../config/apiUrl.json';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex';
import axios from 'axios'; // Importing Axios
import TeacherFeedback from './TeacherFeedback';
import TextGet from '../shared/TextGet';

const TaskReviewTeacher = ({ participationId, apiUrl4, apiUrl5, user_id, currentTaskFile, 
        res_line_number, res_line_id, qNumber, enableTeacherFeedback, handleClose }) => {

    const apiUrl = apiUrlConfig_json.CMS;

    const [questions, setQuestions] = useState([]);
    const [evaluations, setEvaluations] = useState([]);

    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);    
    const [date_stop, setDate_stop] = useState(null);

    const [showMediaFile, setShowMediaFile] = useState({});
    const handleToggleShowMediaFile = (index) => {
        setShowMediaFile((prevState) => ({
            ...prevState,
            [index]: !prevState[index]  // Toggle the specific question's showText state
        }));
    };

    const isRTL = text => {
        const rtlChars = /[\u0600-\u06FF\u0750-\u077F]/;
        return rtlChars.test(text);
    };

    const renderText = (text) => {
        if (isRTL(text)) {
            return <Text dir="rtl" textAlign="right" fontFamily="'Jameel Noori Nastaleeq', serif" fontSize="25px"><Latex>{text}</Latex></Text>;
        } else {
            return <Text><Latex>{text}</Latex></Text>;
        }
    };

    useEffect(() => {
        const fetchTaskData = async () => {
            try {
                const taskResponse = await axios.get(`${apiUrl}gcs_download/${currentTaskFile}/`);
                const taskData = taskResponse.data;
                const { file_contents, content_type } = taskData;

                let parsedContents = file_contents;
                if (typeof file_contents === 'string') {
                    parsedContents = JSON.parse(file_contents);
                }
                // setQuestions(parsedContents);
                if (qNumber) {
                    setQuestions(parsedContents.filter(question => question['Q-n'] === qNumber));
                } else {
                    setQuestions(parsedContents);
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        if (currentTaskFile) {
            fetchTaskData();
        }            

    }, [currentTaskFile]);

    useEffect(() => {
        const fetchEvaluationData = async () => {
            try {
                const evaluationResponse = await axios.get(`${apiUrl4}?participation_id=${participationId}&res_line_id=${res_line_id}`);
                const evaluationData = evaluationResponse.data;
                setEvaluations(evaluationData);
            } catch (error) {
                console.error('Error fetching data:', error);
            } 
        };

        if (participationId) {
            fetchEvaluationData();
        }            

    }, [participationId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Heading as="h2" size="lg" mb={4}>
                Review Your Answers
            </Heading>

            {questions.map((question, index) => {
                // const evaluation = evaluations.find(evaluation => evaluation.part_number === question['Q-n']);
                const evaluation = evaluations.find(evaluation => evaluation.part_number === String(question['Q-n']));
                const answer = evaluation?.answer || '';
                // const obt_marks = evaluation?.obt_marks || evaluation?.ai_obt_marks || 'None';
                const obt_marks = (evaluation?.obt_marks !== null && evaluation?.obt_marks !== undefined) ? evaluation.obt_marks : (evaluation?.ai_obt_marks !== null && evaluation?.ai_obt_marks !== undefined ? evaluation.ai_obt_marks : 'None');
                const feedback = evaluation?.feedback || evaluation?.ai_feedback || 'No feedback available';
                const model_answer = evaluation?.model_answer || evaluation?.ai_model_answer || 'No model answer available';

                // Checkbox answer in database is string (or strinified obejct), so convert into an object, 
                // then sort, then convert  back to string so that we can compare with stringified question.A (i.e. correct answer)
                // const sortedCheckboxAnswer = JSON.stringify(Object.keys(JSON.parse(answer)).filter(key => JSON.parse(answer)[key]).sort());
                const sortedCheckboxAnswer = answer === 'null' 
                ? null 
                : JSON.stringify(
                    Object.keys(JSON.parse(answer))
                    .filter(key => JSON.parse(answer)[key])
                    .sort()
                );                    


                const incorrectAnswer = question['Q-type'] === 'checkbox'
                    ? !(JSON.stringify(question.A) === sortedCheckboxAnswer.replace(/:true/g,"").replace(/{/g,"[").replace(/}/g,"]"))
                    : (question['Q-type'] === 'radio'
                        ? !(answer.replace(/"/g, "") === question.A)
                        : false)

                const correctAnswer = question['Q-type'] === 'checkbox'
                    // ? question.A.map(opt => question[opt] || 'N/A').join(' *** ')
                    ? JSON.stringify(question.A).replace(/opt-[abcd]/g, (matched) => question[matched] || matched)
                    : (question['Q-type'] === 'radio'
                        ? question[question.A] || 'N/A'
                        : model_answer);

                const yourAnswer = question['Q-type'] === 'checkbox'
                ? sortedCheckboxAnswer
                    .replace(/opt-[abcd]/g, (matched) => question[matched] || matched)
                    .replace(/:true/g,"")
                    .replace(/{/g,"[")
                    .replace(/}/g,"]")
                : (question['Q-type'] === 'radio'
                ? (question[answer.replace(/"/g, "")] )
                : answer);

                        
                return (
                    <Box key={index} mb={4} p={4} borderWidth="1px" borderRadius="md">
                        <Text mb={2}>
                            {/* <strong>Question {question['Q-n']}:</strong> {renderText(`${question.Q} (${question.max_marks} marks)`)} */}
                            <strong>Question {question['Q-n']}:</strong> {renderText(`${question.Q} (${question.max_marks || 'None'} marks)`)}
                        </Text>
                        
                        {/* Display options for radio and checkbox type questions */}
                        {question['Q-type'] === 'checkbox' && (
                            <CheckboxGroup mb={4}>
                                <Stack direction="column">
                                    {['a', 'b', 'c', 'd'].map((opt) => (
                                        <Box key={opt} dir={isRTL(question.Q) ? "rtl" : "ltr"} style={isRTL(question.Q) ? { textAlign: 'right' } : {}}>
                                            <Checkbox isChecked={answer[`opt-${opt}`] || false}>
                                                {renderText(question[`opt-${opt}`])}
                                            </Checkbox>
                                        </Box>
                                    ))}
                                </Stack>
                            </CheckboxGroup>
                        )}
                        {question['Q-type'] === 'radio' && (
                            <RadioGroup value={answer} mb={4}>
                                <Stack direction="column">
                                    {['a', 'b', 'c', 'd'].map((opt) => (
                                        <Box key={opt} dir={isRTL(question.Q) ? "rtl" : "ltr"} style={isRTL(question.Q) ? { textAlign: 'right' } : {}}>
                                            <Radio value={`opt-${opt}`}>
                                                {renderText(question[`opt-${opt}`])}
                                            </Radio>
                                        </Box>
                                    ))}
                                </Stack>
                            </RadioGroup>
                        )}

                        {/* <Text mb={2}>
                            <strong>Your answer:</strong> {renderText(yourAnswer)}
                        </Text> */}

                        <Text mb={2}>
                            <strong>Your answer:</strong> 
                            {!(question['Q-type'] === 'media') 
                                ? renderText(yourAnswer) 
                                : (
                                    <>
                                        {/* {!(yourAnswer.replace(/^"|"$/g, '') == '') && ( */}
                                        { !(yourAnswer === 'null') && (
                                            <Button onClick={() => handleToggleShowMediaFile(index)}>
                                                {showMediaFile[index] ? 'Hide Media File' : 'Show Media File'}
                                            </Button>
                                        )}
                                        {showMediaFile[index] && !(yourAnswer === 'null') && (
                                            <TextGet currentTextFile={yourAnswer.replace(/^"|"$/g, '')} cmsType={'learner'} />
                                        )}
                                    </>
                                )
                            }
                            </Text>

                        <Text mb={2}>
                            <strong>Your marks:</strong> {obt_marks}
                        </Text>

                        {['radio', 'checkbox'].includes(question['Q-type']) && (incorrectAnswer) && (
                            <Text fontWeight="normal" color="red.500">
                                <strong>Correct answer:</strong> {renderText(correctAnswer)}
                            </Text>
                        )}

                        { (question['Q-type'] === 'textbox' || question['Q-type'] === 'media') && (
                            <>
                                <Text mb={2}>
                                    <strong>Feedback:</strong> {renderText(feedback)}
                                </Text>
                                <Text mb={2}>
                                    <strong>Model answer:</strong> {renderText(model_answer)}
                                </Text>
                                {enableTeacherFeedback && 
                                    <TeacherFeedback
                                        evaluationId={evaluation.id}
                                        apiUrl={apiUrl4}
                                        initialFeedback={evaluation.feedback}
                                        initialModelAnswer={evaluation.model_answer}
                                        initialObtMarks={evaluation.obt_marks}
                                        onSubmit={() => console.log('Feedback submitted')}
                                        lang={question['lang']}
                                        isRTL={isRTL}
                                        timeLimit={question['time_limit']}
                                        handleClose={handleClose}
                                    />
                                }
                            </>
                        )}
                    </Box>
                );
            })}
        </>
    );

};

export default TaskReviewTeacher;
