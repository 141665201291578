import React, { useEffect, useState } from 'react';
import axios from 'axios';
import apiUrlConfig_json from '../../config/apiUrl.json';

const ListVectorStore = () => {
  const apiUrl = apiUrlConfig_json.AAPI;
  const [vectorStores, setVectorStores] = useState([]);

  useEffect(() => {
    axios.get(`${apiUrl}vector-stores/`)
      .then(response => {
        setVectorStores(response.data.vector_stores);
      })
      .catch(error => {
        console.error('There was an error fetching the vector stores!', error);
      });
      console.log(vectorStores)
  }, []);

  return (
    <div>
      <h2>Vector Stores</h2>
      <ul>
        {vectorStores.map(vs => (
          <li key={vs.vector_store_id}>
            <p>ID: {vs.vector_store_id}</p>
            <p>Name: {vs.name}</p>
            {/* <p>Created At: {new Date(vs.created_at).toLocaleString()}</p> */}
            <p>Status: {vs.status}</p>
            <p>Total Files: {vs.total_files}</p>
            <p>Usage Bytes: {vs.usage_bytes}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListVectorStore;
