import React from "react";
import ReactDOM from "react-dom";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";

const JsonSchemaForm = () => {

    const schema = {
  title: "A registration form",
  description: "A simple form example.",
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Name"
    },
    age: {
      type: "integer",
      title: "Age"
    },
    gender: {
      type: "string",
      enum: ["Male", "Female", "Other"],
      title: "Gender"
    },
    terms: {
      type: "boolean",
      title: "I accept the terms and conditions"
    }
  }
};

const uiSchema = {
  age: {
    "ui:widget": "updown"
  },
  gender: {
    "ui:widget": "radio"
  },
  terms: {
    "ui:widget": "checkbox"
  }
};

return(
  <Form schema={schema} uiSchema={uiSchema} validator={validator}/>
);

};

export default JsonSchemaForm;
