import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

const useDeleteRecordMR = (apiUrl, fetchRecords, reset, initialFormValues, currentIndex, setCurrentIndex, records) => {
  const navigate = useNavigate();
  const toast = useToast();

  const handleDelete = async () => {
    if (currentIndex !== -1 && currentIndex < records.length) {
      const recordId = records[currentIndex].id;
//      console.log(records);   // use only during troublshooting
//      console.log(recordId);  // use only during troublshooting

      try {
        const response = await fetch(`${apiUrl}${recordId}`, {
          method: 'DELETE',
        });
        if (response.ok) {

          toast({
            title: "Success",
            description: "Record deleted successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
    
          fetchRecords(); // Refresh the records after deletion
          reset(initialFormValues); // Reset the form

          // Navigate to the next, previous, or new record, or return to the home page
          if (records.length > 1) {
            if (currentIndex === records.length - 1) {
              setCurrentIndex(currentIndex - 1); // Go to the previous record if the current is the last
            } else {
              setCurrentIndex(currentIndex); // Stay on the same index as it will now be the next record
            }
          } else {
            navigate('/learning/Teacher/'); // Navigate to home page if no records are left
          }
        } else {
          console.error('Failed to delete the record');
        }
      } catch (error) {
        console.error('Error deleting the record:', error);
        toast({
          title: "Error",
          description: "Failed to delete record",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return { handleDelete };
};

export default useDeleteRecordMR;
