// Learning.js
import React from 'react';
import { useRoutingNavigation } from '../routing/useNavigation'; // Import useHistory hook from React Router
import '../styleSheets/edtech.css'; // Adjust the path according to your project structure

function Learning() {
  
  const { handleRouting } = useRoutingNavigation();

  return (
    <div className="container">
      <h2>Learning</h2>
      <button className="button" onClick={handleRouting('/learning/Curriculum')}>Curriculum</button>
      <button className="button" onClick={handleRouting('/learning/Instruction')}>Instruction </button>
      <button className="button" onClick={handleRouting('/learning/Assessment')}>Assessment</button>
      <button className="button" onClick={handleRouting('/learning/Environment')}>Environment</button>

      <button className="button" onClick={handleRouting('/learning/Teacher')}>Teacher Tasks</button>
      <button className="button" onClick={handleRouting('/learning/Teacher2')}>Teacher Tasks 2</button>
      <button className="button" onClick={handleRouting('/learning/AITutor')}>AI Tutor</button>

      <button className="button" onClick={handleRouting('/learning/Admin')}>Administration</button>
      <button className="button" onClick={handleRouting('/learning-system')}>Return to Learning System</button>      
      {/* Placeholder for navigation functionality */}
    </div>
  );
}

export default Learning;
