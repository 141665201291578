import React, { useState } from 'react';
import { Box, Text, Button, Textarea, Input, useToast } from '@chakra-ui/react';
import axios from 'axios';
import AudioRecorder from '../shared/AudioRecorder'; 
import { useAuth } from '../../context/AuthContext'; // Adjust the path as necessary

const TeacherFeedback = ({ evaluationId, apiUrl, initialFeedback, initialModelAnswer, initialObtMarks, 
    onSubmit, lang, isRTL, timeLimit = 2, handleClose }) => {
    
    const { user_id } = useAuth(); // Get the role_id of the logged on user from auth context
    const toast = useToast();

    const [tFeedback, setTFeedback] = useState(initialFeedback || '');
    const [tModelAnswer, setTModelAnswer] = useState(initialModelAnswer || '');
    const [tObtMarks, setTObtMarks] = useState(initialObtMarks || '');

    const handleSubmit = () => {
        const currentDate = new Date().toISOString(); // Generates the current date and time in ISO 8601 format
        axios.patch(`${apiUrl} ${evaluationId}/`, { 
            feedback: tFeedback, 
            model_answer: tModelAnswer, 
            obt_marks: tObtMarks,
            teacher_id: user_id,
            teacher_feedback_date: currentDate  
            })
            .then(response => {
                console.log("Feedback submitted successfully:", response);
                toast({title: "Success", description: "Updated successfully", status: "success", duration: 5000, isClosable: true,});
                handleClose();  // Navigate out only on success
                if (onSubmit) {
                    onSubmit();
                }
            })
            .catch(error => {
                console.error("Error submitting feedback:", error);
                toast({title: "Error", description: "Failed to update", status: "error", duration: 5000, isClosable: true,});                
            });
    };

    const handleTranscribedText = (setFunction) => (transcription) => {
        if (transcription) {
            setFunction(prev => {
                const newText = prev + ' ' + transcription;
                return newText;
            });
        }
    };

    return (
        <Box>
            <Text mb={2}>
                <strong>Teacher Feedback:</strong>
                <AudioRecorder lang={lang} timeLimit={timeLimit} handleTranscribedText={handleTranscribedText(setTFeedback)} />
                <Textarea
                    placeholder="Type your feedback here..."
                    value={tFeedback}
                    onChange={(e) => setTFeedback(e.target.value)}
                    dir={lang==="en"? "ltr" : "rtl"}
                    lang={lang}
                    size="sm"
                    style={{ width: '90vw', height: '250px', fontSize: '20px', backgroundColor: "black", color: 'white', fontWeight: 'normal', fontFamily: (lang === 'ur') ? "'Jameel Noori Nastaleeq', serif" : (lang === 'en' ? "Arial, sans-serif" : "Arial, sans-serif") }}
                />
            </Text>
            <Text mb={2}>
                <strong>Teacher Model answer:</strong>
                <AudioRecorder lang={lang} timeLimit={timeLimit} handleTranscribedText={handleTranscribedText(setTModelAnswer)} />
                <Textarea
                    placeholder="Type your model answer here..."
                    value={tModelAnswer}
                    onChange={(e) => setTModelAnswer(e.target.value)}
                    dir={lang==="en"? "ltr" : "rtl"}
                    lang={lang}
                    size="sm"
                    style={{ width: '90vw', height: '250px', fontSize: '20px', backgroundColor: "black", color: 'white', fontWeight: 'normal', fontFamily: (lang === 'ur') ? "'Jameel Noori Nastaleeq', serif" : (lang === 'en' ? "Arial, sans-serif" : "Arial, sans-serif") }}
                />
            </Text>
            <Text mb={2}>
                <strong>Obtained Marks:</strong>
                <Input
                    type="number"
                    value={tObtMarks}
                    onChange={(e) => setTObtMarks(e.target.value)}
                    size="sm"
                    style={{ width: '100px', fontSize: '20px', backgroundColor: "black", color: 'white', fontWeight: 'normal', fontFamily: 'Arial, sans-serif' }}
                />
            </Text>
            <Button type="button" onClick={handleSubmit} colorScheme="green">
                Submit teacher feedback
            </Button>
        </Box>
    );
};

export default TeacherFeedback;
