import { useAuth } from '../context/AuthContext'; // Adjust the path as necessary
import accessControl_json from '../config/accessControl.json';

const useHasAccess = (compName, itemName) => {
  const accessControl = accessControl_json[compName];
  const { role_id } = useAuth(); // Get the role_id of the logged on user from auth context

  const itemAccess = accessControl.find(item => item.itemName === itemName);
  return itemAccess ? itemAccess.roles.includes(role_id) : false;
};

export default useHasAccess;

