import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';

const SearchableField = ({ apiUrl, placeholder, field, ...rest }) => {
  const [error, setError] = useState(null);

  const loadOptions = async (inputValue) => {
    try {
      const response = await fetch(`${apiUrl}?search=${inputValue}`);
      if (!response.ok) throw new Error(`HTTP status ${response.status}`);
      const data = await response.json();
      setError(null); // Clear any previous errors
      return data.map(item => ({
        value: item.id,
        label: item.name,
      }));
    } catch (error) {
      console.error('Failed to load options:', error);
      setError('Failed to load options'); // Set error message
      return [];
    }
  };

  return (
    <FormControl isInvalid={error}>
      <FormLabel htmlFor={field.name}>{placeholder}</FormLabel>
      <AsyncSelect loadOptions={loadOptions} placeholder={placeholder} {...rest} />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default SearchableField;
