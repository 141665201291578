// https://www.npmjs.com/package/react-media-recorder

import { useReactMediaRecorder } from "react-media-recorder";
import { Button, Box, Text } from "@chakra-ui/react";
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import apiUrlConfig_json from '../../config/apiUrl.json';

const AudioRecorder = ({ lang = 'en', mediaType = 'audio', timeLimit = 1, handleTranscribedText, 
    fileName = 'audio' }) => {

  const apiUrl = apiUrlConfig_json.SHR;
  const { status, startRecording, pauseRecording, resumeRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder({ mediaType: true });

  const [recordingStatus, setRecordingStatus] = useState('Record');
  const [mediaBlobUrlPrev, setMediaBlobUrlPrev] = useState();
  const [timeLimitUsed, setTimeLimitUsed] = useState(0);
  const recordingStartTime = useRef(null);
  const recordingInterval = useRef(null);
  
    const startMonitoringTime = () => {
    recordingInterval.current = setInterval(() => {
      const elapsed = (Date.now() - recordingStartTime.current) / 60000; // Convert ms to minutes
      if (timeLimitUsed + elapsed >= timeLimit) {
        clearInterval(recordingInterval.current);
        handleStopRecording();
        alert("Recording time limit reached.", elapsed);
      }
      setTimeLimitUsed(prev => prev + elapsed);      
      recordingStartTime.current = Date.now();
    }, 10000); // Check every 10 seconds
  };

  const handleRecording = async () => {
    if (recordingStatus === 'Record') {
      if (timeLimitUsed < timeLimit) {
        recordingStartTime.current = Date.now();
        startRecording();
        setRecordingStatus('Pause');
        startMonitoringTime();
      } else {
        alert("Recording time limit reached.", timeLimitUsed);
      }
    } else if (recordingStatus === 'Pause') {
      pauseRecording();
      setRecordingStatus('Resume');
      if (recordingStartTime.current) {
        const elapsed = (Date.now() - recordingStartTime.current) / 60000; // Convert ms to minutes
        setTimeLimitUsed(prev => prev + elapsed);
        recordingStartTime.current = null;
      }
      clearInterval(recordingInterval.current);
    } else if (recordingStatus === 'Resume') {
      if (timeLimitUsed < timeLimit) {
        recordingStartTime.current = Date.now(); // Adjust start time to account for already used time
        resumeRecording();
        setRecordingStatus('Pause');
        startMonitoringTime();
      } else {
        alert("Recording time limit reached.");
      }
    }
  };


  const handleStopRecording = async () => {
    stopRecording();
    setRecordingStatus('Record');    
    if (recordingStartTime.current) {
      const elapsed = (Date.now() - recordingStartTime.current) / 60000; // Convert ms to minutes
      setTimeLimitUsed(prev => prev + elapsed);
      recordingStartTime.current = null;
    }
    clearInterval(recordingInterval.current);
  };

  const transcribeAudio = async () => {
    const response = await fetch(mediaBlobUrl);
    const blob = await response.blob();
    const file = new File([blob], `${fileName}.${mediaType === 'video' ? 'mp4' : (mediaType === 'screen' ? 'webm' : 'm4a')}`, {
      type: blob.type,
    });
    const formData = new FormData();
      // formData.append('file', mediaBlob, 'audio.m4a');
      formData.append('file', file);      
      formData.append('lang', lang);
      try {
        const response = await axios.post(`${apiUrl}audio_transcribe/`, formData);
        await handleTranscribedText(response.data.transcription);
      } catch (error) {
        console.error("Error transcribing audio:", error);
      }
  }

  useEffect( () => {
    if (recordingStatus === 'Record' && mediaBlobUrl && (!mediaBlobUrlPrev || !(mediaBlobUrlPrev === mediaBlobUrl))) {    
        transcribeAudio();
        setMediaBlobUrlPrev(mediaBlobUrl);        
      }
  }, [recordingStatus, mediaBlobUrl]);

  
  return (
    <div>
      <Box mb={2} p={1} borderWidth="1px" borderRadius="md" display="flex" alignItems="center">
        <p>Status: {status} . . .</p>
        <Button mt={2} ml={0} colorScheme="green" type="button" onClick={handleRecording}>
            {recordingStatus}
        </Button>
        {!(recordingStatus==='Record') && 
            <Button mt={2} ml={0} colorScheme="red" type="button" onClick={handleStopRecording}>
              Done
            </Button>
        }
    </Box> 
    </div>
  );
};

export default AudioRecorder;

