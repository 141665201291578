import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import TextDisplay from './TextDisplay';
import apiUrlConfig_json from '../../config/apiUrl.json';

const TextGet = ({ currentTextFile, cmsType = 'teacher' }) => {
  const apiUrl = apiUrlConfig_json.CMS;
  
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  let gcsDownload = (cmsType=='teacher'? 'gcs_download':(cmsType=='learner'? 'gcs_learner_download':undefined));

  const pdfUrl = `${apiUrl}${gcsDownload}/${currentTextFile}/`;

  useEffect(() => {
    if (currentTextFile) {
      fetch(`${apiUrl}${gcsDownload}/${currentTextFile}/`) 
      .then(response => {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          return response.json().then(data => ({ ...data, content_type: contentType }));
        } else if (contentType && contentType.includes("text/plain")) {
          return response.text().then(text => ({ file_contents: text, content_type: contentType }));
        } else {
          return response.blob().then(blob => {
            return blob.arrayBuffer().then(buffer => {
              const base64String = btoa(
                new Uint8Array(buffer)
                  .reduce((data, byte) => data + String.fromCharCode(byte), '')
              );
              return { file_contents: base64String, content_type: contentType };
            });
          });
        }
      })
      .then(data => {
          setData(data);
        })
        .catch(error => setError(error.message));
    }
  }, [currentTextFile]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {data ? <TextDisplay data={data} pdfUrl={pdfUrl} /> : <div>Loading...</div>}
    </div>
  );
};

export default TextGet;
