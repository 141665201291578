import React, {useState} from 'react';
import { useRoutingNavigation } from '../../routing/useNavigation'; // Import useHistory hook from React Router
import C_Levels from './C_Levels'; // Display of first resource
import C_Levels_MR from './C_Levels_MR'; // Display of first resource
import CurriculumMatrix from './CurriculumMatrix'; // Display of first resource
import CurriculumContainers_old from './CurriculumContainers'; // Display of first resource
import CurriculumContainers from './C_Containers'; // Curriculum Containers fo given level
import CurriculumObjectives from './CurriculumObjectives'; // Display of first resource
import C_Data from './C_Data.js'; // Display of first resource


import A_Resources from '../assessment/A_Resources.js'; // Display of Assessment Resources
import A_Assessments from '../assessment/A_Assessments.js'; // Display of Assessment Resources
import FileUploadComponent from '../assessment/FileUploadComponent.js'; // Display of Assessment Resources

function Curriculum() {

    const { handleRouting } = useRoutingNavigation();

    const [currentView, setCurrentView] = useState('Curriculum');
    const handleView = (viewName) => {
      setCurrentView(viewName);
    };

    return (
      <div className="container">
        <h1>Curriculum Management</h1>

        {currentView === 'Curriculum' && (
        <>
        <button className="button" onClick={() => handleView('C_Levels')}>Curriculum Levels</button>
        <button className="button" onClick={() => handleView('C_Levels_MR')}>Curriculum Levels (Multi Row)</button>
        <button className="button" onClick={() => handleView('CurriculumMatrix')}>Curriculum Organization</button>        
        <button className="button" onClick={() => handleView('CurriculumContainers')}>Containers</button>
        <button className="button" onClick={() => handleView('CurriculumObjectives')}>Objectives</button>
        <button className="button" onClick={() => handleView('C_Data')}>Visual Layout</button>
        
        <button className="button" onClick={() => handleView('A_Resources')}>Assessment Resources</button>
        <button className="button" onClick={() => handleView('A_Assessments')}>Assessments</button>
        <button className="button" onClick={() => handleView('FileUploadComponent')}>Resource Upload</button>        

        {/* 
         <button className="button" onClick={() => handleView('A_ResourceUpload')}>Resource Upload</button>        
        */}
        <button className="button" onClick={handleRouting('/learning')}>Return to Learning Process</button>
        </>
        )}

        {currentView === 'C_Levels' && <C_Levels handleView={handleView} />}
        {currentView === 'C_Levels_MR' && <C_Levels_MR handleView={handleView} />}
        {currentView === 'CurriculumMatrix' && <CurriculumMatrix handleView={handleView} />}
        {currentView === 'CurriculumContainers' && <CurriculumContainers handleView={handleView} />}
        {currentView === 'C_Data' && <C_Data handleView={handleView} />}
        {currentView === 'CurriculumObjectives' && <CurriculumObjectives handleView={handleView} />}
        
        {currentView === 'A_Resources' && <A_Resources handleView={handleView} />}
        {currentView === 'A_Assessments' && <A_Assessments handleView={handleView} />}
        {currentView === 'FileUploadComponent' && <FileUploadComponent handleView={handleView} />}

        {/* 
        {currentView === 'A_ResourceUpload' && <A_ResourceUpload handleView={handleView} />}
        */}

      </div>
    );
  }

export default Curriculum;