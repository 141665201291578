import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer } from '@chakra-ui/react';

const JSON2Table = ({ data }) => {
  if (!data || data.length === 0) return null;

  const headers = Object.keys(data[0]);

  return (
    <TableContainer>
      <Table variant="simple" colorScheme="teal" size="sm" border="1px" borderColor="gray.200">
        <Thead bg="teal.500">
          <Tr>
            {headers.map((header, index) => (
              <Th key={index} color="white">{header}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody bg="teal.300">
          {data.map((item, index) => (
            <Tr key={index} border="1px" borderColor="gray.200">
              {headers.map((header, idx) => (
                <Td key={idx} border="1px" borderColor="gray.200">{item[header]}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default JSON2Table;
