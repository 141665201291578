import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Button } from "@chakra-ui/react";
import PDFViewer from './PDFViewer';

const JSON2PDF = ({ data, title, downloadPDF, showPDF }) => {
  const [pdfData, setPdfData] = useState('');
  const [doc, setDoc] = useState(null);

  const formatDataForPDF = (data) => {
    const headers = Object.keys(data[0]);
    const rows = data.map(item => headers.map(header => item[header]));
    return { headers, rows };
  };

  const generatePDF = () => {
    const { headers, rows } = formatDataForPDF(data);
    const doc = new jsPDF();

    // Add title or any header information if needed
    doc.text(title, 14, 16);
    doc.setFontSize(10);
    doc.text('Generated on ' + new Date().toLocaleString(), 14, 22);

    // Add table
    doc.autoTable({
      head: [headers],
      body: rows,
      startY: 30,
      theme: 'grid',
      headStyles: { fillColor: [22, 160, 133] }, // Customize as needed
      margin: { top: 30 }
    });

    const pdfOutput = doc.output('datauristring');
    setPdfData(pdfOutput);
    setDoc(doc);
  };

  useEffect(() => {
    generatePDF();
  }, [data, title]);

  const handleDownloadPDF = () => {
    if (doc) {
      doc.save('data.pdf');
    }
  };

  return (
    <>
      {downloadPDF && (
        <Button mt={2} ml={2} colorScheme="red" onClick={handleDownloadPDF}>Download as PDF</Button>
      )}
      {showPDF && pdfData && (
        <PDFViewer fileUrl={pdfData} allowDownload={true} allowPrint={true} />
      )}
    </>
  );
};

export default JSON2PDF;
