import React from 'react';

function CurriculumContainers({ handleView }) {

    return (
      <div className="container">
        <h2>Containers</h2>
        {/* Buttons for Asessment */}
        <button className="button" onClick={() => handleView('Curriculum')}>Return</button>        
      </div>
    );
  }

  export default CurriculumContainers;