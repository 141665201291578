// A_Resources.js
import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { Button, Box, Flex, useBreakpointValue, useToast } from '@chakra-ui/react';
import axios from 'axios';
import fieldSchema_json from '../../config/fieldSchema_ass.json';
import apiUrlConfig_json from '../../config/apiUrl.json';
import useFetchRecords from '../../techComponents/useFetchRecords';
import NavigationControls from '../../techComponents/NavigationControls';
import FieldsRendering from '../../techComponents/FieldsRendering';
import useFormSubmit from '../../techComponents/useFormSubmit';
import SearchRecords from '../../techComponents/SearchRecords';
import useDeleteRecordMR from '../../techComponents/useDeleteRecordMR';
import useHasAccess from '../../techComponents/hasAccess';
import { useAuth } from '../../context/AuthContext'; // Adjust the path as necessary

const A_Resources = () => {
  const resourceFieldSchema = fieldSchema_json.A_Resources;
  const resourceLinesFieldSchema = fieldSchema_json.A_Resource_Lines;
  const apiUrl = apiUrlConfig_json.A_Resources;
  const apiUrlLines = apiUrlConfig_json.A_Resource_Lines;
  const hasAccess = useHasAccess;
  const { user_id } = useAuth(); // Get the user_id of the logged on user from auth context  
  const toast = useToast();
  
  const flexDirection = useBreakpointValue({ base: 'column', md: 'row' });

  const initialFormValues = resourceFieldSchema.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {});

  const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: { ...initialFormValues, resource_lines: [] },
  });
  
  const [deletedChildRecords, setDeletedChildRecords] = useState([]);

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'resource_lines',
    keyName: 'formId', // Use a different key name to avoid conflict
  });

  const { records, fetchRecords, searchParam, setSearchParam, handleSearch, currentIndex, setCurrentIndex, error } = useFetchRecords(apiUrl);
  const { onSubmit: onSubmitParent } = useFormSubmit(apiUrl, fetchRecords, reset);
  const { onSubmit: onSubmitChild } = useFormSubmit(apiUrlLines, fetchRecords, reset);
  const { handleDelete } = useDeleteRecordMR(apiUrl, fetchRecords, reset, initialFormValues, currentIndex, setCurrentIndex, records);

  useEffect(() => {
    if (currentIndex !== -1 && currentIndex < records.length) {
      const currentRecord = records[currentIndex];
      resourceFieldSchema.forEach(field => setValue(field.name, currentRecord[field.name]));
  
      // Fetch child records
      if (currentRecord.id) {
        axios.get(`${apiUrlLines}?res=${currentRecord.id}`)
          .then(response => {
            const resourceLines = response.data;
            replace(resourceLines); // Initialize child records in the form
          })
          .catch(error => {
            console.error('Error fetching child records:', error);
          });
      } else {
        replace([]); // Clear child records if no parent ID
      }
    } else {
      reset({ ...initialFormValues, resource_lines: [] }); // Reset form if no valid record
    }
  }, [records, currentIndex, setValue, resourceFieldSchema, apiUrlLines, replace, reset]);
  
  const handleParentSubmit = async (data) => {
    try {
      let parentResponse;
      if (data.id) {
        // Update existing record
        data.updated_by = user_id;
        data.updated_on = new Date().toISOString(); // Captures client-side date in ISO format
        parentResponse = await axios.put(`${apiUrl}${data.id}/`, data);
      } else {
        // Create new record
        data.created_by = user_id;
        data.created_on = new Date().toISOString(); // Captures client-side date in ISO format
        parentResponse = await axios.post(apiUrl, data);
      }

      const parentId = parentResponse?.data?.id;
      if (parentId) {
        // Process child records
        const childPromises = data.resource_lines.map((child) => {
          if (child.id) {
            child.updated_by = user_id;
            child.updated_on = new Date().toISOString(); // Captures client-side date in ISO format
            return axios.put(`${apiUrlLines}${child.id}/`, { ...child, res: parentId });
          }
          child.created_by = user_id;
          child.created_on = new Date().toISOString(); // Captures client-side date in ISO format
          return axios.post(apiUrlLines, { ...child, res: parentId });
        });

        // Process deleted child records
        const deletePromises = deletedChildRecords.map((id) => {
          return axios.delete(`${apiUrlLines}${id}/`);
        });

        await Promise.all(childPromises);
      }

      fetchRecords();
      reset({ ...initialFormValues, resource_lines: [] });
      setDeletedChildRecords([]); // Clear deleted records      
      toast({title: "Success", description: "Entries processed successfully", status: "success", duration: 5000, isClosable: true,});

    } catch (error) {
      console.error('Error saving parent or child data:', error);
      toast({title: "Error", description: "Failed to create entries", status: "error", duration: 5000, isClosable: true,});

    }
  };

  return (
    <div className="container">
      <h2>Manage Resources</h2>
      <SearchRecords searchParam={searchParam} setSearchParam={setSearchParam} handleSearch={handleSearch} /> 

      {error && <div style={{ color: 'red' }}>{error}</div>}

      <form onSubmit={handleSubmit(handleParentSubmit)}>
        <FieldsRendering control={control} errors={errors} fieldSchema={resourceFieldSchema} />

        <Box mt={4}>
          <h3>Resource Lines</h3>
          {fields.map((item, index) => (
            <Flex key={item.formId} mb={2} alignItems="center"> {/* Use formId instead of deult name id, because we changed it useFieldArray */}
              <FieldsRendering
                control={control}
                errors={errors}
                fieldSchema={resourceLinesFieldSchema.map(field => ({
                  ...field,
                  name: `resource_lines[${index}].${field.name}` // Ensure unique key by using brackets
                }))}
                showLabels={index === 0} // Show labels only for the first item
              />
{/*              <Button mt={0} ml={1} colorScheme="red" onClick={() => remove(index)}>X</Button> */}
              <Button mt={0} ml={1} colorScheme="red" onClick={() => {
                  if (item.id) {setDeletedChildRecords([...deletedChildRecords, item.id]); }
                  remove(index);
                  }}>X
                </Button>
            </Flex>
          ))}
          <Button mt={2} onClick={() => append({ line_number: '', res_class: '', cms_res_id: '' })}>Add Resource Line</Button>
        </Box>

        <NavigationControls
          currentIndex={currentIndex}
          recordsLength={records.length}
          setCurrentIndex={setCurrentIndex}
          reset={reset}
          initialFormValues={initialFormValues}
          showSubmit={(records[currentIndex]?.status !== 'S') || hasAccess('A_Resources', 'submitButton')}                    
        />
        {hasAccess('A_Resources', 'deleteButton') && (
          <Button mt={2} ml={0} colorScheme="red" onClick={handleDelete}>Delete</Button>
        )}
      </form>
    </div>
  );
};

export default A_Resources;
