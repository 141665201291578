import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import ChartSimple from './ChartSimple'; // Ensure you have the correct path to ChartSimple.js

const ChartOptions = ({ data }) => {
  const [selectedDimensions, setSelectedDimensions] = useState([]);
  const [selectedMeasures, setSelectedMeasures] = useState([]);
  const [chartType, setChartType] = useState('bar');
  const [showChart, setShowChart] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const dimensionOptions = data.length > 0 ? Object.keys(data[0]) : [];
  const measureOptions = data.length > 0 ? Object.keys(data[0]) : [];

  const handleDimensionChange = (dimension) => {
    setSelectedDimensions([dimension]); // Only allow one dimension
  };

  const handleMeasureChange = (measure) => {
    setSelectedMeasures(prev =>
      prev.includes(measure)
        ? prev.filter(m => m !== measure)
        : [...prev, measure]
    );
  };

  const handleChartTypeChange = (e) => {
    setChartType(e.target.value);
  };

  const handleShowChart = () => {
    setShowChart(true);
  };

  const handleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleColorPickerToggle = () => {
    setShowColorPicker(!showColorPicker);
  };

  const filteredDimensionOptions = dimensionOptions.filter(option => !selectedMeasures.includes(option));
  const filteredMeasureOptions = measureOptions.filter(option => !selectedDimensions.includes(option));

  return (
    <div>
      <Button onClick={handleShowOptions}>{showOptions ? 'Hide Options' : 'Draw Chart'}</Button>
      {showOptions && (
        <>
          <div>
            <h3>Select Dimensions</h3>
            <Stack>
              {filteredDimensionOptions.map(dimension => (
                <Checkbox
                  key={dimension}
                  onChange={() => handleDimensionChange(dimension)}
                >
                  {dimension}
                </Checkbox>
              ))}
            </Stack>
          </div>
          <div>
            <h3>Select Measures</h3>
            <Stack>
              {filteredMeasureOptions.map(measure => (
                <Checkbox
                  key={measure}
                  onChange={() => handleMeasureChange(measure)}
                >
                  {measure}
                </Checkbox>
              ))}
            </Stack>
          </div>
          <div>
            <h3>Select Chart Type</h3>
            <RadioGroup onChange={handleChartTypeChange} value={chartType}>
              <Stack direction="row">
              <Radio value="line">Line</Radio>
                <Radio value="bar">Bar</Radio>
                <Radio value="pie">Pie</Radio>
              </Stack>
            </RadioGroup>
          </div>
        </>
      )}
      <Button onClick={handleShowChart}>Render</Button>
      {showChart && selectedDimensions.length > 0 && selectedMeasures.length > 0 && (
        <div>
          <Button onClick={handleColorPickerToggle}>{showColorPicker ? 'Hide Color Picker' : 'Show Color Picker'}</Button>
          <ChartSimple
            data={data}
            xAxisKey={selectedDimensions[0]} // Only one dimension
            yAxisKeys={selectedMeasures}
            chartType="bar"
            showColorPicker={showColorPicker}
          />
        </div>
      )}
    </div>
  );
};

ChartOptions.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ChartOptions;
