import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styleSheets/quizStyles.css'; // Adjust the path according to your project structure
import { useAuth } from '../../context/AuthContext'; // Adjust the path as necessary
import apiUrlConfig_json from '../../config/apiUrl.json';
import TextGet from '../shared/TextGet'; // Adjust the path as necessary
import TaskReview from './TaskReview'; // Adjust the path as necessary
import { Button, Box, Checkbox, FormLabel } from "@chakra-ui/react";

function IPlanResourcesReview() {
    const apiUrl1 = apiUrlConfig_json.A_Participations;
    const apiUrl2 = apiUrlConfig_json.A_Assessments;
    const apiUrl3 = apiUrlConfig_json.A_Resource_Lines;

    const { user_id } = useAuth(); // Get the user_id from auth context
    
    const [participations, setParticipations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentParticipation, setCurrentParticipation] = useState(null);
    const [resourceLines, setResourceLines] = useState([]);
    const [tasksSubmitted, setTasksSubmitted] = useState(0);
    const [isAssessmentSubmitted, setIsAssessmentSubmitted] = useState(true);
    const [showText, setShowText] = useState(false);

    const fetchParticipations = async () => {
        try {
            const participationResponse = await axios.get(`${apiUrl1}?learner_id=${user_id}&status=S`);
            const participationData = participationResponse.data;

            const updatedParticipations = await Promise.all(participationData.map(async (participation) => {
                const assessmentResponse = await axios.get(`${apiUrl2}${participation.assessment_id}/`);
                const assessmentData = assessmentResponse.data;
                return { ...participation, assessment: assessmentData };
            }));
            console.log(updatedParticipations);

            setParticipations(updatedParticipations);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const fetchResourceData = async (participationId, assessmentId) => {
        try {
            const assessmentResponse = await axios.get(`${apiUrl2}${assessmentId}/`);
            const assessmentData = assessmentResponse.data;
            const resourceId = assessmentData.resource_id;

            const resourceResponse = await axios.get(`${apiUrl3}?res=${resourceId}&status=A`);
            const resourceData = resourceResponse.data;
            const resource = resourceData;

            setResourceLines(resource);

        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchParticipations();
    }, [user_id]);

    const handleAssessmentClick = async (participation) => {
        setCurrentParticipation(participation);
        fetchResourceData(participation.id, participation.assessment_id);
    };

    const handleClose = () => {
        setCurrentParticipation(null);
        setIsAssessmentSubmitted(true);
        setTasksSubmitted(0);
        setLoading(true);
        fetchParticipations();
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Box display="flex" alignItems="center" mb={2}>
                <FormLabel htmlFor="showText" width="150px" style={{ marginRight: '10px' }}>Show Text</FormLabel>                    
                <Checkbox
                    id="showText"
                    isChecked={showText}
                    onChange={(e) => setShowText(e.target.checked)}
                />
            </Box>

            {!currentParticipation && participations.map((participation) => (
                <div key={participation.id} className="assessment-container">
                    <h3>{participation.assessment.name} ( {participation.id} )</h3>
                    <Button
                        onClick={() => handleAssessmentClick(participation)}
                        colorScheme="blue"
                    >
                        Review Assessment
                    </Button>
                </div>
            ))}
            {currentParticipation && isAssessmentSubmitted && (
                <div>
                    {resourceLines.map((resourceLine, index) => (
                        <div key={index}>
                            {resourceLine.res_class === "TEXT" ? (
                                <div className="text-container">
                                    {/* <TextGet currentTextFile={resourceLine.cms_res_id} /> */}
                                    {showText && <TextGet currentTextFile={resourceLine.cms_res_id} /> }                                    
                                </div>
                            ) : (
                                <TaskReview
                                    participationId={currentParticipation.id}
                                    user_id={user_id}
                                    currentTaskFile={resourceLine.cms_res_id}
                                    res_line_number={resourceLine.line_number}
                                    res_line_id={resourceLine.id}
                                />
                            )}
                        </div>
                    ))}

                </div>
            )}
            {isAssessmentSubmitted && (
                <Button onClick={handleClose} colorScheme="teal" mt={4}>
                    Close
                </Button>
            )}
        </>
    );
}

export default IPlanResourcesReview;
