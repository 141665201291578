import React, { useState, useEffect } from 'react';
import { Box, Button } from '@chakra-ui/react';
import axios from 'axios';
import apiUrlConfig_json from '../../config/apiUrl.json';
import ThreadSelector from '../Chat/ThreadSelector';
import ThreadChat from '../Chat/ThreadChat';

const ThreadManager = () => {

  const apiUrl = apiUrlConfig_json.AAPI;

  const [assistants, setAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState('');
  const [threads, setThreads] = useState([]);
  const [selectedThread,setSelectedThread] = useState('')
  const [threadName, setThreadName] = useState('');

  useEffect(() => {
    fetchAssistants();
  }, []);

  useEffect(() => {
    if (selectedAssistant) {
      fetchThreads(selectedAssistant);
    }
  }, [selectedAssistant]);

  const fetchAssistants = async () => {
    const response = await axios.get(`${apiUrl}assistants/`);
    setAssistants(response.data.available_assistants);
  };

  const fetchThreads = async (assistantId) => {
    const response = await axios.get(`${apiUrl}assistants/${assistantId}/threads/`);
    setThreads(response.data.threads);
  };

  const handleThreadSelect = (threadId) => {

    setSelectedThread(threadId)
    console.log(selectedThread)
    // You can add additional logic here if needed, such as:
    // - Fetching messages for the selected thread
    // - Updating the UI to display the selected thread's content
    // - Triggering any other necessary actions based on thread selection
  };
  
  const handleCreateThread = async () => {
    if (!selectedAssistant || !threadName) return;

    try {
      const response = await axios.post(`${apiUrl}assistants/${selectedAssistant}/threads/create/`, {
        name: threadName
      });
      console.log('Thread created:', response.data);
      setThreadName('');
      fetchThreads(selectedAssistant);
    } catch (error) {
      console.error('Error creating thread:', error);
    }
  };

  return (
    <>
    <div className="thread-manager">
      <h2>Thread Manager</h2>
      <select 
        value={selectedAssistant} 
        onChange={(e) => setSelectedAssistant(e.target.value)}
      >
        <option value="">Select an Assistant</option>
        {assistants.map((assistant) => (
          <option key={assistant.fields.assistant_id} value={assistant.fields.assistant_id}>
            {assistant.fields.name}
          </option>
        ))}
      </select>

      <div className="create-thread">

        <input 
          type="text" 
          value={threadName} 
          onChange={(e) => setThreadName(e.target.value)} 
          placeholder="Enter thread name"
        />
        <Button onClick={handleCreateThread} disabled={!selectedAssistant || !threadName}>
          Create Thread
        </Button>
</div>

      <div className="thread-list">
        <h3>Available Threads</h3>
        {threads.length > 0 ? (
          <ul>
            {threads.map((thread) => (
              <li key={thread.thread_id}>
                {thread.name} - Created at: {new Date(thread.created_at).toLocaleString()}
              </li>
            ))}
          </ul>
        ) : (
          <p>No threads available for this assistant.</p>
        )}
      </div>

</div>

    <ThreadSelector assistantId={selectedAssistant} threads={threads} onThreadSelect={handleThreadSelect} />
    <ThreadChat threadId={selectedThread} assistant_id={selectedAssistant}/>
    </>
  );
};

export default ThreadManager;
