import React from 'react';
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const Unauthorized = () => {
  const navigate = useNavigate();

  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading as="h1" size="2xl" mb={4}>
        Unauthorized
      </Heading>
      <Text fontSize="lg" color="gray.500" mb={6}>
        You do not have permission to view this page.
      </Text>
      <Button colorScheme="teal" onClick={() => navigate('/')}>
        Go to Home
      </Button>
    </Box>
  );
};

export default Unauthorized;
