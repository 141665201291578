import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import fieldSchema_json from '../../config/fieldSchema.json';
import apiUrlConfig_json from '../../config/apiUrl.json';

import useFetchRecords from '../../techComponents/useFetchRecords';
import NavigationControls from '../../techComponents/NavigationControls';
import FieldsRendering from '../../techComponents/FieldsRendering';
import useFormSubmit from '../../techComponents/useFormSubmit';
import SearchRecords from '../../techComponents/SearchRecords';
import useDeleteRecordMR from '../../techComponents/useDeleteRecordMR';
import useHasAccess from '../../techComponents/hasAccess';

const A_Assessments = () => {
  const fieldSchema = fieldSchema_json.A_Assessments;
  const apiUrl = apiUrlConfig_json.A_Assessments;
  const hasAccess = useHasAccess;
  
  // Generate initial form values
  // const initialFormValues = fieldSchema.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {});
  const initialFormValues = fieldSchema.reduce((acc, field) => {
    acc[field.name] = field.defaultValue? field.defaultValue : (field.type == "number"? 1 : '');
    return acc;
  }, {});

  const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: initialFormValues
  });

  const { records, fetchRecords, searchParam, setSearchParam, handleSearch, currentIndex, setCurrentIndex, error } = useFetchRecords(apiUrl);
  const { onSubmit } = useFormSubmit(apiUrl, fetchRecords, reset);
  const { handleDelete } = useDeleteRecordMR(apiUrl, fetchRecords, reset, initialFormValues, currentIndex, setCurrentIndex, records);

  useEffect(() => {
    if (currentIndex !== -1 && currentIndex < records.length) {
      fieldSchema.forEach(field => setValue(field.name, records[currentIndex][field.name]));
    }
  }, [records, currentIndex, setValue, fieldSchema]);

  return (
    <div className="container">
      <h2>Manage Assessments</h2>
      <SearchRecords searchParam={searchParam} setSearchParam={setSearchParam} handleSearch={handleSearch} />
      {error && <div style={{ color: 'red' }}>{error}</div>}

      <form onSubmit={handleSubmit(data => onSubmit(data, currentIndex, records))}>
        <FieldsRendering control={control} errors={errors} fieldSchema={fieldSchema} />
        <NavigationControls
          currentIndex={currentIndex}
          recordsLength={records.length}
          setCurrentIndex={setCurrentIndex}
          reset={reset}
          initialFormValues={initialFormValues}
          showSubmit={(records[currentIndex]?.status !== 'S') || hasAccess('A_Assessments', 'submitButton')}          
        />
        {hasAccess('A_Assessments', 'deleteButton') && (
          <Button mt={2} ml={0} colorScheme="red" onClick={handleDelete}>Delete</Button> 
        )}
      </form>
    </div>
  );
};

export default A_Assessments;