import React, { useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import PDFViewer from '../../techComponents/PDFViewer';
import TextListen from './TextListen';

const TextDisplay = ({ data, pdfUrl, allowDownload = false}) => {
//  const { file_contents, original_name, content_type } = data;
  const { file_contents, content_type } = data;
  const { original_name } = 'abc';
  
// 240703, commented out as we are using a different method to determine text direction
  // const isRTL = text => {
  //   const rtlChars = /[\u0600-\u06FF\u0750-\u077F]/; // Unicode ranges for Arabic and related scripts
  //   return rtlChars.test(text);
  // };

  const renderContent = () => {
    if (!file_contents) {
      return <div>No content available</div>;
    }

    switch (content_type) {
      case 'text/plain':
//      case 'application/json':

        const containsEnglish = text => {
          const englishChars = /[a-zA-Z]/; // English letters
          return englishChars.test(text);
        };

        const getTextDirection = text => {
          const lines = text.split('\n');
          const firstLine = lines[0].trim();

          if (firstLine.toLowerCase() === 'urdu text') {
            return 'rtl';
          } else if (firstLine.toLowerCase() === 'english text') {
            return 'ltr';
          } else if (containsEnglish(text)) {
            return 'ltr';
          } else {
            return 'rtl';
          }
        };
        const direction = getTextDirection(file_contents);
        const isRightToLeft = direction === 'rtl';
        // const isRightToLeft = isRTL(file_contents);

        return (
          <>
          <pre className="text-data" dir={direction} style={{ textAlign: isRightToLeft ? "right" : "left", fontFamily: isRightToLeft ? "'Jameel Noori Nastaleeq', serif" : "Arial, sans-serif" }}>
              <h3 style={{ color: "white", fontSize: "20px" }}>Text</h3>
              {file_contents}
            </pre>
            {!isRightToLeft && <TextListen textData={file_contents} />}
            {/* {!isRightToLeft && <TextListen textData={file_contents} lang="en-US" />}
              {isRightToLeft && <TextListen textData={file_contents} lang="ur-PK" />} */}
          </>
        );
        
      case 'image/jpeg':
      case 'image/gif':  
      case 'image/png':
        return (
          <img 
          src={`data:${content_type};base64,${file_contents}`} 
          alt={original_name} 
          onContextMenu={allowDownload ? undefined : (e) => e.preventDefault()}
          // style={!allowDownload ? { userSelect: 'none', pointerEvents: 'none' } : undefined}
          />
        );

      case 'video/mp4':
      case 'video/webm':        
        return (
          <ReactPlayer 
            url={`data:${content_type};base64,${file_contents}`} 
            controls 
            config={!allowDownload ? 
              { file: { attributes: { controlsList: 'nodownload', onContextMenu: (e) => e.preventDefault() } } } : 
              undefined
            }
          />

          // <ReactPlayer url={`data:${content_type};base64,${file_contents}`} controls config={{ file: { attributes: { controlsList: 'nodownload', onContextMenu: (e) => e.preventDefault() } } }}/>
        // <video src={`data:${content_type};base64,${file_contents}`} controls controlsList="nodownload"/>
          // <video controls>
          //   <source src={`data:${content_type};base64,${file_contents}`} type={content_type} />
          //   Your browser does not support the video tag.
          // </video>
        );

      case 'audio/mpeg':
        return (
          <ReactPlayer 
            url={`data:${content_type};base64,${file_contents}`} 
            controls 
            config={!allowDownload ? 
              { file: { attributes: { controlsList: 'nodownload', onContextMenu: (e) => e.preventDefault() } } } : 
              undefined
            }
            height="50px"  // Set a small height for audio players
            // width="50%"   // Optional: Set width to 100% to fit the container            
          />

          // <audio controls controlsList="nodownload">
          //   <source src={`data:${content_type};base64,${file_contents}`} type={content_type} />
          //   Your browser does not support the audio element.
          // </audio>
        );
      case 'application/pdf':
        const pdfData = `data:${content_type};base64,${file_contents}`;
        return <PDFViewer fileUrl={pdfData} allowDownload={allowDownload} allowPrint={false} />;
        
//         return (
//             <iframe
//                src={pdfData}
//                 src={googleDriveViewerUrl}
//                 width="100%"
//                height="100%"
//                 height="500px"
//                 style={{ border: 'none' }}
//                 title="PDF Viewer"
//             />
//           );


      default:
        return <div>Unsupported file type</div>;
    }
  };

  return <div>{renderContent()}</div>;
};

export default TextDisplay;
