import React from 'react';
import { RadioGroup as ChakraRadioGroup, Radio, FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';

const RadioGroup = ({ field, value, onChange, error, ...rest }) => {
  return (
    <FormControl isInvalid={error}>
      <FormLabel htmlFor={field.name}>{field.label}</FormLabel>
      <ChakraRadioGroup onChange={onChange} value={value} {...rest}>
        {field.options.map(option => (
          <Radio key={option.value} value={option.value}>
            {option.label}
          </Radio>
        ))}
      </ChakraRadioGroup>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default RadioGroup;
