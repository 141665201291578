import React, { useState, useEffect } from 'react';
import { VStack, Box, Button, Text, Heading } from '@chakra-ui/react';
import { useRoutingNavigation} from '../../routing/useNavigation'; // Import useHistory hook from React Router

import IPlanResources from '../instruction/IPlanResources.js';
import IPlanResourcesReview from '../review/IPlanResourcesReview.js';
import L_CreateTask from '../learner/L_CreateTask.js';
import L_ChangePwd from '../learner/L_ChangePwd';


function Assessment() {

    const { handleRouting } = useRoutingNavigation();
    const [currentView, setCurrentView] = useState('Assessment');
    const handleView = (viewName) => { 
      setCurrentView(viewName); 
    };

    return (
      <div className="container" >
        <Button mt={2} mb={6} ml={0} colorScheme="green" width="max(75vw, 400px)" onClick={() => handleView('Assessment')}>Return to Home</Button>
        <Heading fontSize="xl" fontWeight="bold" color="black.500" textAlign="left" >
          Practice and Assessment Tasks
        </Heading>

        {currentView === 'Assessment' && (
            <>
              <VStack spacing={2} align="stretch">                      
                <Button mt={2} ml={0} colorScheme="purple" width="max(75vw, 400px)" onClick={() => handleView('IPlanResources')}>Practice Tasks & Assessments</Button>
                <Button mt={2} ml={0} colorScheme="teal" width="max(75vw, 400px)" onClick={() => handleView('L_CreateTask')}>Self Learning and Practice</Button>            
                <Button mt={2} ml={0} colorScheme="yellow" width="max(75vw, 400px)" onClick={() => handleView('IPlanResourcesReview')}>Review completed Practice & Assessments</Button>            
                <Button mt={2} ml={0} colorScheme="red" width="max(75vw, 400px)" onClick={() => handleView('L_ChangePwd')}>Change Password</Button>            
              </VStack>
            </>
        )}

        {currentView === 'IPlanResources' && <IPlanResources handleView={handleView} />}
        {currentView === 'L_CreateTask' && <L_CreateTask handleView={handleView} />}
        {currentView === 'IPlanResourcesReview' && <IPlanResourcesReview handleView={handleView} />}
        {currentView === 'L_ChangePwd' && <L_ChangePwd handleView={handleView} />}

        {/* <button className="button" onClick={handleRouting('/learning/Assessment')}>Return to Main Page</button> */}
      </div>
    );
  }

  export default Assessment;