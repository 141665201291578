import React, { useState, useEffect } from 'react';
import { Button } from '@chakra-ui/react';
import axios from 'axios';
import apiUrlConfig_json from '../../config/apiUrl.json';

// import "./ModifyAssistant.css"

const ModifyAssistant = () => {

  const apiUrl = apiUrlConfig_json.AAPI;

  const [assistants, setAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    fetchAssistants();
  }, []);

  const fetchAssistants = async () => {
    const response = await axios.get(`${apiUrl}assistants/`);
    setAssistants(response.data.available_assistants);
    console.log(response.data)
  };
  const handleAssistantSelect = (event) => {
    const selected = assistants.find(a => a.fields.assistant_id === event.target.value)
    setSelectedAssistant(selected)
    setFormData({
      name: selected.fields.name,
      description: selected.fields.description,
      instructions: selected.fields.instructions,
      model: selected.fields.model
    })
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormData(prevData => ({ ...prevData, [name]: value }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const response = await axios.put(`${apiUrl}assistants/${selectedAssistant.fields.assistant_id}/modify/`, formData)
    alert('Assistant updated successfully')
    console.log(response.data)
    fetchAssistants()
  }

  return (
    <div className="assistant-update">
      <h2>Update Assistant</h2>
      <select onChange={handleAssistantSelect} value={selectedAssistant?.fields.assistant_id || ''}>
        <option value="">Select an assistant</option>
        {assistants.map(assistant => (
          <option key={assistant.fields.assistant_id} value={assistant.fields.assistant_id}>
            {assistant.fields.name}
          </option>
        ))}
      </select>

      {selectedAssistant && (
        <form onSubmit={handleSubmit}>
          <div>
            <label>Name:</label>
            <input type="text" name="name" value={formData.name || ''} onChange={handleInputChange} />
          </div>
          <div>
            <label>Description:</label>
            <textarea name="description" value={formData.description || ''} onChange={handleInputChange} />
          </div>
          <div>
            <label>Instructions:</label>
            <textarea name="instructions" value={formData.instructions || ''} onChange={handleInputChange} />
          </div>
          <div>
            <label>Model:</label>
            <select
          value={formData.model || "gpt-4o-mini"}
          onChange={handleInputChange}
        >
          <option value="gpt-4-1106-preview" disabled>GPT-4 Turbo</option>
          <option value="gpt-3.5-turbo" disabled>GPT-3.5 Turbo</option>
          <option value="gpt-4o-mini" >GPT-4o Mini</option>
        </select>
          </div>
          <Button type="submit">Update Assistant</Button>
        </form>
      )}
    </div>
  )
}

export default ModifyAssistant
