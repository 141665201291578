import React, { useState } from 'react';
import { Editor, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';

const TaskCRQ_D = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleChange = (state) => {
    setEditorState(state);
  };

  return (
    <div>
      <Editor editorState={editorState} onChange={handleChange} />
    </div>
  );
};

export default TaskCRQ_D;
