import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Button, Heading, Box } from '@chakra-ui/react';

import FieldsRendering from '../../techComponents/FieldsRendering';
import JSON2Table from '../../techComponents/JSON2Table';
import JSON2CSV from '../../techComponents/JSON2CSV';
import JSON2PDF from '../../techComponents/JSON2PDF2';

import fieldSchema_json from '../../config/fieldSchema_lnr.json';
import apiUrlConfig_json from '../../config/apiUrl.json';

const EvaluationReview = () => {
  const fieldSchema = fieldSchema_json.EvaluationReview;
  const apiUrl = apiUrlConfig_json.L_EvaluationReview;

  // Extracting default values from fieldSchema
  const defaultValues = fieldSchema.reduce((acc, field) => {
    if (field.defaultValue !== undefined) {
      acc[field.name] = field.defaultValue;
    }
    return acc;
  }, {});

  // Initialize form hooks with default values
  const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
      defaultValues
  });



  const [message, setMessage] = useState('');
  const [summary, setSummary] = useState([]);
  const [summary2, setSummary2] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async (data) => {

    // Construct filters with only non-empty values
    const filters = Object.entries(data).reduce((acc, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    }, {});

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(apiUrl, { filters }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setMessage(response.data.message);      
      setSummary2(response.data);

    // Group data into dimensions, detailed_data, and measures
    const groupedData = response.data.map((assessment) => {
      const { grade, subject, assessment_id, campus, learner_grade, section, learner_id, ...detailedData } = assessment;
      const detailedDataArray = Array.isArray(detailedData) ? detailedData : [detailedData];

      return {
        dimensions: {
          grade,
          subject,
          assessment_id,
          campus,
          learner_grade,
          section,
          learner_id,
        },
        detailed_data: detailedDataArray,
        aggregated_data: {
          total_obt_marks: detailedDataArray.reduce((sum, item) => sum + (item.obt_marks || 0), 0),
        }
      };
    });

      setSummary(groupedData);
    } catch (error) {
      console.error('Error fetching data', error);
      setError('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div>
        <Heading as="h1" color="purple.500" size="lg">Review Evaluation</Heading>
        <FieldsRendering control={control} errors={errors} fieldSchema={fieldSchema} />
        <Box mt={2}>
            <Button colorScheme="blue" width="max(25vw, 200px)" onClick={handleSubmit(onSubmit)} isDisabled={loading}>Run Evaluation Report</Button> 
        </Box>
        </div>

      {loading && <p>Loading...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {message && <p>{message}</p>}
      
      {summary.length > 0 && <JSON2Table data={summary.dimensions} />}
      {summary.length > 0 && <JSON2CSV data={summary2} />}
      {summary.length > 0 && <JSON2PDF data={summary} title={'Task Performance'} downloadPDF={true} showPDF={false} />}
    </div>
  );
};

export default EvaluationReview;