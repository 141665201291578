import React, { useState, useEffect } from 'react';
import { Box, Button, FormLabel, Input } from '@chakra-ui/react';
import axios from 'axios';
import apiUrlConfig_json from '../../config/apiUrl.json';
import "./CreateAssistant.css"

const CreateAssistant = () => {

  const apiUrl = apiUrlConfig_json.AAPI;

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [instructions, setInstructions] = useState('');
  const [model, setModel] = useState('gpt-4o-mini');
  const [tools, setTools] = useState([]);
  const [toolResources, setToolResources] = useState({});
  const [vectorStores, setVectorStores] = useState([]);

  useEffect(() => {
    fetchVectorStores();
  }, []);

  const fetchVectorStores = async () => {
    const response = await axios.get(`${apiUrl}vector-stores/`);
    setVectorStores(response.data.vector_stores);
    console.log(vectorStores)
  };

const handleVectorStoreChange = (toolType, selectedOptions) => {
    const selectedIds = Array.from(selectedOptions, option => option.value);
    setToolResources(prevResources => ({
      ...prevResources,
      [toolType]: {
        ...prevResources[toolType],
        vector_store_ids: selectedIds
      }
    }));
  };
  
useEffect(() => {
    console.log('Updated toolResources:', toolResources);
  }, [toolResources]);

const handleToolChange = (e) => {
  const selectedTools = Array.from(e.target.selectedOptions, option => ({ 'type': option.value }));
  setTools(selectedTools);
  console.log(toolResources)

  setToolResources(prevResources => {
    const newResources = {};
    selectedTools.forEach(tool => {
      if (prevResources[tool.type]) {
        newResources[tool.type] = prevResources[tool.type];
      } else {
        newResources[tool.type] = { "vector_store_ids": [] };
      }
    });
    return newResources;
  });
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}assistants/create/`, {
        name,
        description,
        instructions,
        model,
        tools: tools,
        tool_resources: toolResources
      });
      console.log('Assistant created:', response.data);
      // Reset form or handle success
    } catch (error) {
      console.error('Error creating assistant:', error);
      // Handle error
    }
  };

  return (
    <div className="create-assistant">
      <h2>Create New Assistant</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Assistant Name"
          required
        />
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Description"
        />
        <textarea
          value={instructions}
          onChange={(e) => setInstructions(e.target.value)}
          placeholder="Instructions"
        />
        <select
          value={model}
          onChange={(e) => setModel(e.target.value)}
        >
          <option value="gpt-4o-mini">GPT-4o Mini</option>
          <option value="gpt-4o-2024-08-06">GPT-4o</option>
          <option value="gpt-4-1106-preview">GPT-4 Turbo</option>
          <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
        </select>

        <select multiple value={tools.map(tool => tool.type)} onChange={handleToolChange} >
          <option value="file_search">File Search</option>
          <option value="code_interpreter">Code Interpreter</option>
          <option value="function">Function</option>
        </select>

        {tools.map((tool, index) => (
          <div key={index} className='tool-select'>
            <label>{tool.type}</label>
            <select
              className='vector-store-select'
              multiple
              value={toolResources[tool.type]?.vector_store_ids || []}
              onChange={(e) => handleVectorStoreChange(tool.type, e.target.selectedOptions)}
            >
              {vectorStores.map(store => (
                <option key={store.id} value={store.vector_store_id}>{store.name}</option>
              ))}
            </select>
          </div>
        ))}

        <button type="submit">Create Assistant</button>
      </form>
    </div>
  );
};

export default CreateAssistant;
