import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styleSheets/quizStyles.css'; // Adjust the path according to your project structure
import { useAuth } from '../../context/AuthContext'; // Adjust the path as necessary
import apiUrlConfig_json from '../../config/apiUrl.json';
import TextGet from '../shared/TextGet'; // Adjust the path as necessary
import TaskReviewTeacher from './TaskReviewTeacher'; // Adjust the path as necessary
import Dropdown from '../../techComponents/Dropdown.js'; 
import List from '../../techComponents/List.js'; 
import { Button, Box, Input, Checkbox, FormLabel, Select } from "@chakra-ui/react";


function IPlanResourcesReviewTeacher() {
    const apiUrl1 = apiUrlConfig_json.A_Participations;
    const apiUrl2 = apiUrlConfig_json.A_Assessments;
    const apiUrl3 = apiUrlConfig_json.A_Resource_Lines;
    const apiUrl4 = apiUrlConfig_json.A_Evaluations;
    const apiUrl5 = apiUrlConfig_json.A_Participation_Lines;
    const apiUrl6 = apiUrlConfig_json.L_Learners;

    const { user_id } = useAuth(); // Get the user_id from auth context
    
    const [participations, setParticipations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentParticipation, setCurrentParticipation] = useState(null);
    const [resourceLines, setResourceLines] = useState([]);
    
    const [p_assessment_id, setpAssessmentid] = useState();
    const [p_learner_id, setpLearnerId] = useState('');
    const [p_grade, setpGrade] = useState('');
    const [p_campus, setpCampus] = useState('');
    const [p_section, setpSection] = useState('');

    const [qNumber, setQNumber] = useState();
    const [showText, setShowText] = useState(false);
    const [enableTeacherFeedback, setEnableTeacherFeedback] = useState(false);

    const fetchParticipations = async () => {
        try {
            // const assessment_id = 2668;
            let url = `${apiUrl1}get_participations?status=S`;
            if (p_assessment_id) { url += `&assessment_id=${p_assessment_id}`; }
            if (p_learner_id) { url += `&learner_id=${p_learner_id}`; }            
            if (p_grade) { url += `&grade=${p_grade}`; }
            if (p_campus) { url += `&campus=${p_campus}`; }
            if (p_section) { url += `&section=${p_section}`; }                                    
            const participationResponse = await axios.get(url);

            // const participationResponse = await axios.get(`${apiUrl1}get_participations?assessment_id=${p_assessment_id}&grade=${p_grade}&status=S`);
            const participationData = participationResponse.data;
            const updatedParticipations = await Promise.all(participationData.map(async (participation) => {
                const learnerResponse = await axios.get(`${apiUrl6}${participation.learner_id}/`);
                const learnerData = learnerResponse.data;
                return { ...participation, learner: learnerData };
            }));

            setParticipations(updatedParticipations);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const fetchResourceData = async (participationId, assessmentId) => {
        try {
            const assessmentResponse = await axios.get(`${apiUrl2}${assessmentId}/`);
            const assessmentData = assessmentResponse.data;
            const resourceId = assessmentData.resource_id;
            
            const resourceResponse = await axios.get(`${apiUrl3}get_resource_lines/?res=${resourceId}&status=A`);
            const resourceData = resourceResponse.data;
            const resource = resourceData;

            setResourceLines(resource);

        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     fetchParticipations();
    // }, [assessment_id]);

    // useEffect(() => {
    //     fetchParticipations(p_assessment_id); 
    // }, [p_assessment_id]); 


    const handleAssessmentClick = async (participation) => {
        setCurrentParticipation(participation);
        fetchResourceData(participation.id, participation.assessment_id);
    };

    const handleClose = () => {
        setCurrentParticipation(null);
        setLoading(true);
        fetchParticipations(p_assessment_id);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Box mt={2}>
                <Button type="button" onClick={() => fetchParticipations()} colorScheme="green">
                        Show Participations
                </Button>
                <Box display="flex" alignItems="left" mb={4}>
                    <FormLabel htmlFor="p_assessment_id" width="150px" style={{ marginRight: '10px' }}>Select Assessment</FormLabel>
                    <Box width="50vw" maxWidth="400px">
                        <Dropdown
                            id="p_assessment_id"
                            lov="a_assessments"
                            placeholder="Select Assessment"
                            value={p_assessment_id}
                            onChange={(value) => setpAssessmentid(value)}
                            style={{ width: '100%' }}
                        />
                    </Box>
                </Box>
                <Box display="flex" alignItems="left" mb={4}>
                    <FormLabel htmlFor="p_learner_id" width="150px" style={{ marginRight: '10px' }}>Select Learner</FormLabel>
                    <Box width="50vw" maxWidth="400px">
                        <Dropdown
                            id="p_learner_id"
                            lov="l_learners"
                            placeholder="Select Learner"
                            value={p_learner_id}
                            onChange={(value) => setpLearnerId(value)}
                            style={{ width: '100%' }}
                        />
                    </Box>
                </Box>
                <Box display="flex" alignItems="left" mb={4}>
                    <FormLabel htmlFor="p_grade" width="150px" style={{ marginRight: '10px' }}>Select Grade Level</FormLabel>
                    <Box width="50vw" maxWidth="400px">
                        <List
                            id="p_grade"
                            field={{ label: "Select Grade Level" }} // Provide the field prop with a label
                            lov="GradeLevels"
                            placeholder="Select Grade Level"
                            value={p_grade}
                            onChange={(value) => setpGrade(value || '')}
                            style={{ width: '100%' }}
                        />
                    </Box>
                </Box>                
                <Box display="flex" alignItems="left" mb={4}>
                    <FormLabel htmlFor="p_campus" width="150px" style={{ marginRight: '10px' }}>Select Campus</FormLabel>
                    <Box width="50vw" maxWidth="400px">
                        <List
                            id="p_campus"
                            field={{ label: "Select Campus" }} // Provide the field prop with a label
                            lov="Campuses"
                            placeholder="Select Campus"
                            value={p_campus}
                            onChange={(value) => setpCampus(value || '')}
                            style={{ width: '100%' }}
                        />
                    </Box>
                </Box>                
                <Box display="flex" alignItems="left" mb={4}>
                    <FormLabel htmlFor="p_section" width="150px" style={{ marginRight: '10px' }}>Select Section</FormLabel>
                    <Box width="50vw" maxWidth="400px">
                        <List
                            id="p_section"
                            field={{ label: "Select Section" }} // Provide the field prop with a label
                            lov="Sections"
                            placeholder="Select Section"
                            value={p_section}
                            onChange={(value) => setpSection(value || '')}
                            style={{ width: '100%' }}
                        />
                    </Box>
                </Box>                

                <Box display="flex" alignItems="center" mb={2}>
                    <FormLabel htmlFor="qNumber" width="150px" style={{ marginRight: '10px' }}>Question Number</FormLabel>
                    <Input
                        id="qNumber"
                        placeholder="Enter Q number to review"
                        value={qNumber}
                        onChange={(e) => setQNumber(e.target.value)}
                        style={{ width: '10vw' }}
                    /> 
                </Box>                    
                <Box display="flex" alignItems="center" mb={2}>
                    <FormLabel htmlFor="showText" width="150px" style={{ marginRight: '10px' }}>Show Text</FormLabel>                    
                    <Checkbox
                        id="showText"
                        isChecked={showText}
                        onChange={(e) => setShowText(e.target.checked)}
                    />
                </Box>
                <Box display="flex" alignItems="center" mb={2}>
                    <FormLabel htmlFor="enableTeacherFeedback" width="150px" style={{ marginRight: '10px' }}>Enable Teacher Feedback</FormLabel>                    
                    <Checkbox
                        id="enableTeacherFeedback"
                        isChecked={enableTeacherFeedback}
                        onChange={(e) => setEnableTeacherFeedback(e.target.checked)}
                    />
                </Box>
            </Box>

            {!currentParticipation && participations.map((participation) => (
                <div key={participation.id} className="assessment-container">
                    <h3>{participation.learner.name_full} ( {participation.learner.ext_sys_id} ) </h3>
                    <Button
                        onClick={() => handleAssessmentClick(participation)}
                        colorScheme="blue"
                    >
                        Review Assessment
                    </Button>
                </div>
            ))}
            {currentParticipation && (
                <div>
                    {resourceLines.map((resourceLine, index) => (
                        <div key={index}>
                            {resourceLine.res_class === "TEXT" ? (
                                <div className="text-container">
                                  {showText && <TextGet currentTextFile={resourceLine.cms_res_id} /> }
                                </div>
                            ) : (
                                <TaskReviewTeacher
                                    participationId={currentParticipation.id}
                                    apiUrl4={apiUrl4}
                                    apiUrl5={apiUrl5}
                                    user_id={user_id}
                                    currentTaskFile={resourceLine.cms_res_id}
                                    res_line_number={resourceLine.line_number}
                                    res_line_id={resourceLine.id}
                                    qNumber={qNumber}
                                    enableTeacherFeedback={enableTeacherFeedback}
                                    handleClose={handleClose}
                                />
                            )}
                        </div>
                    ))}

                </div>
            )}
            {/* <Button onClick={() => fetchParticipations(484)} colorScheme="blue" mt={4}>
                    Review Assessment
                </Button> */}

            <Button onClick={handleClose} colorScheme="teal" mt={4}>
                Close
            </Button>
        </>
    );
}

export default IPlanResourcesReviewTeacher;
