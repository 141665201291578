import React from 'react';
import Select from 'react-select';
import listConfig from '../config/list.json';

const List = ({ field, lov, value, onChange, ...rest }) => {
  const config = listConfig[lov];
  const options = [
    { value: '', label: 'Select' }, // Placeholder option
    ...config.map(option => ({
      value: option.value,
      label: option.label
    }))
  ];

  const handleChange = selectedOption => {
    onChange(selectedOption ? selectedOption.value : null);
  };

  const selectedValue = options.find(option => option.value === value) || null; // Allow null for no selection

  return (
    <Select 
      options={options} 
      placeholder={field.label} 
      value={selectedValue} 
      onChange={handleChange} 
      {...rest} 
      styles={{ container: (base) => ({ ...base, width: '50vw' }) }} // 50% of the viewport width
    />
  );
};

export default List;
