import React, { useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { Box, Button, Input, FormControl, FormLabel, FormErrorMessage, useToast } from '@chakra-ui/react';

import fieldSchema_json from '../../config/fieldSchema.json';
import apiUrlConfig_json from '../../config/apiUrl.json';

const C_Levels_Multi_Row = ({ handleView }) => {
    
    const fieldSchema = fieldSchema_json.C_Levels;
    const apiUrl = apiUrlConfig_json.C_Levels;

    const { control, handleSubmit, register, reset, formState: { errors } } = useForm({        
        defaultValues: {
            masterField: '',
            children: [Object.fromEntries(fieldSchema.map(field => [field.name, '']))]
        }
    });
    const { fields: rows, append, remove } = useFieldArray({
        control,
        name: "children",
        keyName: "arrayRow_id"  // Custom key name that doesn't conflict with id column name in our fetched table
    });
    
    const [deletedIds, setDeletedIds] = useState([]);
    const handleDeleteRow = (index, id) => {
        if (id) {
            setDeletedIds(prev => [...prev, id]); // Track deletions by ID
        }
        remove(index);
    };

    const handleAddRow = () => {
        append(Object.fromEntries(fieldSchema.map(field => [field.name, ''])));
    };

    const handleCancel = () => {
        reset();  // This will reset the form fields to their initial values
        handleView('Curriculum');  // Assume handleView is your navigation function
    };
    
    const handleQuery = async () => {
        try {
            const response = await fetch(apiUrl); // Ensure apiUrl is correctly defined to fetch records
            const data = await response.json();
            reset({ children: data }); // Assuming the response matches the fields structure
            console.log("fetch carried out");
        } catch (error) {
            console.error('Failed to fetch records:', error);
        }
    };

    const toast = useToast();
    const onSubmit = async (data) => {
        const payload = {
        // masterData: data.masterField,
            newEntries: data.children.filter(child => !child.id), // Assumes no ID means a new entry
            updatedEntries: data.children.filter(child => child.id), // Assumes ID means an existing entry
            deletedEntries: deletedIds
        };
        console.log(payload);
        console.log("Payload for submission:", JSON.stringify(payload, null, 2));

        // Following code has been modified to allow bulk create. 
        // Commented lines can be uncommented to allow single row POST.
        //        payload.newEntries.forEach(entry => {
        //            fetch(apiUrl, {
        ////            fetch(apiUrl + 'bulk_create/', {
        ////                method: 'POST', // Or 'PUT' depending on your API
        ////            headers: {'Content-Type': 'application/json'},
        //                body: JSON.stringify(entry)
        ////                body: JSON.stringify(payload.newEntries)    
        ////            })
        ////            .then(response => response.json())
        ////            .then(result => console.log('Create Batch processed:', result))
        ////            .catch(error => console.error('Error processing Batch:', error));
        //        });

// Assuming `toast` and `reset` are available in your context, similar to the second code block

        try {
            const response = await fetch(apiUrl + 'bulk_create/', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(payload.newEntries)
            });
            if (!response.ok) throw new Error(`HTTP status ${response.status}`);
            const result = await response.json();
            console.log('Create Batch processed:', result);
            toast({
                title: "Success",
                description: "Entries created successfully",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error processing Batch:', error);
            toast({
                title: "Error",
                description: "Failed to create entries",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }

        // Following code has been modified to allow bulk create. 
        // Commented lines can be uncommented to allow single row POST.
        //        payload.deletedEntries.forEach(id => {
        //            fetch(`${apiUrl}${id}/`, {
        try {
            const response = await fetch(`${apiUrl}bulk_delete/`, {
                method: 'DELETE',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ ids: payload.deletedEntries })                
            });
            if (!response.ok) throw new Error(`HTTP status ${response.status}`);
            const result = await response.json();
            console.log('Delete Batch processed:', result);
            toast({
                title: "Success",
                description: "Entries deletd successfully",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error processing Batch:', error);
            toast({
                title: "Error",
                description: "Failed to delete entries",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }

        // Following code has been modified to allow sigle row update. 
        // Commented lines can be uncommented to allow multi row POST, but this actually cause INSERTs.
        // So, don't use bulk update until the Django code is fixed for this.
        /// payload.updatedEntries.forEach(entry => {
        for (const entry of payload.updatedEntries) {            
            try {
                const response = await fetch(`${apiUrl}${entry.id}/`, {
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(entry)
                });
                if (!response.ok) throw new Error(`HTTP status ${response.status}`);
                const result = await response.json();
                console.log('Upate Batch processed:', result);
                toast({
                    title: "Success",
                    description: "Entries updated successfully",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } catch (error) {
                    console.error('Error processing Batch:', error);
                    toast({
                        title: "Error",
                        description: "Failed to update entries",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
             }
        }
        reset();  // Reset the form state
    }

    return (
        <div className="container">
        <h2>Curriculum Levels</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Input {...register('masterField')} placeholder="Master Field" />

            {/* Render labels at the top */}
            <Box display="flex" alignItems="center" width="100%" pl="5px" justifyContent="space-between">
                {fieldSchema.filter(field => field.name !== 'id').map((field) => (
                    <FormLabel key={`label-${field.name}`} htmlFor={field.name} width="100px" mb="0">
                        {field.label}
                    </FormLabel>
                ))}
                <Box display="flex" alignItems="center" pl="5px">Actions</Box> {/* Placeholder for action column */}
            </Box>

            {/* Render fields for each row beneath the labels */}
            {rows.map((row, index) => (
                <Box key={row.arrayRow_id} display="flex" alignItems="center" width="100%" pl="5px" gap="2" justifyContent="space-between">
                    {fieldSchema.map((schemaField) => (
                        <FormControl key={`${row.arrayRow_id}-${schemaField.name}`}>
{/*                            <FormLabel htmlFor={`children[${index}].${schemaField.name}`}>{schemaField.label}</FormLabel> */}
                            <Controller
                                name={`children[${index}].${schemaField.name}`}
                                control={control}
                                rules={{ required: schemaField.required }}
                                render={({ field }) => (
                                    <Input {...field} type={schemaField.type} readOnly={schemaField.readOnly} placeholder={schemaField.placeholder} width="200px" />
                                )}
                            />
                            <FormErrorMessage>
                                {errors[`children[${index}].${schemaField.name}`] && `${schemaField.label} is required`}
                            </FormErrorMessage>
                        </FormControl>
                    ))}
                    <Button size="sm" minWidth="40px" colorScheme="red" onClick={() => handleDeleteRow(index, row.id)}>X</Button> 
                </Box>
            ))}

            {/* Include this button in your form rendering */}
            <Box display="flex" mt="10" mb="4" gap="10px">
                <Button onClick={handleAddRow} colorScheme="green">Add Row</Button>
                <Button onClick={handleQuery} colorScheme="teal">GET Records</Button>
        {/*
                <Button onClick={handleAdd} colorScheme="teal">Add</Button>
                <Button onClick={handleUpdate} colorScheme="teal">Update</Button>
                <Button onClick={handleDelete} colorScheme="teal">Delete</Button>
        */}
                <Button type="submit" colorScheme="blue">Submit Batch</Button>      
                <Button onClick={handleCancel} colorScheme="red">Cancel</Button>

            </Box>

        </form>
        </div>
    );
};

export default C_Levels_Multi_Row;
