import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button } from '@chakra-ui/react';
import fieldSchema_json from '../../config/fieldSchema_shared.json';
import FieldsRendering from '../../techComponents/FieldsRendering';
import TextGet from './TextGet';
import A_TaskMCQ_Preview from '../assessment/A_TaskMCQ_Preview';
import A_TakeAssessments_Preview from '../assessment/A_TakeAssessments_Preview';
import useHasAccess from '../../techComponents/hasAccess';

// 240630 changes to allow delete and download of files
import apiUrlConfig_json from '../../config/apiUrl.json';

const PreviewComponent = () => {
  const fieldSchema = fieldSchema_json.Preview; // Adjust this to match your schema name
  const apiUrl = apiUrlConfig_json.CMS;
  const hasAccess = useHasAccess;

  // Generate initial form values
  const initialFormValues = fieldSchema.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {});

  const { control, handleSubmit, watch, formState: { errors } } = useForm({
    defaultValues: initialFormValues
  });

  const [submittedData, setSubmittedData] = useState(null);

  const onSubmit = (data) => {
    console.log('Form Data:', data);
    setSubmittedData(data);
  };

  const handleDelete = async (currentTextFile) => {
    try {
      const response = await fetch(`${apiUrl}gcs_delete/${currentTextFile}/`, {
        method: 'DELETE',
      });
      if (response.ok) {
        alert('File deleted successfully');
        // Optionally, update the state to remove the deleted file from the UI
      } else {
        alert('Failed to delete file');
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      alert('Error deleting file');
    }
  };

  const handleDownload = (downloadFile) => {
    const link = document.createElement('a');
    link.href = `${apiUrl}gcs_download/${downloadFile}/`;
    link.download = downloadFile;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="container">
      <h2>Preview Component</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldsRendering control={control} errors={errors} fieldSchema={fieldSchema} />
        <Box mt={4}>
          <Button type="submit" colorScheme="blue">Submit</Button>
        </Box>
      </form>

      {submittedData && (
        <div className="output-container">
          {submittedData.TextFile && (
            <>
              <TextGet currentTextFile={submittedData.TextFile} />

              {hasAccess('CMS_Resources', 'deleteButton') && (
              <Button colorScheme="red" onClick={() => handleDelete(submittedData.TextFile)}>Delete Text File</Button>
              )}

              {/* <Button colorScheme="green" onClick={() => handleDownload(submittedData.TextFile)}>Download Text File</Button> */}
            </>
          )}
          {submittedData.TaskFile && (
            <>
              <A_TaskMCQ_Preview currentTaskFile={submittedData.TaskFile} />
              
              {hasAccess('CMS_Resources', 'deleteButton') && (              
              <Button colorScheme="red" onClick={() => handleDelete(submittedData.TaskFile)}>Delete Task File</Button>
              )}

              {/* <Button colorScheme="green" onClick={() => handleDownload(submittedData.TaskFile)}>Download Task File</Button> */}
            </>
          )}
          {submittedData.Assessment_ID && <A_TakeAssessments_Preview assessmentId={submittedData.Assessment_ID} showSubmitButton={true} />}
        </div>
      )}
    </div>
  );
};

export default PreviewComponent;
