import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import FieldsRendering from '../../techComponents/FieldsRendering';
import apiUrlConfig_json from '../../config/apiUrl.json'; // Assuming this file contains the endpoint URLs
import fieldSchema_json from '../../config/fieldSchema.json'; // Assuming this file contains the field definitions
import { useAuth } from '../../context/AuthContext'; // Adjust the path as necessary

const FileUploadComponent = ({ p_file, resetFile, p_resource_class }) => {
  const fieldSchema = fieldSchema_json.FileUploadForm;
  const apiUrl = apiUrlConfig_json.CMS;
  const { user_id } = useAuth(); // Get the role_id of the logged on user from auth context  

  const defaultValues = fieldSchema.reduce((acc, field) => {
//    acc[field.name] = field.defaultValue || getDefaultValue(field.type);
    acc[field.name] = field.defaultValue? field.defaultValue : (field.type == "number"? 0 : '');
    return acc;
}, {});

  const { control, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm({
    defaultValues,
  });
  
  const [file, setFile] = useState(p_file);

  useEffect(() => {
    if (p_resource_class) {  // Check if p_resource_class is present
        setValue('resource_class', p_resource_class);
    }
  }, []);  // Empty dependency array ensures this runs only on initial render

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('org_id', data.org_id);
    if (file) {
      formData.append('file', file);
    }
    formData.append('url', data.url);
    formData.append('url_res_type', data.url_res_type);
    formData.append('resource_class', data.resource_class);
    formData.append('course_id', data.course_id);
    formData.append('complexity_level', data.complexity_level);
    formData.append('user_id', user_id);

    try {
//      const response = await fetch(apiUrl.upload, {
      const response = await fetch(`${apiUrl}gcs_upload/`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
//        throw new Error('File upload failed');
        const responseError = await response.json();
        throw new Error(`HTTP status ${response.status + " (" + response.statusText + ") " + responseError.error }`);
      }

      const responseData = await response.json();
      alert('File uploaded successfully');
      reset(); // Reset form after successful upload
      if (p_file && resetFile) { resetFile(); }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldsRendering control={control} errors={errors} fieldSchema={fieldSchema} />
      <div>
        {!p_file && <input type="file" onChange={handleFileChange} required={true} /> }
        {file && <h3>File name: <span style={{ color: "red", fontWeight: "bold" }}>{file.name}</span></h3> }
      </div>
      <Button type="submit">Upload / Create</Button>
    </form>
  );
};

export default FileUploadComponent;
