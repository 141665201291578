import { useToast } from '@chakra-ui/react';
import { useAuth } from '../context/AuthContext'; // Adjust the path as necessary

const useFormSubmit = (apiUrl, fetchRecords, reset) => {
  const toast = useToast();
  const { user_id } = useAuth(); // Get the user_id of the logged on user from auth context  

  const onSubmit = async (data, currentIndex, records) => {

    if (!data.date_start) data.date_start = null;
    if (!data.date_end) data.date_end = null;
  
    const isNewRecord = currentIndex === -1;
    const method = isNewRecord ? 'POST' : 'PUT';
    const recordId = !isNewRecord ? `${records[currentIndex].id}/` : '';

    try {

      if (method === 'POST') {
        data.created_by = user_id;
        data.created_on = new Date().toISOString(); // Captures client-side date in ISO format
      }
      if (method === 'PUT') {
        data.updated_by = user_id;
        data.updated_on = new Date().toISOString(); // Captures client-side date in ISO format
      }

      const response = await fetch(`${apiUrl}${recordId}`, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      if (!response.ok) throw new Error(`HTTP status ${response.status} (${response.statusText})`);

      const result = await response.json();
//      console.log('Submission Success:', result); // provides detail in conole log
      console.log('Submission Success:');
      toast({
        title: "Success",
        description: "Entries created successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      reset();
      fetchRecords();
    } catch (error) {
      console.error('Failed to submit form:', error);
      toast({
        title: "Error",
        description: "Failed to create entries",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return { onSubmit };
};

export default useFormSubmit;
