import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiUrlConfig_json from '../../config/apiUrl.json';
import "./DeleteVectorStore.css"

const DeleteVectorStore = () => {
  const apiUrl = apiUrlConfig_json.AAPI;
  const [vectorStoreId, setVectorStoreId] = useState('');
  const [message, setMessage] = useState('');

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`${apiUrl}vector-stores/delete/${vectorStoreId}/`);
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Error deleting vector store: ' + error.response.data.error);
    }
  };

  return (
    <div className="delete-vector-store">
      <h2>Delete Vector Store</h2>
      <input
        type="text"
        value={vectorStoreId}
        onChange={(e) => setVectorStoreId(e.target.value)}
        placeholder="Vector Store ID"
      />
      <button onClick={handleDelete}>Delete</button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default DeleteVectorStore;
