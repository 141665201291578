// Learner.js
import React from 'react';
import { useRoutingNavigation } from '../../routing/useNavigation'; // Import useHistory hook from React Router
import '../../styleSheets/edtech.css'; // Adjust the path according to your project structure

function Learner() {
  const { handleRouting } = useRoutingNavigation();
  
  return (
    <div className="container">
      <h2>Learner</h2>
      <button className="button" onClick={handleRouting('/learning/Learner')}>Learner</button>
      <button className="button">Cognitive Development</button>
      <button className="button">Personal-Social-Emotional Development</button>
      <button className="button">Physical Development</button>
      {/* Placeholder for navigation and further breakdown */}
      <button className="button" onClick={handleRouting('/learning-system')}>Return to Learning System</button>            
    </div>
  );
}

export default Learner;
