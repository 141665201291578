import React, { useState } from 'react';
import { useRoutingNavigation} from '../../routing/useNavigation'; // Import useHistory hook from React Router
import { Button, Heading } from "@chakra-ui/react";

import LNRTaskPerformance from '../learner/LNRTaskPerformance.js';
import IPlanResourcesReviewTeacher from '../review/IPlanResourcesReviewTeacher.js';
import EvaluationReview from '../learner/EvaluationReview.js';

import CreateResourceItems from '../shared/CreateResourceItems.js';
import CreateMedia from '../shared/CreateMedia';
import CreateText from '../shared/CreateText';
import CreateTask from '../shared/CreateTask';
import LearnerThreads from '../../assistantapi/Chat/LearnerThreads.jsx';

function Teacher2() {

  const { handleRouting } = useRoutingNavigation();

    const [currentView, setCurrentView] = useState('Teacher2');
    const handleView = (viewName) => {
      setCurrentView(viewName);
    };
    
    return (
      <div className="container">
        <Heading as="h1" color="green.500" size="sm">Teacher Tasks</Heading>
        <Button mt={2} mb={2} ml={0} colorScheme="green" width="max(50vw, 400px)" onClick={() => handleView('Teacher2')}>Return to Teacher Tasks Home</Button>

        {currentView === 'Teacher2' && (
        <>
        <button className="button" onClick={() => handleView('LNRTaskPerformance')}>Learner Task Performance</button>
        <button className="button" onClick={() => handleView('IPlanResourcesReviewTeacher')}>Review Learner Performance</button>
        <button className="button" onClick={() => handleView('EvaluationReview')}>Review Assessment Evaluation</button>

        {/* <button className="button" onClick={() => handleView('CreateResourceItems')}>Create Resource Items</button> */}
        <button className="button" onClick={() => handleView('CreateMedia')}>Create Resource Items (Media)</button>
        {/* <button className="button" onClick={() => handleView('CreateText')}>Create Resource Items (Text)</button> */}
        <button className="button" onClick={() => handleView('CreateTask')}>Create Resource Items (Task)</button>                        
        <button className="button" onClick={() => handleView('LearnerThreads')}>Interactive Learning</button>                                
        <button className="button" onClick={handleRouting('/learning')}>Return to Learning Process</button>
        </>
        )}

        {currentView === 'LNRTaskPerformance' && <LNRTaskPerformance handleView={handleView} />}
        {currentView === 'IPlanResourcesReviewTeacher' && <IPlanResourcesReviewTeacher handleView={handleView} />}
        {currentView === 'EvaluationReview' && <EvaluationReview handleView={handleView} />}
        {/* {currentView === 'CreateResourceItems' && <CreateResourceItems handleView={handleView} />} */}
        {currentView === 'CreateMedia' && <CreateMedia handleView={handleView} />}
        {/* {currentView === 'CreateText' && <CreateText handleView={handleView} />} */}
        {currentView === 'CreateTask' && <CreateTask handleView={handleView} />}                        
        {currentView === 'LearnerThreads' && <LearnerThreads handleView={handleView} />}                                
      </div>
    );
  }

  export default Teacher2;