import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styleSheets/quizStyles.css'; // Adjust the path according to your project structure
import { useAuth } from '../../context/AuthContext'; // Adjust the path as necessary
import apiUrlConfig_json from '../../config/apiUrl.json';
import TextGet from '../shared/TextGet'; // Adjust the path as necessary
import Task from '../shared/Task'; // Adjust the path as necessary
import { Button } from "@chakra-ui/react";


function IPlanResources() {
    const apiUrl1 = apiUrlConfig_json.A_Participations;
    const apiUrl2 = apiUrlConfig_json.A_Assessments;
    const apiUrl3 = apiUrlConfig_json.A_Resource_Lines;
    const apiUrl4 = apiUrlConfig_json.A_Evaluations;
    const apiUrl5 = apiUrlConfig_json.A_Participation_Lines;

    const { user_id } = useAuth(); // Get the user_id from auth context
    
    const [participations, setParticipations] = useState([]);
    const [assessmentList, setAssessmentList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentParticipation, setCurrentParticipation] = useState(null);
    const [date_start, setDate_start] = useState(null);
    const [resourceLines, setResourceLines] = useState([]);
    const [tasksSubmitted, setTasksSubmitted] = useState(0);
    const [isAssessmentSubmitted, setIsAssessmentSubmitted] = useState(false);

    const fetchParticipations = async () => {
        try {
            const participationResponse = await fetch(`${apiUrl1}?learner_id=${user_id}&status=A`);
            const participationData = await participationResponse.json();

            const assessments = await Promise.all(participationData.map(async (participation) => {
                const assessmentResponse = await fetch(`${apiUrl2}${participation.assessment_id}/`);
                const assessmentData = await assessmentResponse.json();
                return { ...participation, assessment: assessmentData };
            }));

            setParticipations(participationData);
            setAssessmentList(assessments);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const fetchResourceData = async (participationId, assessmentId) => {
        try {
            const assessmentResponse = await fetch(`${apiUrl2}${assessmentId}/`);
            const assessmentData = await assessmentResponse.json();
            const resourceId = assessmentData.resource_id;

            const resourceResponse = await fetch(`${apiUrl3}?res=${resourceId}&status=A&learner_id=${user_id}&participation_id=${participationId}`);
            const resourceData = await resourceResponse.json();
            const resource = resourceData;

//            setResourceLines(resource?.resource_list || []);
            setResourceLines(resource);

        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchParticipations();
    }, [user_id]);

    const handleAssessmentClick = async (participation) => {
        setCurrentParticipation(participation);
//        setDate_start(new Date().toISOString());
        fetchResourceData(participation.id, participation.assessment_id);
        if (!participation.date_start) {
            await axios.patch(`${apiUrl1}${participation.id}/`, { date_start: new Date().toISOString() });
        }         // Use await to ensure the request completes  
    };

    const handleTaskSubmit = () => {
        setTasksSubmitted(prev => prev + 1);
    };

    const handleAssessmentComplete = async () => {
        // Update participation status to 'completed'
        const date_end = new Date().toISOString();
        const time_taken = Math.round((new Date(date_end) - new Date(currentParticipation.date_start)) / 60000);

        const updateParticipation = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                status: 'S',
//                date_start: date_start,   //this is set at Assessment start, which could be multi session
                date_end: date_end,
                time_taken: time_taken,
            }),
        };

        try {
            console.log("Attempting to update participation...");
            const participationResponse = await fetch(`${apiUrl1}${currentParticipation.id}/`, updateParticipation);

            if (!participationResponse.ok) {
                const contentType = participationResponse.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    const errorResponse = await participationResponse.json();
                    throw new Error(`Participation update failed: ${errorResponse.detail || participationResponse.statusText}`);
                } else {
                    const errorText = await participationResponse.text();
                    throw new Error(`Participation update failed: ${errorText}`);
                }
            }

            setIsAssessmentSubmitted(true);
        } catch (error) {
            console.error('Error updating database:', error);
        }
    };

    const handleClose = () => {
        setCurrentParticipation(null);
        setIsAssessmentSubmitted(false);
        setTasksSubmitted(0);
        setLoading(true);
        fetchParticipations();
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            {!currentParticipation && assessmentList.map((assessment) => (
                <div key={assessment.assessment_id} className="assessment-container">
                    <h3>{assessment.assessment.name}</h3>
                    <Button
                        onClick={() => handleAssessmentClick(assessment)}
                        colorScheme="blue"
                    >
                        Start Assessment
                    </Button>
                </div>
            ))}
            {currentParticipation && !isAssessmentSubmitted && (
                <div>
                    {resourceLines.map((resourceLine, index) => (
                        <div key={index}>
                            {resourceLine.res_class === "TEXT" ? (
                                <div className="text-container">
                                    <TextGet currentTextFile={resourceLine.cms_res_id} />
                                </div>
                            ) : (
                                <Task
                                    participationId={currentParticipation.id}
                                    apiUrl4={apiUrl4}
                                    apiUrl5={apiUrl5}
                                    user_id={user_id}
                                    date_start={date_start}
                                    currentTaskFile={resourceLine.cms_res_id}
                                    res_line_number={resourceLine.line_number}
                                    res_line_id={resourceLine.id}
                                    onTaskSubmit={handleTaskSubmit}
                                />
                            )}
                        </div>
                    ))}

{/*                    {tasksSubmitted === resourceLines.length && (     
                    {tasksSubmitted === resourceLines.filter(item => item.res_class === 'TASK').length && ( 
                    {resourceLines.filter(item => item.res_class === 'TASK' && item.status === 'A').length === 0 && ( */}
                    {
                        (tasksSubmitted === resourceLines.filter(item => item.res_class === 'TASK').length || 
                        resourceLines.filter(item => item.res_class === 'TASK').length === 0) && (
                            <Button onClick={handleAssessmentComplete} colorScheme="teal" mt={4}>
                                Finish Assessment
                            </Button>
                        )
                    }        
                        {/* <Button onClick={handleAssessmentComplete} colorScheme="teal" mt={4}>
                            Finish Assessment
                        </Button> */}
                </div>
            )}
            {isAssessmentSubmitted && (
                <Button onClick={handleClose} colorScheme="teal" mt={4}>
                    Close
                </Button>
            )}
        </>
    );
}

export default IPlanResources;
