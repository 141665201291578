import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import dropdownConfig from '../config/dropdown.json';
import apiUrlConfig from '../config/apiUrl.json';
import { useAuth } from '../context/AuthContext'; // Adjust the path as necessary

const Dropdown = ({ lov, placeholder, isInvalid, value, onChange, ...rest }) => {
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(null);
  const { user_id, role_id } = useAuth(); // Get the role_id of the logged on user from auth context

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const config = dropdownConfig[lov];
        const apiUrl = apiUrlConfig[config.apiUrlKey];

        // const queryFilter = config.query_filter || ''; // Default to empty string if query_filter is not present
        const queryFilter = config.query_filter
        ? `${config.query_filter}&user_id=${encodeURIComponent(user_id)}&role_id=${encodeURIComponent(role_id)}`
        : `?user_id=${encodeURIComponent(user_id)}&role_id=${encodeURIComponent(role_id)}`;

        const response = await fetch (`${apiUrl}${queryFilter}`);
        if (!response.ok) throw new Error(`HTTP status ${response.status}`);
        const data = await response.json();
        const formattedOptions = data.map(item => ({
          value: item[config.value_attr],
//          label: item[config.label_attr],
          label: Array.isArray(config.label_attr) 
            ? config.label_attr.map(attr => item[attr]).join(' | ') 
            : item[config.label_attr],          
        }));
        setOptions(formattedOptions);
        setError(null); // Clear any previous errors
      } catch (error) {
        console.error('Failed to fetch options:', error);
        setError('Failed to load options'); // Set error message
      }
    };

    fetchOptions();
  }, [lov]);

  return (
    <FormControl isInvalid={isInvalid || error}>
{/*      <FormLabel>{placeholder}</FormLabel> */}
      <Select
        options={options}
        placeholder={placeholder}
        value={options.find(option => option.value === value)}
        onChange={(selectedOption) => onChange(selectedOption.value)}
        {...rest}
      />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default Dropdown;
