// 20240421
// React Hooks Form, using UI library chakra, and all CRUD tasks, including GET
// and scroll down / up buttons

import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Input, Button, FormControl, FormLabel, FormErrorMessage, Box } from '@chakra-ui/react';

import fieldSchema_json from '../../config/fieldSchema.json';
import apiUrlConfig_json from '../../config/apiUrl.json';


  const L_Learners = () => {

    const fieldSchema = fieldSchema_json.L_Access;
    const apiUrl = apiUrlConfig_json.L_Access;
  
    const generateInitialValues = (fields) => {
      const initialValues = {};
      fields.forEach(field => {
        initialValues[field.name] = '';  // Set to empty string or a sensible default
      });
      return initialValues;
    };
    const initialFormValues = generateInitialValues(fieldSchema);

    
    const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
      defaultValues: initialFormValues
    });

  const [records, setRecords] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchParam, setSearchParam] = useState('');

  // useEffect(() => {
  //   fetchRecords();
  // }, []);


  useEffect(() => {
    // Set form values when records or currentIndex changes
//    if (records.length > 0 && currentIndex < records.length) {
    if (currentIndex !== -1 && currentIndex < records.length) {      
      fieldSchema.forEach(field => {
        setValue(field.name, records[currentIndex][field.name]);
      });
    }
  }, [records, currentIndex, setValue, fieldSchema]);

  const fetchRecords = async () => {
//    const response = await fetch(`${apiUrl}`, {
    const response = await fetch(`${apiUrl}?search=${searchParam}`, {          
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });
    if (response.ok) {
      const data = await response.json();
      setRecords(data);
    } else {
      console.error('Failed to fetch records');
    }
  };

  const handleSearch = () => {
    fetchRecords();
  };


  const onSubmit = async (data) => {
//    const method = records[currentIndex] ? 'PUT' : 'POST';
//    const recordId = records[currentIndex] ? records[currentIndex].id : '';

    const isNewRecord = currentIndex === -1;
    const method = isNewRecord ? 'POST' : 'PUT';
    const recordId = !isNewRecord ? records[currentIndex].id+"/" : '';

    // Conditionally prepare the data for POST or PUT
//    const { id, ...updateData } = data;  // Destructure to separate `id` from the rest of the data
//    const payload = isNewRecord ? data : updateData; // Use full data for POST, exclude id for PUT

//    const method = currentIndex === -1 ? 'POST' : 'PUT';
//    const recordId = currentIndex !== -1 ? records[currentIndex].id : '';

    try {
//      const response = await fetch(`${apiUrl}${recordId}`, {
//      const response = await fetch(`${apiUrl}${recordId ? `/${recordId}` : ''}`, {    
//      const response = await fetch(`${apiUrl}${!isNewRecord ? `${recordId}` : ''}`, {            
      const response = await fetch(`${apiUrl}${recordId}`, {                    
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

//      if (!response.ok) throw new Error('Network response was not ok');
//      if (!response.ok) throw new Error(`HTTP status ${response.status}`);
      if (!response.ok) throw new Error(`HTTP status ${response.status + " (" + response.statusText + ")"}`);

      const result = await response.json();
      console.log('Submission Success:', result);
      reset();
      fetchRecords(); // Refetch records after submission
    } catch (error) {
      console.error('Failed to submit form:', error);
    }
  };

  const handleNext = () => {
    if (currentIndex < records.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNewRecord = () => {
    reset(initialFormValues);  // Reset form fields using the initial values
    setCurrentIndex(-1);       // Set currentIndex to -1 to indicate a new record
  };

  return (
    <div className="container">
    <h2>Manage Learners</h2>

    <div>
        <Input
          type="text"
          placeholder="Search"
          value={searchParam}
          onChange={(e) => setSearchParam(e.target.value)}
        />
        <Button onClick={handleSearch} colorScheme="green">Search</Button>
      </div>

    <form onSubmit={handleSubmit(onSubmit)}>
      {fieldSchema.map((field) => (
        <FormControl key={field.name} isInvalid={errors[field.name]}>
          <FormLabel htmlFor={field.name}>{field.label}</FormLabel>
          <Controller
            name={field.name}
            control={control}
            rules={{ required: field.required }}
//            render={({ field }) => <Input {...field} id={field.name} type={field.type || 'text'} />}
            render={({ field: { ref, ...rest } }) => (
              <Input {...rest} id={field.name} type={field.type} readOnly={field.readOnly} />            
            )}

            />
          <FormErrorMessage>
            {errors[field.name] && `${field.label} is required`}
          </FormErrorMessage>
        </FormControl>
      ))}
      <Box display="flex" mt={4}>
        <Button colorScheme="blue" type="submit" className='button_small'>Submit</Button>
        <Button ml={4} onClick={() => reset()} variant="outline">Reset</Button>
        <Button ml={4} onClick={handlePrevious} variant="ghost">Previous</Button>
        <Button ml={4} onClick={handleNext} variant="ghost">Next</Button>
        <Button ml={4} onClick={handleNewRecord} colorScheme="teal">New Record</Button>
      </Box>
    </form>
    </div>
  );
};

export default L_Learners;
