import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Importing Axios
import 'katex/dist/katex.min.css';
import Latex from 'react-latex';

import { Box, Textarea, Text, Button, Checkbox, FormLabel, Input } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import AudioRecorder from '../shared/AudioRecorder';
import TextDisplay from '../shared/TextDisplay';
import TextListen from '../shared/TextListen';
// import TaskGet from './TaskGet';
import A_TaskMCQ_Preview from '../assessment/A_TaskMCQ_Preview';
import L_Task from './L_Task';
import List from '../../techComponents/List';
import apiUrlConfig_json from '../../config/apiUrl.json';
import FileUploadComponent from '../assessment/FileUploadComponent';
import MediaRecorder from '../shared/MediaRecorder';

const L_CreateTask = () => {
    const apiUrl = apiUrlConfig_json.SHR;

    const { handleSubmit, control, setValue, getValues } = useForm();
    
    const [textInstructions, setTextInstructions] = useState('');
    const [textContent, setTextContent] = useState('');
    const [textGenerating, setTextGenerating] = useState(false);

    const [taskInstructions, setTaskInstructions] = useState('');
    const [taskContent, setTaskContent] = useState('');
    const [taskGenerating, setTaskGenerating] = useState(false);
    const [taskSubmitted, setTaskSubmitted] = useState(false); // State to store the file

    const [contextInclude, setContextInclude] = useState(false);
    const [previewText, setPreviewText] = useState(false);
    const [performTask, setPerformTask] = useState(true);
    const [taskError, setTaskError] = useState(null);   
    const [sysPromptFile3, setSysPromptFile3] = useState(null);   

    const [wordCountTextInstructions, setWordCountTextInstructions] = useState(0);
    const [wordCountText, setWordCountText] = useState(0);
    const [wordCountTaskInstructions, setWordCountTaskInstructions] = useState(0);

    const [textFileName, setTextFileName] = useState('text'); // Your file name
    // const [textFileName, setTextFileName] = useState(() => {
    //     const currentDateTime = new Date().toISOString().replace(/[:\-]/g, '').replace('T', '_').replace(/\..+/, '');
    //     return `${grade}_${subject}_${language}_${currentDateTime}`;
    // });

    const [textFile, setTextFile] = useState(null); // State to store the file
    const [uploadTextFile, setUploadTextFile] = useState(false); 

    const [taskFileName, setTaskFileName] = useState('task'); // Your file name
    const [taskFile, setTaskFile] = useState(null); // State to store the file

    const [uploadTaskFile, setUploadTaskFile] = useState(false); 

    const [mediaFileName, setMediaFileName] = useState('media'); // Your file name
    const [mediaFile, setMediaFile] = useState(null); // State to store the file

    const wordLimit = 100;
    const timeLimit = 1;
    const qCount = 6;
    const maxMarks = 4;

    const wordLimitTeacher = 8000;
    const timeLimitTeacher = 5;

    const [grade, setGrade] = useState('');
    const [subject, setSubject] = useState('');
    const [language, setLanguage] = useState('en');

    const isRTL = () => {
        // const rtlChars = /[\u0600-\u06FF\u0750-\u077F]/;
        // return rtlChars.test(text);
        return ((language==='ur') ? true : false)
    };

    const renderText = (text) => {
        if (isRTL()) {
            return <Text dir="rtl" textAlign="right" fontFamily="'Jameel Noori Nastaleeq', serif" fontSize="25px"><Latex>{text}</Latex></Text>;
        } else {
            return <Text><Latex>{text}</Latex></Text>;
        }
    };

    const handleTextInstructionsChange = (event) => {
        const words = event.target.value.split(/\s+/);
        setWordCountTextInstructions(words.length);
        if (words.length <= wordLimitTeacher) {
            setTextInstructions(event.target.value);
        }
    };

    const handleTranscribedTextInstructions = (transcription) => {
        setTextInstructions(prev => {
                const newText = prev + ' ' + transcription;
                const words = newText.split(/\s+/);
                if (words.length > wordLimitTeacher) {
                    return words.slice(0, wordLimitTeacher).join(' ');
                }
                return newText;
            });
        };

    const handleTextChange = (event) => {
        const words = event.target.value.split(/\s+/);
        setWordCountText(words.length);
        if (words.length <= wordLimitTeacher) {
            setTextContent(event.target.value);
        }
    };

    const handleTranscribedText = (transcription) => {
        setTextContent(prev => {
            const newText = prev + ' ' + transcription;
            const words = newText.split(/\s+/);
            if (words.length > wordLimitTeacher) {
                return words.slice(0, wordLimitTeacher).join(' ');
            }
            return newText;
        });
    };

    const handleTaskInstructionsChange = (event) => {
        const words = event.target.value.split(/\s+/);
        setWordCountTaskInstructions(words.length);
        if (words.length <= wordLimitTeacher) {
            setTaskInstructions(event.target.value);
        }
    };

    const handleTranscribedTaskInstructions = (transcription) => {
        setTaskInstructions(prev => {
                const newText = prev + ' ' + transcription;
                const words = newText.split(/\s+/);
                if (words.length > wordLimitTeacher) {
                    return words.slice(0, wordLimitTeacher).join(' ');
                }
                return newText;
            });
        };

    const handleTaskChange = (taskData) => {
        try {
            JSON.parse(taskData); // Attempt to parse the JSON to check for validity
            setTaskError(null);
        } catch (error) {
            setTaskError("Invalid JSON format. Please correct the task content.");
        } finally {
            setTaskContent(taskData);
        }
    };

    const handleTextGenerate = async () => {        
        const postData = {
            grade: grade,
            subject: subject,
            lang: language,
            sys_prompt_file_1: null,
            sys_prompt_file_2: null,
            user_prompt_file_1: null,            
            textInstructions: textInstructions
        }
        try {
            console.log(postData);
            setTextGenerating(true);
            const response = await axios.post(`${apiUrl}create_resource_text/`, postData);
            // const feedback = response.data.feedback; // Correctly extracting feedback from the response
            // const feedbackString = JSON.stringify(feedback, null, 2); // Convert the feedback to a readable JSON string            
            setTextContent(response.data.feedback);
            setTextGenerating(false);
        } catch (error) {
            console.error('Error:', error);
        }
       
    };


    const handleTaskGenerate = async () => {        
        const postData = {
            grade: grade,
            subject: subject,
            q_count: qCount,
            max_marks: maxMarks,
            word_limit: wordLimit,
            time_limit: timeLimit,
            lang: language,
            sys_prompt_file_1: null,
            sys_prompt_file_2: null,
            sys_prompt_file_3: contextInclude === true ? sysPromptFile3 : null,
            textContent: contextInclude === true ? textContent : null,
            user_prompt_file_1: null,
            taskInstructions: taskInstructions
        }
        try {
            console.log(postData);
            setTaskGenerating(true);            
            const response = await axios.post(`${apiUrl}create_resource_items/`, postData);
            const feedback = response.data.feedback; // Correctly extracting feedback from the response
            const feedbackString = JSON.stringify(feedback, null, 2); // Convert the feedback to a readable JSON string            
            handleTaskChange(feedbackString); // Correctly invoking the handleTaskChange function
            setTaskGenerating(false);            
        } catch (error) {
            console.error('Error:', error);
        }
       
    };

    const onPreviewText = () => {
        setPreviewText(!(previewText));
    };

    const onPerformTask = () => {
        if (!taskError) {
            setPerformTask(!performTask);
        }
    };

    const handleClose = () => {
        onPerformTask();
        // setTaskContent(null);
    };

    const onUploadText = () => {
        if (!uploadTextFile) {
            const newFile = new File([textContent], `${textFileName}.txt`, { type: 'text/plain' });
            setTextFile(newFile);
        } else {
            setTextFile(null);
        }
        setUploadTextFile(!uploadTextFile);
    };    

    const onUploadTask = () => {
        if (!uploadTaskFile) {
            const newFile = new File([taskContent], `${taskFileName}.json`, { type: 'application/json' });
            setTaskFile(newFile); // Set the file in state to trigger the upload component
        } else {
            setTaskFile(null);
        }
        setUploadTaskFile(!uploadTaskFile);
    };    


    return (
        <>
        <Box display="flex" alignItems="left" mb={4}>
            <FormLabel htmlFor="grade" width="150px" style={{ marginRight: '10px' }}>Select Grade</FormLabel>
            <Box width="50vw" maxWidth="300px">
                <List
                    id="grade"
                    field={{ label: "Select Grade" }} // Provide the field prop with a label
                    lov="GradeLevels"
                    placeholder="Select Grade"
                    value={grade}
                    onChange={(value) => setGrade(value)}
                    style={{ width: '100%' }}
                />
            </Box>
        </Box>                
        <Box display="flex" alignItems="left" mb={4}>
            <FormLabel htmlFor="subject" width="150px" style={{ marginRight: '10px' }}>Select Subject</FormLabel>
            <Box width="50vw" maxWidth="300px">
                <List
                    id="subject"
                    field={{ label: "Select Subject" }} // Provide the field prop with a label
                    lov="Subjects"
                    placeholder="Select Subject"
                    value={subject}
                    onChange={(value) => setSubject(value)}
                    style={{ width: '100%' }}
                />
            </Box>
        </Box>                
        <Box display="flex" alignItems="left" mb={4}>
            <FormLabel htmlFor="language" width="150px" style={{ marginRight: '10px' }}>Select Language</FormLabel>
            <Box width="50vw" maxWidth="300px">
                <List
                    id="language"
                    field={{ label: "Select Language" }} // Provide the field prop with a label
                    lov="Language"
                    placeholder="Select Language"
                    value={language}
                    onChange={(value) => setLanguage(value || '')}
                    style={{ width: '100%' }}
                />
            </Box>
        </Box>                

        <Box display="flex" alignItems="center" mb={2}>
            <FormLabel htmlFor="textFileName" width="150px" style={{ marginRight: '10px' }}>TEXT file name</FormLabel>
            <Input
                id="textFileName"
                placeholder="Enter TEXT file name"
                value={textFileName}
                onChange={(e) => setTextFileName(e.target.value)}
                style={{ width: '50vw', color: "red", fontSize: "20px", fontWeight: "bold" }}
            /> 
        </Box>                    

        <Box display="flex" alignItems="center" mb={2}>
            <FormLabel htmlFor="taskFileName" width="150px" style={{ marginRight: '10px' }}>TASK file name</FormLabel>
            <Input
                id="taskFileName"
                placeholder="Enter TASK file name"
                value={taskFileName}
                onChange={(e) => setTaskFileName(e.target.value)}
                style={{ width: '50vw', color: "red", fontSize: "20px", fontWeight: "bold" }}
            /> 
        </Box>                    

        <Box mb={2} p={1} borderWidth="1px" borderRadius="md">
            {/* Text Recording and Typing */}
            <Text as="h2" fontSize="30px" fontWeight="bold" color="green">TEXT Instructions</Text>            
            <AudioRecorder lang={language} timeLimit={timeLimitTeacher} handleTranscribedText={handleTranscribedTextInstructions} />

            <Textarea
                placeholder="Specify the text requirements here..."
                value={textInstructions}
                onChange={handleTextInstructionsChange}
                dir={language === 'ur' ? "rtl" : "ltr"} 
                size="sm"
                style={{ 
                    width: '90vw', 
                    height: '200px', 
                    fontSize: language === 'ur' ? '25px' : '20px', 
                    backgroundColor: "black", 
                    color: 'white', 
                    textAlign: language === 'ur' ? 'right' : 'left', 
                    fontFamily: language === 'ur' ? "'Jameel Noori Nastaleeq', serif" : 'Arial, sans-serif' 
                }}                
            />
            {wordLimit && 
                <Text>
                    Word limit: <Box as="span" fontSize="lg" fontWeight="bold" color="blue.500">{wordLimitTeacher}</Box>, 
                    <Box as="span" mx={6}>
                        Words entered: <Box as="span" fontSize="lg" fontWeight="bold" color="red.500">{wordCountTextInstructions}</Box>
                    </Box>
                </Text>
            }

            {textInstructions && !textGenerating && subject && grade && 
                <Button mt={2} ml={0} colorScheme="teal" type="button" onClick={handleTextGenerate}>
                    Generate TEXT
                </Button>
            }  
        </Box>


        <Box mb={2} p={1} borderWidth="1px" borderRadius="md">
            {/* Text Recording and Typing */}
            <Text as="h2" fontSize="30px" fontWeight="bold" color="green">TEXT (generated, spoken or typed) </Text>
            <AudioRecorder lang={language} timeLimit={timeLimitTeacher} handleTranscribedText={handleTranscribedText} />

            <Textarea
                placeholder="Type your text here or record it..."
                value={textContent}
                onChange={handleTextChange}
                dir={language === 'ur' ? "rtl" : "ltr"} 
                size="sm"
                style={{ 
                    width: '90vw', 
                    height: '200px', 
                    fontSize: language === 'ur' ? '25px' : '20px', 
                    backgroundColor: "black", 
                    color: 'white', 
                    textAlign: language === 'ur' ? 'right' : 'left', 
                    fontFamily: language === 'ur' ? "'Jameel Noori Nastaleeq', serif" : 'Arial, sans-serif' 
                }}                
            />
            {wordLimit && 
                <Text>
                    Word limit: <Box as="span" fontSize="lg" fontWeight="bold" color="blue.500">{wordLimitTeacher}</Box>, 
                    <Box as="span" mx={6}>
                        Words entered: <Box as="span" fontSize="lg" fontWeight="bold" color="red.500">{wordCountText}</Box>
                    </Box>
                </Text>
            }
            {language==='en' && textContent && <TextListen textData={textContent} />}

            {/* Preview and Upload for Text */}
            {/* temporay turn off later turn on */}
            {/* {textContent && (
                <>
                    <Button mt={2} ml={0} colorScheme="teal" type="button" onClick={onPreviewText}>
                        {previewText === false ? 'Preview Text' : 'Unpreview Text'}
                    </Button>
                    <Button mt={2} ml={4} colorScheme="blue" type="button" onClick={onUploadText}>
                        {uploadTextFile ? 'Cancel Upload' : 'Upload Text'}
                    </Button>
                </>
            )} */}

            {previewText && <TextDisplay data={{file_contents: textContent, content_type: 'text/plain'}} />}
            {textFile && (<FileUploadComponent p_file={textFile} resetFile={() => setTextFile(null)} p_resource_class={'TEXT'} /> )}

        </Box>

        <Box mb={2} p={1} borderWidth="1px" borderRadius="md">
            <Text as="h2" fontSize="30px" fontWeight="bold" color="green">TASK Instructions</Text>
            {/* Text Recording and Typing */}
            <AudioRecorder lang={language} timeLimit={timeLimitTeacher} handleTranscribedText={handleTranscribedTaskInstructions} />

            <Textarea
                placeholder="Specify the task requirements here..."
                value={taskInstructions}
                onChange={handleTaskInstructionsChange}
                dir={language === 'ur' ? "rtl" : "ltr"}                 
                size="sm"
                style={{ 
                    width: '90vw', 
                    height: '200px', 
                    fontSize: language === 'ur' ? '25px' : '20px', 
                    backgroundColor: "black", 
                    color: 'white', 
                    textAlign: language === 'ur' ? 'right' : 'left', 
                    fontFamily: language === 'ur' ? "'Jameel Noori Nastaleeq', serif" : 'Arial, sans-serif' 
                }}                
            />
            {wordLimit && 
                <Text>
                    Word limit: <Box as="span" fontSize="lg" fontWeight="bold" color="blue.500">{wordLimitTeacher}</Box>, 
                    <Box as="span" mx={6}>
                        Words entered: <Box as="span" fontSize="lg" fontWeight="bold" color="red.500">{wordCountTaskInstructions}</Box>
                    </Box>
                </Text>
            }

            {/* <Box mt={2}>
                {renderText(taskInstructions)}
            </Box>             */}

            {/* Checkbox for including context */}
            <Checkbox mt={2} colorScheme="green" isChecked={contextInclude} onChange={(e) => setContextInclude(e.target.checked)}>
                Include Text Context for Task Generation
            </Checkbox>

            {taskInstructions && !taskGenerating && subject && grade &&
                <Button mt={2} ml={0} colorScheme="teal" type="button" onClick={handleTaskGenerate}>
                    Generate TASK
                </Button>
            }

        </Box>


        {/* <Box mt={4} mb={4} p={1} borderWidth="1px" borderRadius="md"> */}
        <Box mb={2} p={1} borderWidth="1px" borderRadius="md"> 
            {1===2 && <Textarea
                placeholder="Task detail comes here"
                value={taskContent}
                onChange={(e) => handleTaskChange(e.target.value)}
                size="sm"
                style={{ 
                    width: '90vw', 
                    height: '200px', 
                    fontSize: language === 'ur' ? '25px' : '20px', 
                    backgroundColor: "black", 
                    color: 'white', 
                    // textAlign: language === 'ur' ? 'right' : 'left', 
                    // fontFamily: language === 'ur' ? "'Jameel Noori Nastaleeq', serif" : 'Arial, sans-serif' 
                }}                
            />
            }

            {/* Preview and Upload for Task */}
            <Text as="h2" fontSize="30px" fontWeight="bold" color="green">TASK (generated)</Text>            
            {taskContent && 
                <Button mt={2} ml={0} colorScheme="teal" type="button" onClick={onPerformTask}>
                    {performTask === false ? "Perform Task" : "Cancel Task" }
                </Button>
            }
            {taskError && <Text color="red.500">{taskError}</Text>}
            {/* {preformTask && !taskError && taskContent && <A_TaskMCQ_Preview taskData={{file_contents: taskContent, content_type: 'application/json'}} />} */}
            {performTask && !taskError && taskContent && <L_Task taskData={{file_contents: taskContent, content_type: 'application/json'}} onTaskSubmit={handleClose}/>}            

            {/* temporay turn off later turn on */}
            {/* {taskContent &&
                <Button mt={2} ml={4} colorScheme="blue" type="button" onClick={onUploadTask}>
                    {uploadTaskFile ? 'Cancel Upload' : 'Upload Task'}
                </Button>
            } */}
            {taskFile && (<FileUploadComponent p_file={taskFile} resetFile={() => setTaskFile(null)} p_resource_class={'TASK'} /> )}
        </Box>
        </>
    );
};

export default L_CreateTask;
