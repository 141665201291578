import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import fieldSchema_json from '../../config/fieldSchema.json';
import apiUrlConfig_json from '../../config/apiUrl.json';
import { useAuth } from '../../context/AuthContext';
import FieldsRendering from '../../techComponents/FieldsRendering';
import useFormSubmit from '../../techComponents/useFormSubmitSR';

const L_ChangePwd = ({ handleView }) => {
  const { user_id } = useAuth();
  const fieldSchema = fieldSchema_json.L_ChangePwd;
  console.log("calling fetch", user_id);
  const apiUrl = `${apiUrlConfig_json.L_Learners}${user_id}`;
  console.log("called fetch", apiUrl);

  // Generate initial form values
  const initialFormValues = fieldSchema.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {});

  const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: initialFormValues
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { onSubmit } = useFormSubmit(apiUrl, reset, handleView);

  const fetchLearnerData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) throw new Error(`HTTP status ${response.status}`);
      const data = await response.json();
      fieldSchema.forEach(field => setValue(field.name, data[field.name]));
      setLoading(false);
    } catch (err) {
      console.error('Failed to fetch learner data:', err);
      setError('Failed to load data');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLearnerData();
  }, [apiUrl]);

  return (
    <div className="container">
      <h2>Change Password</h2>
      {loading && <div>Loading...</div>}
      {error && <div style={{ color: 'red' }}>{error}</div>}
      
      {!loading && !error && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FieldsRendering control={control} errors={errors} fieldSchema={fieldSchema} />
          <Button mt={2} ml={2} colorScheme="teal" type="submit">Submit</Button>
        </form>
      )}
    </div>
  );
};

export default L_ChangePwd;
