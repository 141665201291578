// 20240324 C_Containers.js having Form with fields or c_continers and c_cont_rels

import React, { useState, useEffect, useReducer } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Select, Box, Button, Heading, FormControl, FormLabel, FormErrorMessage, Input, useToast, StepNumber } from '@chakra-ui/react';

import fieldSchema_json from '../../config/fieldSchema.json';
import apiUrlConfig_json from '../../config/apiUrl.json';

const C_Containers = ({ handleView }) => {

    const fieldSchema_Lovs = fieldSchema_json.C_Containers_Lovs;
    const fieldSchema = fieldSchema_json.C_Containers;    
    const apiUrl = apiUrlConfig_json.C_Containers;

    const { control, handleSubmit, register, reset, watch, setValue, getValues, formState: { errors } } = useForm({
        defaultValues: {
            ...Object.fromEntries(fieldSchema_Lovs.map(field => [field.name, ''])),
            children: [Object.fromEntries(fieldSchema.map(field => [field.name, '']))]
        }
    });

    const { fields: rows, append, remove } = useFieldArray({
        control,
        name: "children",
        keyName: "arrayRow_id"  // Custom key name that doesn't conflict with id column name in our fetched table
    });

    const toast = useToast();
    
    // Fetch levels when the component mounts
    const [levels, setLevels] = useState([]);
    useEffect(() => {
        fetch(apiUrl.C_Levels)
            .then(response => response.json())
            .then(data => setLevels(data.map(level => ({ label: level.name, value: level.id, level_number: level.level_number }))))
            .catch(error => console.error('Failed to load levels', error));
    }, []);
    
    const selectedLevelId = watch('level');  // Watch the level_id dropdown
    const selectedParentConId = watch('parent_con'); // Watch the parent_con_id dropdown

    const [parentCons, setParentCons] = useState([]);
    useEffect(() => {
        if (selectedLevelId) {  // Ensure there's a selected level
            const parentLevelId = levels.find(lvl => lvl.level_number == (levels.find(lvl => lvl.value == selectedLevelId)?.level_number - 1))?.value;
            if (parentLevelId ) {
                const url = `${apiUrl.C_Containers}?level=${parentLevelId}`;
                console.log("url ", url);
                fetch(url)
                    .then(response => response.json())
                    .then(data => setParentCons(data.map(parentCon => ({ label: parentCon.name, value: parentCon.id }))))
                    .catch(error => console.error('Failed to load parent containers', error));
            } else {
                setParentCons([]);
            }
       }
    }, [selectedLevelId]);  // Dependency on selectedLevelId ensures the effect runs only when it changes

    useEffect(() => {
        rows.forEach((row, index) => {
            setValue(`children.${index}.level_id`, selectedLevelId);
            // Following causes re-render so taht we can see the change in the rendered field.
            // rows.forEach((row, index) => {setValue(row.level_id, selectedLevelId); });    
        });
    }, [selectedLevelId, setValue, rows.length]);  // Include rows.length to handle dynamic rows

    const handleQuery = async () => {
        try {
            let url = `${apiUrl.C_Containers_Child}?level=${selectedLevelId}`;
            if (selectedParentConId) url += `&parent_con=${selectedParentConId}`;

            const response = await fetch(url); // Ensure apiUrl is correctly defined to fetch records
            const data = await response.json();
            reset({ children: data, level: selectedLevelId, parent_con: selectedParentConId }); // Assuming the response matches the fields structure
            console.log("fetch carried out");
        } catch (error) {
            console.error('Failed to fetch records:', error);
        }
    };

    const [deletedIds, setDeletedIds] = useState([]);
    const handleDeleteRow = (index, id) => {
        if (id) {
            setDeletedIds(prev => [...prev, id]); // Track deletions by ID
        }
        remove(index);
    };

    const handleAddRow = () => {
        if (selectedLevelId) append(Object.fromEntries(fieldSchema.map(field => [field.name, ''])));
    };

    const handleCancel = () => {
        reset();  // This will reset the form fields to their initial values
        handleView('Curriculum');  // Assume handleView is your navigation function
    };

    const onSubmit = async (data) => {
        const payload = {
            newEntries: data.children.filter(child => !child.id), // Assumes no ID means a new entry
            updatedEntries: data.children.filter(child => child.id), // Assumes ID means an existing entry
            deletedEntries: deletedIds
        };
    
        let url = `${apiUrl.C_Containers_CRUD}?level=${selectedLevelId}`;
        if (selectedParentConId) url += `&parent_con=${selectedParentConId}`;
        console.log(payload.newEntries);
        console.log("url1 ", url);

        const crudTasks = [
            {"method": "POST", "data": payload.newEntries, "status": 'pending'},
            {"method": "PUT", "data": payload.updatedEntries, "status": 'pending'},
            {"method": "DELETE", "data": payload.deletedEntries, "status": 'pending'}
        ]

        for (const task of crudTasks) {
        //        for (const entry of payload.newEntries) {
            try {
                const response = await fetch(url, {
                method: task.method,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(task.data),
            });
            if (!response.ok) {
                const errorData = await response.json();
                console.log("Error posting data:", errorData);
                task.status = `${task.method} "failed"`;  
                task.status = `${task.method} "failed"`;
                throw new Error('Failed to post data');
            }
            const result = await response.json();
            console.log("Succss: ", result);
            task.status = `${task.method} "success"`;;  
            toast({
                title: "Success",
                description: "Data submitted successfully",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            } catch (error) {
            console.error("Network or other error:", error);
            task.status = `${task.method} "failed"`;  
            toast({
                title: "Error",
                description: "Failed to submit data",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
          }
        }

//        const allOperationsSuccessful = crud_tasks.every(task => task.status === 'success');
        const allOperationsSuccessful = crudTasks.every(task => task.status.includes('success'));
        if (allOperationsSuccessful) {
            reset();  // Reset the form if all tasks were successful
            // Other actions for complete success
        } else {
            // Handle partial or complete failure
            console.log("Not all operations were successful.");
            console.log(crudTasks.every(task => task.status));
            crudTasks.forEach(task => {
                if (!task.status.includes('success')) {
                    console.log(task);
                }
            });
        }
    };


    return (
        <Box as="form" onSubmit={handleSubmit(onSubmit)} p={4} className='conatiner'>
            <Heading as="h2" size="md" mb={4}>Curriculum Containers</Heading>
            <Box display="flex" alignItems="center" width="100%"  pl="5px" justifyContent="space-between">
            {fieldSchema_Lovs.map((field) => (
                <FormControl key={field.name} isInvalid={errors[field.name]}>
                <FormLabel htmlFor={field.name}>{field.label}</FormLabel>
                <Controller
                    name={field.name}
                    control={control}
                    rules={{ required: field.required }}
                    render={({ field: { ref, onChange, onBlur, value, name } }) => (
                        <Select id={name} onChange={onChange} onBlur={onBlur} value={value} placeholder={field.placeholder} >
                            {field.name === 'level' && levels.map(level => (
                                <option key={level.value} value={level.value}>{level.label}</option>
                            ))}
                            {field.name === 'parent_con' && parentCons.map(parentCon => (
                                <option key={parentCon.value} value={parentCon.value}>{parentCon.label}</option>
                            ))}
                        </Select>
                    )}
                />
                <FormErrorMessage>
                    {errors[field.name] && `${field.label} is required`}
                </FormErrorMessage>
                </FormControl>
            ))}
            </Box>

            {/* Render labels at the top */}
            <Box display="flex" alignItems="center" width="100%"  mt="30px" pl="5px" justifyContent="space-between">
                {fieldSchema.filter(field => !['id', 'level_id'].includes(field.name)).map((field) => (
                    <FormLabel key={`label-${field.name}`} htmlFor={field.name} flex="1" width="100px" mb="0">
                        {field.label}
                    </FormLabel>
                ))}
                <Box display="flex" alignItems="center" pl="5px">Actions</Box> {/* Placeholder for action column */}
            </Box>

            {/* Render fields for each row beneath the labels */}
            {rows.map((row, index) => (
                <Box key={row.arrayRow_id} display="flex" alignItems="center" width="100%" pl="5px" gap="1" justifyContent="space-between">
                    {fieldSchema.map((schemaField) => (
                        <FormControl key={`${row.arrayRow_id}-${schemaField.name}`}>
                            <Controller
                                name={`children[${index}].${schemaField.name}`}
                                control={control}
                                rules={{ required: schemaField.required }}
                                render={({ field }) => (
                                    <Input {...field} type={schemaField.type} readOnly={schemaField.readOnly} placeholder={schemaField.placeholder} flex="1" width={field.type === 'hidden' ? '2px' : 'flex'}/>
                                )}
                            />
                            <FormErrorMessage>
                                {errors[`children[${index}].${schemaField.name}`] && `${schemaField.label} is required`}
                            </FormErrorMessage>
                        </FormControl>
                    ))}
                    <Button size="sm" minWidth="40px" colorScheme="red" onClick={() => handleDeleteRow(index, row.id)}>X</Button> 
                </Box>
            ))}


{/*            <Button mt={5} colorScheme="teal" onClick={() => append(Object.fromEntries(fieldSchema.map(field => [field.name, ''])))}>Add Container</Button> */}

            <Button mt={5} colorScheme="teal" onClick={handleQuery}>GET Records</Button>
            <Button mt={5} ml={4} colorScheme="green" onClick={handleAddRow}>Add Row</Button>
            <Button mt={5} ml={4} colorScheme="blue" type="submit">Submit</Button>
            <Button mt={5} ml={4} colorScheme="red" onClick={handleCancel}>Cancel</Button>
        </Box>
    );
};

export default C_Containers;
