import React, { useState } from 'react';
import { useRoutingNavigation} from '../../routing/useNavigation'; // Import useHistory hook from React Router

function Environment() {

    const { handleRouting } = useRoutingNavigation();
    const [currentView, setCurrentView] = useState('Instruction');
    const handleView = (viewName) => {
      setCurrentView(viewName);
    };

    return (
      <div className="container">
        <h2>Learning Environment</h2>
        {/* Buttons for Asessment */}
        <button className="button" onClick={handleRouting('/learning')}>Return to Learning System</button>
      </div>
    );
  }

  export default Environment;