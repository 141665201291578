import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Input, Stack, Box, Text, Select } from '@chakra-ui/react';

const MultiRecordForm = () => {
  const { control, handleSubmit, reset, getValues } = useForm();
  const [formData, setFormData] = useState({});
  const [currentRecord, setCurrentRecord] = useState({ level: '', stage: '', component: '' });

  const fields = [
    { name: 'i_resource', label: 'Resource', type: 'text' },
    { name: 'i_strategy', label: 'Strategy', type: 'select', options: ['Strategy 1', 'Strategy 2'] },
    { name: 'i_pace', label: 'Pace', type: 'text' },
    { name: 'i_environment', label: 'Environment', type: 'select', options: ['Environment 1', 'Environment 2'] },
  ];

  const onSubmit = data => {
    const { level, stage, component } = currentRecord;
    const updatedFormData = { ...formData };
    if (!updatedFormData[`i_level_${level}`]) {
      updatedFormData[`i_level_${level}`] = {};
    }
    if (!updatedFormData[`i_level_${level}`][`i_stage_${stage}`]) {
      updatedFormData[`i_level_${level}`][`i_stage_${stage}`] = {};
    }
    updatedFormData[`i_level_${level}`][`i_stage_${stage}`][`i_component_${component}`] = data;
    setFormData(updatedFormData);
  };

  const handleChange = (field, value) => {
    setCurrentRecord(prev => ({ ...prev, [field]: value }));
  };

  const handleReset = () => {
    reset({});
    setCurrentRecord({ level: '', stage: '', component: '' });
  };

  const handleFinalSubmit = () => {
    onSubmit(getValues());
    console.log('Final JSON:', formData);
    // Send formData to the backend
  };

  return (
    <Box p={5}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Box>
            <Text mb={2}>Level</Text>
            <Input
              value={currentRecord.level}
              onChange={e => handleChange('level', e.target.value)}
              placeholder="Level"
            />
          </Box>
          <Box>
            <Text mb={2}>Stage</Text>
            <Input
              value={currentRecord.stage}
              onChange={e => handleChange('stage', e.target.value)}
              placeholder="Stage"
            />
          </Box>
          <Box>
            <Text mb={2}>Component</Text>
            <Input
              value={currentRecord.component}
              onChange={e => handleChange('component', e.target.value)}
              placeholder="Component"
            />
          </Box>
          {fields.map(field => (
            <Box key={field.name}>
              <Text mb={2}>{field.label}</Text>
              <Controller
                name={field.name}
                control={control}
                defaultValue=""
                render={({ field: controllerField }) => (
                  field.type === 'select' ? (
                    <Select {...controllerField}>
                      {field.options.map(option => (
                        <option key={option} value={option}>{option}</option>
                      ))}
                    </Select>
                  ) : (
                    <Input {...controllerField} placeholder={field.label} />
                  )
                )}
              />
            </Box>
          ))}
        </Stack>
        <Button mt={4} onClick={handleReset}>Reset</Button>
        <Button mt={4} onClick={handleFinalSubmit} colorScheme="blue">
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default MultiRecordForm;
