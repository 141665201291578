import React, { useState, useEffect } from 'react';
import { Box, Checkbox, CheckboxGroup, Stack, Text } from "@chakra-ui/react";

const TaskCheckboxGroup = ({ question, index, onSubmit, renderText, isRTL }) => {
    const [selectedOptions, setSelectedOptions] = useState({});

    useEffect(() => {
        const correctAnswers = question.A;
        const userAnswers = Object.keys(selectedOptions).filter(key => selectedOptions[key]);
        let obtainedMarks = userAnswers.filter(answer => correctAnswers.includes(answer)).length;
        let isCorrect = "no";
        let correctAnswerContent = null;

        if (obtainedMarks === correctAnswers.length && obtainedMarks === userAnswers.length) {
            isCorrect = "yes";
        } else if (obtainedMarks > 0) {
            isCorrect = "partial";
            correctAnswerContent = correctAnswers.map(opt => question[opt]).join(' *** ');
        } else {
            correctAnswerContent = correctAnswers.map(opt => question[opt]).join(' *** ');
        }

        onSubmit(index, selectedOptions, obtainedMarks, isCorrect, correctAnswerContent);

    }, [selectedOptions, index, question]);

    const handleOptionChange = (option) => {
        setSelectedOptions(prevSelected => ({
            ...prevSelected,
            [option]: !prevSelected[option]
        }));
    };

    return (
        <Box mb={4} p={2} borderWidth="1px" borderRadius="md">
            <CheckboxGroup value={Object.keys(selectedOptions).filter(key => selectedOptions[key])} onChange={(values) => values.forEach(val => handleOptionChange(val))} mb={4}>
                <Stack direction="column">
                    {['a', 'b', 'c', 'd'].map((opt) => (
                        <Box key={opt} dir={isRTL(question.Q) ? "rtl" : "ltr"} style={isRTL(question.Q) ? { textAlign: 'right' } : {}}>
                            <Checkbox isChecked={selectedOptions[`opt-${opt}`]} onChange={() => handleOptionChange(`opt-${opt}`)}>
                                {renderText(question[`opt-${opt}`])}
                            </Checkbox>
                        </Box>
                    ))}
                </Stack>
            </CheckboxGroup>
        </Box>
    );
};

export default TaskCheckboxGroup;
