import React, { useState, useEffect } from 'react';
import { Box, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";

const TaskRadioGroup = ({ question, index, onSubmit, renderText, isRTL }) => {
    const [selectedOption, setSelectedOption] = useState('');
    let obtainedMarks = 0;
    let isCorrect = "no";
    let correctAnswerContent = question[question.A];

    useEffect(() => {
        if (question.A === selectedOption) {
            obtainedMarks = 1;
            isCorrect = "yes";
        } else {
            correctAnswerContent = question[question.A];
        }

        onSubmit(index, selectedOption, obtainedMarks, isCorrect, correctAnswerContent);
    }, [selectedOption, index, question]);

    const handleOptionChange = (value) => {
        setSelectedOption(value);
        console.log("selected option ", selectedOption);

        // if (question.A === value) {
        //     obtainedMarks = 1;
        //     isCorrect = "yes";
        // } else {
        //     correctAnswerContent = question[question.A];
        // }

        // onSubmit("radio", index, value, obtainedMarks, isCorrect, correctAnswerContent);        
    };
    
    return (
        <Box mb={4} p={2} borderWidth="1px" borderRadius="md">
            <RadioGroup onChange={handleOptionChange} value={selectedOption} mb={4}>
                <Stack direction="column">
                    {['a', 'b', 'c', 'd'].map((opt) => (
                        <Box key={opt} dir={isRTL(question.Q) ? "rtl" : "ltr"} style={isRTL(question.Q) ? { textAlign: 'right' } : {}}>
                            <Radio value={`opt-${opt}`}>
                                {renderText(question[`opt-${opt}`])}
                            </Radio>
                        </Box>
                    ))}
                </Stack>
            </RadioGroup>
        </Box>
    );
};

export default TaskRadioGroup;
