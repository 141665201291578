import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Box, Button, Input, Heading } from "@chakra-ui/react";
import FieldsRendering from '../../techComponents/FieldsRendering';
import fieldSchema_json from '../../config/fieldSchema.json';
import apiUrlConfig_json from '../../config/apiUrl.json';

const ProcessEvaluations = () => {
    const fieldSchema = fieldSchema_json.ProcessEvaluations;
    const apiUrl = apiUrlConfig_json.SHR;

    const [frequency, setFrequency] = useState(6);
    const [batchSize, setBatchSize] = useState(1000);
    const [perfStdFile1, setPerfStdFile1] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    // Extracting default values from fieldSchema
    const defaultValues = fieldSchema.reduce((acc, field) => {
        if (field.defaultValue !== undefined) {
            acc[field.name] = field.defaultValue;
        }
        return acc;
    }, {});

    // Initialize form hooks with default values
    const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        defaultValues
    });


    const startProcessing = handleSubmit(async (data) => { 
        setIsSubmitting(true);  // Disable the button
        try {
            const response = await axios.post(`${apiUrl}run_process_evaluation/`, {
                action: 'start',
                frequency: data.frequency,
                batch_size: data.batchSize,
                perf_std_file_1: data.perfStdFile1
            });
            console.log('Processing started:', response.data);
        } catch (error) {
            console.error('Error starting processing:', error);
        }
    });

    // const runOnce = async () => {
    const runOnce = handleSubmit(async (data) => {
        setIsSubmitting(true);  // Disable the button
        try {
            const response = await axios.post(`${apiUrl}run_process_evaluation/`, {
                action: 'start',
                batch_size: data.batchSize,
                perf_std_file_1: data.perfStdFile1,
                assessment_id: data.assessmentId 
            });
            console.log('Processing started:', response.data);
            console.log("assessment id:", data.assessmentId);
            console.log("isSubmitting", isSubmitting)
        } catch (error) {
            console.error('Error starting processing:', error);
        }
    });

    const stopProcessing = async () => {
        setIsSubmitting(true);  // Disable the button
        try {
            const response = await axios.post(`${apiUrl}run_process_evaluation/`, { action: 'stop' });
            console.log('Processing stopped:', response.data);
        } catch (error) {
            console.error('Error stopping processing:', error);
        }
    };

    return (
        <div>
            <Heading as="h1" color="purple.500" size="lg">Process Evaluation</Heading>

            <FieldsRendering control={control} errors={errors} fieldSchema={fieldSchema} />

            <Box mt={2}>
                <Button colorScheme="blue" width="max(25vw, 200px)" onClick={runOnce} isDisabled={isSubmitting}>Run Once</Button>
            </Box>
            <Box mt={2}>
                <Button colorScheme="green" width="max(25vw, 200px)" onClick={startProcessing} isDisabled={isSubmitting}>Start Processing Every {frequency} Minutes</Button>
            </Box>
            <Box mt={2}>
                <Button colorScheme="red" width="max(25vw, 200px)" onClick={stopProcessing} isDisabled={isSubmitting}>Stop Processing</Button>
            </Box>
        </div>
    );
};

export default ProcessEvaluations;
