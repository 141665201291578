import React, { useState, useEffect,useRef } from 'react';
import { Box, Textarea, Text, Button, Checkbox, FormLabel, Input, VStack } from "@chakra-ui/react";
import axios from 'axios';
import apiUrlConfig_json from '../../config/apiUrl.json';
import Markdown from 'react-markdown'
import styles from "./ThreadChat.module.css"
import AudioRecorder from './AudioRecorder';
import TextDisplay from '../../components/shared/TextDisplay';
import TextListen from '../../components/shared/TextListen';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex';



const UserMessage = ({ text }) => {
    return <div className={styles.userMessage}>{text}</div>;
  };
  
  const AssistantMessage = ({ text }) => {
    return (
      <div className={styles.assistantMessage}>
        {/* <Markdown>{text}</Markdown> */}
        <Latex>{text}</Latex>
        {/* {text} */}
      </div>
    );
  };
  
  const CodeMessage = ({ text }) => {
    return (
      <div className={styles.codeMessage}>
        {text.split("\n").map((line, index) => (
          <div key={index}>
            <span>{`${index + 1}. `}</span>
            {line}
          </div>
        ))}
      </div>
    );
  };
  
  const Message = ({ role, text }) => {
    switch (role) {
      case "user":
        return <UserMessage text={text} />;
      case "assistant":
        return <AssistantMessage text={text} />;
      case "code":
        return <CodeMessage text={text} />;
      default:
        return null;
    }
  };


const ThreadChat = ({ threadId, assistant_id }) => {
  
  const apiUrl = apiUrlConfig_json.AAPI;

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [inputDisabled, setInputDisabled] = useState(false);
  const messagesEndRef=useRef(null)

  const wordLimitTeacher = 8000;
  const timeLimitTeacher = 5;
  const [language, setLanguage] = useState('en');
  const [textInstructions, setTextInstructions] = useState('');

  const isRTL = () => {
      return ((language==='ur') ? true : false)
  };

  const renderText = (text) => {
      if (isRTL()) {
          return <Text dir="rtl" textAlign="right" fontFamily="'Jameel Noori Nastaleeq', serif" fontSize="25px"><Latex>{text}</Latex></Text>;
      } else {
          return <Text><Latex>{text}</Latex></Text>;
      }
  };

//   const handleTextInstructionsChange = (event) => {
//     const words = event.target.value.split(/\s+/);
//     setWordCountTextInstructions(words.length);
//     if (words.length <= wordLimitTeacher) {
//         setTextInstructions(event.target.value);
//     }
// };

  const handleTranscribedTextInstructions = (transcription) => {
    setNewMessage(prev => {
              const newText = prev + ' ' + transcription;
              const words = newText.split(/\s+/);
              if (words.length > wordLimitTeacher) {
                  return words.slice(0, wordLimitTeacher).join(' ');
              }
              return newText;
          });
      };



  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (threadId) {
      fetchMessages(threadId);
    }
  }, [threadId]);

  const fetchMessages = async (threadId) => {
    const response = await axios.get(`${apiUrl}threads/${threadId}/messages/`);
    setMessages(response.data.messages);
    setInputDisabled(false);
  };

  const sendMessage = async (e) => {
    e.preventDefault()
    setInputDisabled(true);
    const reply=await axios.post(`${apiUrl}threads/${threadId}/reply/`, { assistant_id:assistant_id,
        content: newMessage });
    console.log(reply)
    setNewMessage('');
    fetchMessages(threadId);
    scrollToBottom();
  };


  return (
    <div className={styles.chatContainer}>
        
      <div className={styles.messages}>
        {messages.map((msg, index) => (
          <Message key={index} role={msg.role} text={msg.content} />
        ))}
        <div ref={messagesEndRef} />
      </div>

      <form
        onSubmit={sendMessage}
        className={`${styles.inputForm} ${styles.clearfix}`}
      >
        {/* <input
          type="text"
          className={styles.input}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Enter your question"
        /> */}

    <VStack spacing={2} align="stretch">
        <Box mb={0} p={1} borderWidth="1px" borderRadius="md">
          {/* Text Recording and Typing */}
          {/* <Text as="h2" fontSize="30px" fontWeight="bold" color="green">Learner Response</Text>             */}
          <AudioRecorder lang={language} timeLimit={timeLimitTeacher} handleTranscribedText={handleTranscribedTextInstructions} />

          <Textarea
              placeholder="Specify the text requirements here..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              dir={language === 'ur' ? "rtl" : "ltr"} 
              size="sm"
              style={{ 
                  width: '80vw', 
                  height: '100px', 
                  'border-radius': '15px',
                  fontSize: language === 'ur' ? '25px' : '20px', 
                  backgroundColor: "black", 
                  color: 'white', 
                  textAlign: language === 'ur' ? 'right' : 'left', 
                  fontFamily: language === 'ur' ? "'Jameel Noori Nastaleeq', serif" : 'Arial, sans-serif' 
              }}                
          />
          <Button mt={0} ml={0} style={{width: '200px'}} colorScheme="teal" type="submit" // className={styles.button} 
            isDisabled={inputDisabled} >
              Send
          </Button>
        </Box>
      </VStack>          

      </form>

    </div>
  );
};

export default ThreadChat;