import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { ChromePicker } from 'react-color';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const ChartSimple = ({ data, xAxisKey, yAxisKeys, chartType, showColorPicker }) => {
  const chartRef = useRef(null);
  const [colors, setColors] = useState(yAxisKeys.map(() => '#8884d8'));

  useEffect(() => {
    return () => {
      // Cleanup function to destroy the chart instance when the component unmounts
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, []);

  const handleColorChange = (color, index) => {
    const newColors = [...colors];
    newColors[index] = color.hex;
    setColors(newColors);
  };

  // Ensure data is an array and handle empty data case
  const chartData = {
    labels: data ? data.map(item => item[xAxisKey]) : [],
    datasets: yAxisKeys.map((key, index) => ({
      label: key,
      data: data ? data.map(item => item[key]) : [],
      backgroundColor: colors[index],
      borderColor: colors[index],
      borderWidth: 1,
    })),
  };

  const options = {
    responsive: true,
    scales: {
      x: { beginAtZero: true },
      y: { beginAtZero: true },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  return (
    <div>
      {showColorPicker && yAxisKeys.map((key, index) => (
        <div key={key}>
          <label>{key} Color:</label>
          <ChromePicker
            color={colors[index]}
            onChange={(color) => handleColorChange(color, index)}
          />
        </div>
      ))}
      {chartType === 'bar' && <Bar ref={chartRef} data={chartData} options={options} />}
      {chartType === 'line' && <Line ref={chartRef} data={chartData} options={options} />}
      {chartType === 'pie' && <Pie ref={chartRef} data={chartData} options={options} />}
    </div>
  );
};

// Default props to prevent undefined data error
ChartSimple.defaultProps = {
  data: [],
  xAxisKey: '',
  yAxisKeys: [],
  chartType: 'bar',
  showColorPicker: false,
};

// Prop validation
ChartSimple.propTypes = {
  data: PropTypes.array,
  xAxisKey: PropTypes.string,
  yAxisKeys: PropTypes.arrayOf(PropTypes.string),
  chartType: PropTypes.string,
  showColorPicker: PropTypes.bool,
};

export default ChartSimple;
