// 241030 This uses @react-pdf/renderer, which is more flexible.
import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { Button } from "@chakra-ui/react";
import PDFViewer from './PDFViewer';

// Define the styles for the PDF document
const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontSize: 12,
        fontFamily: 'Helvetica',
    },
    heading1: {
        fontSize: 20,
        marginBottom: 10,
        fontWeight: 'bold',
    },
    heading2: {
        fontSize: 18,
        marginBottom: 8,
    },
    heading3: {
        fontSize: 16,
        marginBottom: 6,
    },
    table: {
        display: "table",
        width: "auto",
        marginTop: 10,
    },
    tableRow: {
        flexDirection: "row",
    },
    tableCol: {
        width: "20%", // Dynamically adjustable based on content
        padding: 5,
    },
    tableHeader: {
        fontWeight: 'bold',
        fontSize: 14,
        paddingBottom: 3,
    },
    tableCell: {
        fontSize: 12,
        paddingBottom: 3,
    },
    summary: {
        marginTop: 20,
        fontWeight: 'bold',
        fontSize: 14,
    },
    pageBreak: {
        marginTop: 30,
        borderBottomWidth: 2,
    }
});

// JSON2PDF Component using react-pdf
const JSON2PDF2 = ({ data, title, downloadPDF, showPDF }) => {

    // PDF Document Component
    const MyDocument = ({ data }) => (
        <Document>
            {data.map((assessment, index) => (
                <Page key={index} size="LETTER" style={styles.page}>
                    {/* Render dimensions as headings */}
                    {Object.entries(assessment.dimensions).map(([key, value], idx) => (
                        <Text key={key} style={styles[`heading${idx + 1}`] || styles.heading3}>
                            {`${key}: ${value}`}
                        </Text>
                    ))}

                    {/* Render table for detailed data */}
                    <View style={styles.table}>
                        {/* Table header */}
                        <View style={styles.tableRow}>
                            {Object.keys(assessment.detailed_data[0] || {}).map((col, idx) => (
                                <View key={idx} style={styles.tableCol}>
                                    <Text style={styles.tableHeader}>{col}</Text>
                                </View>
                            ))}
                        </View>

                        {/* Table rows */}
                        {assessment.detailed_data.map((row, rowIndex) => (
                            <View key={rowIndex} style={styles.tableRow}>
                                {Object.values(row).map((cell, cellIndex) => (
                                    <View key={cellIndex} style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{cell || '-'}</Text>
                                    </View>
                                ))}
                            </View>
                        ))}
                    </View>

                    {/* Render aggregated data */}
                    <Text style={styles.summary}>
                        Total Obtained Marks: {assessment.aggregated_data.total_obt_marks}
                    </Text>

                    {/* Page Break after each assessment */}
                    {index < data.length - 1 && (
                        <Text style={styles.pageBreak}></Text>
                    )}
                </Page>
            ))}
        </Document>
    );

    return (
        <>
            {downloadPDF && (
                <Button mt={2} ml={2} colorScheme="red" onClick={() => {
                    // Functionality for downloading the PDF
                    // Integrate PDF download using a blob generation if required.
                }}>
                    Download as PDF
                </Button>
            )}
            {showPDF && (
                <PDFViewer fileUrl={<MyDocument data={data} />} allowDownload={downloadPDF} allowPrint={true} />
            )}
        </>
    );
};

export default JSON2PDF2;
