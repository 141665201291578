
// https://www.npmjs.com/package/react-media-recorder

import { useReactMediaRecorder } from "react-media-recorder";
import { Button, Box, Text } from "@chakra-ui/react";
import { useState, useEffect, useRef } from 'react';
// import ReactPlayer from 'react-player';

const MediaRecorder = ({ mediaType, timeLimit = 1, setMediaFile, fileName, 
      videoResolution, videoFPS = 15, cameraFacingMode = 'environment', audioConfig }) => {

  // const options = mediaType === 'video' ? { video: true } : { audio: true };
  const videoConfig = { ...videoResolution, frameRate: { ideal: videoFPS }, facingMode: { ideal: cameraFacingMode } }
  // console.log("videConfig: ", videoConfig);
  // console.log("videConfig: ", videoConfig);

  const options = mediaType === 'video' ? 
    { video:  videoConfig} :   
      (mediaType === 'screen' ? { screen: true } : 
        { audio: audioConfig });
  
  const { status, startRecording, pauseRecording, resumeRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder(options);

  const [recordingStatus, setRecordingStatus] = useState('Record');
  const [playStatus, setPlayStatus] = useState(false);
  const [timeLimitUsed, setTimeLimitUsed] = useState(0);
  const recordingStartTime = useRef(null);
  const recordingInterval = useRef(null);
  const videoRef = useRef(null);
  
    const startMonitoringTime = () => {
    recordingInterval.current = setInterval(() => {
      const elapsed = (Date.now() - recordingStartTime.current) / 60000; // Convert ms to minutes
      if (timeLimitUsed + elapsed >= timeLimit) {
        clearInterval(recordingInterval.current);
        handleStopRecording();
        alert("Recording time limit reached.", elapsed);
      }
      setTimeLimitUsed(prev => prev + elapsed);      
      recordingStartTime.current = Date.now();
    }, 10000); // Check every 10 seconds
  };

  const handleRecording = async () => {
    if (recordingStatus === 'Record') {
      setTimeLimitUsed(0);    //Get full minutes if recording or re-recording
      if (timeLimitUsed < timeLimit) {
        recordingStartTime.current = Date.now();
        startRecording();
        setRecordingStatus('Pause');
        startMonitoringTime();
      } else {
        alert("Recording time limit reached.");
      }
    } else if (recordingStatus === 'Pause') {
      pauseRecording();
      setRecordingStatus('Resume');
      if (recordingStartTime.current) {
        const elapsed = (Date.now() - recordingStartTime.current) / 60000; // Convert ms to minutes
        setTimeLimitUsed(prev => prev + elapsed);
        recordingStartTime.current = null;
      }
      clearInterval(recordingInterval.current);
    } else if (recordingStatus === 'Resume') {
      if (timeLimitUsed < timeLimit) {
        recordingStartTime.current = Date.now(); // Adjust start time to account for already used time
        resumeRecording();
        setRecordingStatus('Pause');
        startMonitoringTime();
      } else {
        alert("Recording time limit reached.");
      }
    }
  };

  const handleStopRecording = async () => {
    stopRecording();
    setRecordingStatus('Record');    
    if (recordingStartTime.current) {
      const elapsed = (Date.now() - recordingStartTime.current) / 60000; // Convert ms to minutes
      setTimeLimitUsed(prev => prev + elapsed);
      recordingStartTime.current = null;
    }
    clearInterval(recordingInterval.current);
  }

  const getMediaFile = async () => {
    const response = await fetch(mediaBlobUrl);
    const blob = await response.blob();
    const file = new File([blob], `${fileName}.${mediaType === 'video' ? 'mp4' : (mediaType === 'screen' ? 'webm' : 'mp3')}`, {
      type: blob.type, });
    setMediaFile(file); // Pass the actual File object back to the calling component                
  }

  useEffect( () => {    
    if (recordingStatus === 'Record' && mediaBlobUrl) {      
      getMediaFile();
    } 
  },[recordingStatus, mediaBlobUrl]);

  const handlePlayStatusToggle = () => {
    setPlayStatus(!playStatus);
  };

  useEffect(() => {
    if (mediaType === 'video' && videoRef.current && recordingStatus === "Pause") {
      navigator.mediaDevices.getUserMedia({ video: videoConfig })
        .then(stream => {
          videoRef.current.srcObject = stream;
        })
        .catch(err => console.error("Error accessing camera: ", err));
    }
  }, [mediaType, videoConfig]);


  return (
    <div>
      <p>{status}</p>
      <Button mt={2} ml={0} colorScheme="green" type="button" onClick={handleRecording}>
        {recordingStatus}
      </Button>
      {!(recordingStatus==='Record') && 
        <Button mt={2} ml={0} colorScheme="red" type="button" onClick={handleStopRecording}>
          Stop Recording
        </Button>
      }
      <Text>
        Recording time limit (minutes): <Box as="span" fontSize="lg" fontWeight="bold" color="blue.500"> {timeLimit} </Box>
        (Used: {Math.floor(timeLimitUsed)}:{Math.floor((timeLimitUsed - Math.floor(timeLimitUsed))*60)})
      </Text>                

      {mediaType === 'video' && recordingStatus === 'Pause' && <video ref={videoRef} autoPlay muted style={{ width: '100%', maxHeight: '400px' }} /> }

      {!(['Pause', 'Resume'].includes(recordingStatus)) && mediaBlobUrl &&
        <Button mt={2} ml={0} colorScheme="purple" type="button" onClick={handlePlayStatusToggle}>
          {!playStatus ? 'Show Play' : 'Hide Play'}
        </Button>
      }
      {playStatus && (mediaType === "audio" ? <audio src={mediaBlobUrl} controls /> : <video src={mediaBlobUrl} controls /> )}
      {/* {playStatus && (mediaType === "audio" ? <audio src={mediaBlobUrl} controls /> : <ReactPlayer url={mediaBlobUrl} controls /> )}       */}
    </div>
  );
};

export default MediaRecorder;
