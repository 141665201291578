import React, { useState, useEffect } from 'react';
import { Box, Button } from "@chakra-ui/react";
import { useForm } from 'react-hook-form';
import debounce from 'lodash.debounce';

import TaskRadioGroup from './TaskRadioGroup';
import TaskCheckboxGroup from './TaskCheckboxGroup';
import TaskTextbox from './TaskTextbox';
import TaskMedia from './TaskMedia';

const TaskLines = ({ questions, onSubmit, renderText, isRTL }) => {
    const [answers, setAnswers] = useState({});
    const [marks, setMarks] = useState({});
    const [incorrectAnswers, setIncorrectAnswers] = useState({});
    const [correctAnswers, setCorrectAnswers] = useState({});
    const [counter, setCounter] = useState(0);

    // 240805, following line added in order to avoid multiple form submission because of rendering or unintentional multiple mouse clicks
    const { register, handleSubmit, formState: { errors } } = useForm();

    const handleChildSubmit = async (questionIndex, answer, mark, isCorrect, correctAnswer ) => {
        setCounter(counter+1);
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionIndex]: answer
        }));
        setMarks(prevMarks => ({
            ...prevMarks,
            [questionIndex]: mark
        }));

        if (isCorrect === "no" || isCorrect === "partial") {
            setIncorrectAnswers(prevIncorrect => ({
                ...prevIncorrect,
                [questionIndex]: "true"
            }));
            setCorrectAnswers(prevCorrectAnswers => ({
                ...prevCorrectAnswers,
                [questionIndex]: correctAnswer
            }));
        } else {
            setIncorrectAnswers(prevIncorrect => ({
                ...prevIncorrect,
                [questionIndex]: "false"
            }));
        }
    };

    useEffect(() => {
        console.log("answers ", answers);
        console.log("marks ", marks);
        console.log("incorrect answers ", incorrectAnswers);
        console.log("correct answers ", correctAnswers);
    }, [answers]);

     const onSubmitLocal = async (event) => {
        //  event.preventDefault();
         onSubmit(answers, marks, incorrectAnswers, correctAnswers);
     };
    
    // Debounce the onSubmit function
    const debouncedSubmit = debounce(onSubmitLocal, 300);    
    
    // Wrap the handleSubmit to include event.preventDefault
    const handleDebouncedSubmit = (event) => {
        event.preventDefault();
        handleSubmit(debouncedSubmit)();
    };

    return (
        <form onSubmit={handleDebouncedSubmit}>
            {questions.map((question, index) => (
                <Box key={index} mb={4} p={4} borderWidth="1px" borderRadius="md">
                    <p>{renderText(`${question['Q-n']}: ${question.Q} (${question.max_marks} marks)`)}</p>
                    {!question['Q-type'] || question['Q-type'] === 'radio' ? (
                        <TaskRadioGroup
                            question={question}
                            index={index}
                            onSubmit={handleChildSubmit}
                            renderText={renderText}
                            isRTL={isRTL}
                        />
                    ) : 
                    question['Q-type'] === 'checkbox' ? (
                        <TaskCheckboxGroup
                            question={question}
                            index={index}
                            onSubmit={handleChildSubmit}
                            renderText={renderText}
                            isRTL={isRTL}
                        />
                    ) : question['Q-type'] === 'textbox' ? (
                        <TaskTextbox
                            question={question}
                            index={index}
                            onSubmit={handleChildSubmit}
                            renderText={renderText}
                            isRTL={isRTL}
                            allowSpeechToText={question.allowSpeechToText}
                            wordLimit={question.word_limit}
                            timeLimit={question.time_limit}                            
                            maxMarks={question.max_marks}
                            lang={question.lang}
                        />
                    ) : question['Q-type'] === 'media' ? (
                        <TaskMedia
                            question={question}
                            index={index}
                            onSubmit={handleChildSubmit}
                            renderText={renderText}
                            isRTL={isRTL}
                            allowSpeechToText={question.allowSpeechToText}
                            wordLimit={question.word_limit}
                            timeLimit={question.time_limit}                            
                            maxMarks={question.max_marks}
                            lang={question.lang}
                            mediaType={question.media_type}
                        />
                    ) : (
                       // Placeholder for future question types like 'text'
                        <div>Unsupported question type</div>
                    )}
                </Box>
            ))}
            <Button mt={2} ml={0} colorScheme="green" type="submit" >SUBMIT</Button>
        </form>
    );
};

export default TaskLines;
