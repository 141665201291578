import React, { useState } from 'react';

const A_TaskText = () => {
  const [content, setContent] = useState('');

  const handleChange = (event) => {
    setContent(event.target.value);
  };

  return (
    <textarea
      value={content}
      onChange={handleChange}
      style={{ width: '50vw', height: '300px', fontSize: '50px', color: "red", fontWeight: "bold", fontFamily: "'Jameel Noori Nastaleeq', serif"}}
      placeholder="Type here..."
     lang="ur"
     dir="rtl"
    />
  );
};

export default A_TaskText;
