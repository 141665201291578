import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiUrlConfig_json from '../../config/apiUrl.json';
import "./UploadFile.css"

const UploadFile = () => {
  const apiUrl = apiUrlConfig_json.AAPI;
  const [file, setFile] = useState(null);
  const [vectorStoreId, setVectorStoreId] = useState('');
  const [message, setMessage] = useState('');
  const [vectorStores, setVectorStores] = useState([]);

  useEffect(() => {
    fetchVectorStores();
  }, []);

  const fetchVectorStores = async () => {
    const response = await axios.get(`${apiUrl}vector-stores/`);
    setVectorStores(response.data.vector_stores);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('vector_store_id', vectorStoreId);

    try {
      const response = await axios.post(`${apiUrl}files/upload/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setMessage('File uploaded successfully: ' + response.data.file_id);
    } catch (error) {
      setMessage('Error uploading file: ' + error.response.data.error);
    }
  };

  return (
    <div className="upload-file">
      <h2>Upload File to Vector Store</h2>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleFileChange} required />
        <select value={vectorStoreId} onChange={(e) => setVectorStoreId(e.target.value)} required>
          <option value="" disabled>Select Vector Store</option>
          {vectorStores.map(store => (
            <option key={store.vector_store_id} value={store.vector_store_id}>{store.name}</option>
          ))}
        </select>
        <button type="submit">Upload</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default UploadFile;
