import { useState, useCallback } from 'react';

const useFetchRecords = (apiUrl) => {
  const [records, setRecords] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [searchParam, setSearchParam] = useState('');
  const [error, setError] = useState(null);

  const fetchRecords = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}?search=${searchParam}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.ok) throw new Error(`HTTP status ${response.status}`);
      const data = await response.json();
      setRecords(data);
      setCurrentIndex(0); // Set to the first record by default
      setError(null); // Clear any previous errors
    } catch (error) {
      console.error('Failed to fetch records:', error);
      setError('Failed to fetch records');
    }
  }, [apiUrl, searchParam]);

  const handleSearch = () => {
    fetchRecords();
  };

  return {
    records,
    fetchRecords,
    searchParam,
    setSearchParam,
    handleSearch,
    currentIndex,
    setCurrentIndex,
    error
  };
};

export default useFetchRecords;
