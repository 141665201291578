import React, { useState, useEffect } from 'react';
import { Select, FormControl, FormLabel } from "@chakra-ui/react";

import axios from 'axios';
import ThreadChat from './ThreadChat';
//onthreadselect
const ThreadSelector = ({ assistantId, threads, onThreadSelect }) => {
  const [selectedThread, setSelectedThread] = useState('');

  const handleThreadChange = (event) => {
    const threadId = event.target.value;
    setSelectedThread(threadId);
    onThreadSelect(threadId);
  };

  return (
    <>
      <div className="thread-selector">
        <FormControl>
          {/* <FormLabel color="red.500" fontWeight="bold">Select Learning Session</FormLabel> */}
          <Select value={selectedThread} onChange={handleThreadChange} placeholder="Select Learning Session" w="300px" 
            color="teal.600" borderColor="gray.400" fontSize="lg" fontWeight="bold">
            {threads.map((thread) => (
              <option key={thread.thread_id} value={thread.thread_id}>
                {thread.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );

  // return (
  //   <>
  //   <div className="thread-selector">
  //     <select value={selectedThread} onChange={handleThreadChange}>
  //       <option value="">Select Learning Session</option>
  //       {threads.map((thread) => (
  //         <option key={thread.thread_id} value={thread.thread_id}>
  //           {thread.name}
  //         </option>
  //       ))}
  //     </select>
  //   </div>
  //   </>
  // );

};

export default ThreadSelector;
