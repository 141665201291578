import React, { useEffect, useRef } from 'react';
import cytoscape from 'cytoscape';
import dagre from 'cytoscape-dagre';

cytoscape.use(dagre); // Register the dagre layout

const C_Network = ({ elements }) => {
    const cyContainer = useRef(null);

    useEffect(() => {
        const cy = cytoscape({
            container: cyContainer.current,
            elements: elements,
            style: [
                { selector: 'node', style: { 'content': 'data(label)', 'text-valign': 'center', 'text-halign': 'center' }},
//                { selector: 'edge', style: { 'curve-style': 'bezier', 'target-arrow-shape': 'triangle' }}
                { selector: 'edge', style: { 'curve-style': 'straight', 'target-arrow-shape': 'triangle' }}

            ],
            layout: {
                name: 'dagre',
                rankDir: 'TB', // 'TB' for top to bottom, or 'LR' for left to right
                nodeSep: 50, // the separation between adjacent nodes in the same rank
                edgeSep: 10, // the separation between adjacent edges in the same rank
                rankSep: 100, // the separation between adjacent nodes in the same rank
                align: 'UL' // alignment of nodes ('UL' for upper left, 'UR' for upper right)
            }
        });

        cy.on('tap', 'node', function(evt) {
            var node = evt.target;
            alert(`Node clicked: ${node.data('label')}`);
        });

        return () => { cy.destroy(); }; // Cleanup the cytoscape instance on component unmount
    }, [elements]);

    return <div style={{ width: '100%', height: '600px' }} ref={cyContainer}></div>;
};

export default C_Network;
