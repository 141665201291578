import React, { useState, useEffect } from 'react';
import { Box, Button } from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext'; 
import apiUrlConfig_json from '../../config/apiUrl.json';
import ThreadSelector from '../Chat/ThreadSelector';
import ThreadChat from '../Chat/ThreadChat';
import AAPI_Learning from './AAPI_Learning';

const LearnerThreads = () => {

  const { user_id } = useAuth(); // Get the user_id of the logged on user from auth context
  const apiUrl = apiUrlConfig_json.AAPI;

  const [threads, setThreads] = useState([]);
  const [selectedThread,setSelectedThread] = useState('')
  const [threadName, setThreadName] = useState('');
  const [selectedAssistant, setSelectedAssistant] = useState('');  

  useEffect(() => {
    if (user_id) {
      fetchThreads(user_id);
    }
  }, [user_id]);

  const fetchThreads = async (user_id) => {
    const response = await axios.get(`${apiUrl}learners/${parseInt(user_id)}/threads/`);
    setThreads(response.data.threads);
  };

  const handleThreadSelect = (threadId) => {
    setSelectedThread(threadId)
    setSelectedAssistant(threads.find(thread => thread.thread_id === threadId).assistant_id);

    // You can add additional logic here if needed, such as:
    // - Fetching messages for the selected thread
    // - Updating the UI to display the selected thread's content
    // - Triggering any other necessary actions based on thread selection
  };
  
  return (
    <>
      <Box fontSize="24px" p="8px 16px" border="1px solid" borderRadius="8px" bg="green" color="white" width="500px" textAlign="center" >
        Interactive Learning
      </Box>   
      {!selectedThread && <ThreadSelector threads={threads} onThreadSelect={handleThreadSelect} /> }
      {/* {selectedThread && <ThreadChat threadId={selectedThread} assistant_id={selectedAssistant}/> } */}
      {selectedThread && <AAPI_Learning threadId={selectedThread} assistant_id={selectedAssistant}/> }      
    </>
  );
};

export default LearnerThreads;
