// src/routing/AppRouter.js or a similar path
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AccessManager from '../components/access/AccessManager';
import LearningSystem from '../components/LearningSystem';
import Learning from '../components/Learning';
import Curriculum from '../components/curriculum/Curriculum';
import Instruction from '../components/instruction/Instruction';
import Assessment from '../components/assessment/Assessment';
import Environment from '../components/environment/Environment';
import Teacher from '../components/teacher/Teacher';
import Teacher2 from '../components/teacher/Teacher2';
import AssistantApi from '../assistantapi/AssistantApi';
import Admin from '../components/admin/Admin';
import Learner from '../components/learner/Learner';
import { useAuth } from '../context/AuthContext';
// import ResourceUploader from '../components/assessment/ResourceUploader';
import Unauthorized from '../components/access/Unauthorized';
import permissions from '../config/rolePermissions.json';
// import ProtectedRoute from '../components/ProtectedRoute'; // The custom protected route component

const ProtectedRoute = ({ children, path }) => {
  const { user_id, role_id } = useAuth();
  const roles = permissions[path];

  if (!user_id) {
    return <Navigate to="/" />;
  }

  if (roles && !roles.includes(role_id)) {
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

const AppRouter = () => (
  <Routes>
    <Route path="/" element={<AccessManager />} />

    <Route path="/learning-system" element={
      <ProtectedRoute path="/learning-system"> 
        <LearningSystem/>
      </ProtectedRoute> 
    } />

    <Route path="/learning" element={
      <ProtectedRoute path="/learning">
        <Learning/>
      </ProtectedRoute>
     } />
    
    <Route path="/learning/Curriculum" element={
    <ProtectedRoute path="/learning/Curriculum">
      <Curriculum/>
    </ProtectedRoute>
    } />
    
    
    <Route path="/learning/Instruction" element={
      <ProtectedRoute path="/learning/Instruction">
        <Instruction/>
      </ProtectedRoute>
      } />
    
    <Route path="/learning/Assessment" element={
      <ProtectedRoute path="/learning/Assessment">
        <Assessment/>
      </ProtectedRoute>
      } />
    
    <Route path="/learning/Environment" element={
      <ProtectedRoute path="/learning/Environment">
        <Environment/>
      </ProtectedRoute>
      } />

    <Route path="/learning/Teacher" element={
          <ProtectedRoute path="/learning/Teacher">
            <Teacher/>
          </ProtectedRoute>
          } />

    <Route path="/learning/Teacher2" element={
          <ProtectedRoute path="/learning/Teacher2">
            <Teacher2/>
          </ProtectedRoute>
          } />

<Route path="/learning/AITutor" element={
          <ProtectedRoute path="/learning/AITutor">
            <AssistantApi/>
          </ProtectedRoute>
          } />

<Route path="/learning/Admin" element={
      <ProtectedRoute path="/learning/Admin">
        <Admin/>
      </ProtectedRoute>
      } />

    <Route path="/learner" element={
      <ProtectedRoute path="/learner">
        <Learner/>
      </ProtectedRoute>
      } />    

{/* <Route path="/resource_upload" element={
        <ResourceUploader/>
      } />     */}


    {/* More routes here */}
    <Route path="/unauthorized" element={<Unauthorized />} />

  </Routes>
);

export default AppRouter;
